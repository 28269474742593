const groupStyles = {
  background: "#ddd",
  color: "black",
  padding: "4px",
  textTransform: "none",
  fontSize: "16px",
};

export const customStyles = {
  menu: (provided) => ({
    ...provided,
    border: "1px solid #808080",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#322d31",
  }),
  groupHeading: (provided) => ({ ...provided, ...groupStyles }),
};

import { getGeocode, getLatLng, getDetails } from "use-places-autocomplete";
import { fillInAddress } from "../../Utils/addressHelpers";
import { Form } from "react-bootstrap";

const PlacesAutocomplete = ({
  setLocationCoordinates,
  setLocationAddress,
  setAddressObj,
  value,
  setValue,
  clearSuggestions,
  data,
  ready,
  status,
  map,
}) => {
  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = (item) => () => {
    const { description } = item;
    const parameter = {
      placeId: item.place_id,
      fields: ["address_components"],
    };
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description }).then((results) => {
      const { lat, lng } = getLatLng(results[0]);
      const { types = [] } = results[0];
      setLocationCoordinates({ lat, lng });
      getDetails(parameter).then((details) => {
        const finalAddress = fillInAddress(
          details?.address_components,
          description,
          { lat, lng },
          types
        );
        setAddressObj(finalAddress);
      });
      setLocationAddress(description);
    });
    map.setZoom(18);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          style={{ marginBottom: 15 }}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div
      style={{
        paddingTop: 5,
        paddingRight: 5,
        position: "relative",
      }}
    >
      <Form.Control
        value={value}
        onChange={handleInput}
        disabled={!ready}
        style={{
          borderRadius: 5,
          padding: 5,
        }}
        placeholder="Search for a apartment, street or road name"
      />
      {status === "OK" && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: "white",
            borderRadius: "0 0 5px 5px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
            listStyleType: "none",
            padding: 10,
            margin: 0,
          }}
        >
          {renderSuggestions()}
        </ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;

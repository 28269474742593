import React, { useCallback, useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PlacesAutocomplete from "./PlacesAutoComplete";
import Button from "react-bootstrap/Button";
import {
  center,
  checkIfInsidePolygon,
  fillInAddress,
  polygonPath,
} from "../../Utils/addressHelpers";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import OutsideErrorModal from "./OutsideErrorModal";
import LocationErrorModal from "./LocationErrorModal";
import { trackEvent } from "../../Config/amplitude";

const MapWithSearch = ({
  isLoaded,
  setAddressObj,
  locationCoordinates,
  setLocationCoordinates,
  setLocationAddress,
  addressObj,
}) => {
  const [map, setMap] = useState(null);
  const [showOutsideErrorModal, setShowOutsideErrorModal] = useState(false);
  const [showlocationErrorModal, setShowLocationErrorModal] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      ...(isLoaded
        ? {
            location: new window.google.maps.LatLng(center.lat, center.lng),
          }
        : {}),
      radius: 30,
    },
    debounce: 300,
  });

  const onLoad = useCallback(function callback(map) {
    setMap(map);
    map.setCenter(center);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const getAndAddGeoCode = (latitude, longitude) => {
    getGeocode({
      // eslint-disable-next-line no-undef
      location: new google.maps.LatLng(latitude, longitude),
    })
      .then((results) => {
        const latLng = getLatLng(results[0]);
        const { types = [], address_components = [] } = results[0];
        setValue(results[0].formatted_address, false);
        setLocationAddress(results[0].formatted_address);
        setLocationCoordinates(latLng);
        const finalAddress = fillInAddress(
          address_components,
          results[0].formatted_address,
          latLng,
          types
        );
        setAddressObj(finalAddress);
        clearSuggestions();
      })
      .catch((error) => {
        console.error("Geocode failed: ", error);
      });
  };

  const onMarkerDragEnd = useCallback((event) => {
    const newPosition = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    getAndAddGeoCode(newPosition.lat, newPosition.lng);
    checkIfInsidePolygon(
      new window.google.maps.LatLng(newPosition.lat, newPosition.lng),
      polygonPath
    );
  }, []);

  useEffect(() => {
    if (isLoaded) {
    }
  }, [isLoaded]);

  const currentLocationHandler = async () => {
    const successCallback = (position) => {
      const { latitude, longitude } = position.coords;
      getAndAddGeoCode(latitude, longitude);
      map.setZoom(19);
    };

    const errorCallback = (error) => {
      console.error("Geolocation error: ", error.message);
      setShowLocationErrorModal(true);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.log("Navigator not supported");
    }
  };

  useEffect(() => {
    if (!value) {
      setLocationAddress(null);
    }
  }, [value]);

  useEffect(() => {
    const { coordinates = {} } = addressObj;
    const isInside = checkIfInsidePolygon(
      new window.google.maps.LatLng(coordinates?.lat, coordinates?.lng),
      polygonPath
    );

    if (!isInside && addressObj?.coordinates?.lat) {
      setShowOutsideErrorModal(true);
      trackEvent("ADDRESS_OUTSIDE", { address: addressObj?.description });
    }
  }, [addressObj]);

  const onHideModal = () => {
    setShowOutsideErrorModal(false);
    setShowLocationErrorModal(false);
    setAddressObj({});
    setLocationAddress(null);
  };

  return isLoaded ? (
    <div>
      <div style={{ zIndex: 20, marginBottom: 30 }}>
        <PlacesAutocomplete
          setLocationCoordinates={setLocationCoordinates}
          setLocationAddress={setLocationAddress}
          setAddressObj={setAddressObj}
          value={value}
          setValue={setValue}
          clearSuggestions={clearSuggestions}
          data={data}
          ready={ready}
          status={status}
          map={map}
        />
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {addressObj?.coordinates && (
          <p
            className="m-0 sticky-top "
            style={{
              position: "sticky",
              border: "black",
              borderWidth: "2px",
              borderStyle: "solid",
              paddingLeft: 10,
            }}
          >
            Adjust the pin for accuracy
          </p>
        )}
        <Button
          className="py-1 px-2 mb-1 map-button"
          onClick={currentLocationHandler}
        >
          <i className="fa fa-location-arrow" style={{ marginRight: 10 }} />
          Locate Me
        </Button>
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "350px" }}
          center={locationCoordinates}
          zoom={13}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            disableDefaultUI: true,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: true,
            fullscreenControl: false,
          }}
        >
          <Marker
            position={locationCoordinates}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
          {/* <Polygon
            paths={polygonPath}
            options={{
              fillColor: "black",
              fillOpacity: 0.5,
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
            }}
          /> */}

          <></>
        </GoogleMap>
      </div>

      {showlocationErrorModal && (
        <LocationErrorModal
          showModal={showlocationErrorModal}
          onSecondaryHandler={onHideModal}
        />
      )}

      {showOutsideErrorModal && (
        <OutsideErrorModal
          showModal={showOutsideErrorModal}
          onHideModal={onHideModal}
        />
      )}
    </div>
  ) : (
    <></>
  );
};

export default MapWithSearch;

import axios from "axios";
import {
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  RAZORPAY_REQUEST,
  RAZORPAY_SUCCESS,
  RAZORPAY_FAIL,
  ORDER_CONFIRM_REQUEST,
  ORDER_CONFIRM_SUCCESS,
  ORDER_CONFIRM_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_NEWCONFIRM_REQUEST,
  ORDER_NEWCONFIRM_SUCCESS,
  ORDER_NEWCONFIRM_FAIL,
  DISCOUNT_CODE_REQUEST,
  DISCOUNT_CODE_SUCCESS,
  DISCOUNT_CODE_FAIL,
  DISCOUNT_CODE_RESET,
  PAYMENT_BY_ID_REQUEST,
  PAYMENT_BY_ID_SUCCESS,
  PAYMENT_BY_ID_FAIL,
  ORDER_BY_ID_REQUEST,
  ORDER_BY_ID_SUCCESS,
  ORDER_BY_ID_FAIL,
} from "../Constants/OrderConstants";
import scrollToTop from "../Utils/ScrollToTop";
import { displayFlashMessage } from "./FlashMessageActions";

export const getMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_LIST_MY_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get("api/orders/v2/", config);
    dispatch({ type: ORDER_LIST_MY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ORDER_LIST_MY_FAIL, payload: error.response });
  }
};

export const createOrder = (tempOrder) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_CREATE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      "api/orders/create-v2/",
      tempOrder,
      config
    );
    dispatch({ type: ORDER_CREATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const verifyPayment = (verify) => async (dispatch, getState) => {
  try {
    dispatch({ type: RAZORPAY_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      "api/orders/payment/confirm/",
      verify,
      config
    );
    dispatch({ type: RAZORPAY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: RAZORPAY_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const confirmOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_CONFIRM_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get(`api/orders/by-payment/${id}/`, config);
    dispatch({ type: ORDER_CONFIRM_SUCCESS, payload: data });
    dispatch({ type: DISCOUNT_CODE_RESET });
  } catch (error) {
    dispatch({ type: ORDER_CONFIRM_FAIL, payload: error.response });
  }
};

export const cancelOrder =
  (id, reason, paymentId = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_CANCEL_REQUEST });
      const { userVerifyOTP } = getState();
      const { login } = userVerifyOTP;
      const config = {
        headers: {
          "Content-type": "Application/json",
          Authorization: "Token " + login.token,
        },
      };
      const { data } = await axios.post(
        `api/orders/cancel-v2/${id}/`,
        reason,
        config
      );
      dispatch({ type: ORDER_CANCEL_SUCCESS, payload: data });
      let message = data
        ? data.detail
        : "Order cancelled. You will recieve a full refund in 5-7 business days";
      scrollToTop();
      dispatch(
        displayFlashMessage({
          position: "top-center",
          delay: 5000,
          background: "success",
          string: message,
        })
      );
      dispatch(getPaymentById(paymentId));
    } catch (error) {
      dispatch({ type: ORDER_CANCEL_FAIL, payload: error.response });
    }
  };

export const checkDiscountCode = (code) => async (dispatch, getState) => {
  try {
    dispatch({ type: DISCOUNT_CODE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      "api/orders/check-discount-code/",
      { discountCode: code },
      config
    );
    dispatch({ type: DISCOUNT_CODE_SUCCESS, payload: data });
    Boolean(data && data.isValid) &&
      localStorage.setItem("discountCode", JSON.stringify(data));
  } catch (error) {
    dispatch({ type: DISCOUNT_CODE_FAIL, payload: error.response });
  }
};

export const newConfirmOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_NEWCONFIRM_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get(`api/orders/payment/${id}/`, config);
    dispatch({ type: ORDER_NEWCONFIRM_SUCCESS, payload: data });
    dispatch({ type: DISCOUNT_CODE_RESET });
  } catch (error) {
    dispatch({ type: ORDER_NEWCONFIRM_FAIL, payload: error.response });
  }
};

export const getPaymentById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_BY_ID_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get(`api/orders/for_payment/${id}/`, config);
    dispatch({ type: PAYMENT_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PAYMENT_BY_ID_FAIL, payload: error.response });
  }
};

export const getOrderById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_BY_ID_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get(`api/orders/${id}/`, config);
    dispatch({ type: ORDER_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ORDER_BY_ID_FAIL, payload: error.response });
  }
};

import React from "react";
import { Button } from "react-bootstrap";
import Loader from "../Loader";

const VerifyActions = (props) => {
  const {
    verifyOTP,
    disableResend,
    messageGenerateOTP,
    handleIssue,
    loadingIssues,
    resendOTP,
    backToGenerate,
  } = props;

  const shouldDisplayIssueReport = () => {
    return (
      messageGenerateOTP &&
      messageGenerateOTP.detail.includes("#") &&
      messageGenerateOTP.detail.split("#")[1].startsWith("3")
    );
  };

  return (
    <div className="mt-2">
      <Button
        type="submit"
        variant="primary"
        onClick={verifyOTP}
        className="mb-2"
      >
        Verify OTP
      </Button>
      {disableResend && (
        <p>Not received OTP yet? Kindly wait 1 minute to request resend</p>
      )}
      {shouldDisplayIssueReport() && (
        <div className="d-flex mb-2 gap-2">
          <span>Still not received OTP? </span>
          <Button variant="danger" size="sm" onClick={handleIssue}>
            Report issue
          </Button>
        </div>
      )}
      {loadingIssues && <Loader />}
      <div className="d-flex gap-2">
        <Button
          disabled={disableResend}
          variant="secondary"
          onClick={resendOTP}
        >
          Resend OTP
        </Button>
        <Button variant="info" onClick={backToGenerate}>
          Change phone no.
        </Button>
      </div>
    </div>
  );
};

export default VerifyActions;

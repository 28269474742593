import React, { useCallback, useEffect, useState } from "react";
import { getProductList } from "../Actions/ProductActions";
import { useDispatch, useSelector } from "react-redux";
import FlashMessage from "../Components/FlashMessage";
import MobileFooter from "../Components/MobileFooter";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import MainContainer from "../Components/HomeScreen/MainContainer";
import useProductListPolling from "../CustomHooks/useProductListPooling";
import useInfiniteScroll from "../CustomHooks/useInfiniteScroll";
import usePageTracking from "../CustomHooks/usePageTracking";

function Homescreen({ history }) {
  const [showToast, setShowToast] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const { products, pagination, loading, error } = useSelector(
    (state) => state.productList
  );

  const loadMoreProducts = useCallback(() => {
    if (pagination && pagination.productsCount) {
      const totalPages = Math.ceil(pagination.productsCount / 30);
      if (!loading && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [loading, pagination]);

  useDocumentTitle("Homble | Whole Foods");
  usePageTracking("Home Screen");
  useProductListPolling(currentPage);
  useInfiniteScroll(loading, loadMoreProducts);

  useEffect(() => {
    dispatch(getProductList(currentPage));
  }, [dispatch, currentPage]);

  return (
    <div>
      <FlashMessage
        show={showToast}
        message={"Sorry! No more stock"}
        setShow={setShowToast}
        background={"primary"}
        position="bottom-center"
      />
      <MainContainer
        history={history}
        products={products}
        loading={loading}
        error={error}
        setShowToast={setShowToast}
      />
      <MobileFooter />
    </div>
  );
}

export default Homescreen;

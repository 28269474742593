import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { displayFlashMessage } from "../../Actions/FlashMessageActions";
import { useDispatch } from "react-redux";

const AddressCard = ({ address, deliveryAddress, handleSelectAddress }) => {
  const isDefault = address?.isDefault;
  const isSelected = deliveryAddress === address.id;
  const isServiceable = address?.isServiceable;

  const dispatch = useDispatch();

  const backgroundColor = !isServiceable
    ? "light"
    : isSelected
      ? "success"
      : "warning";

  return (
    <div
      key={"div" + address.id}
      className={`d-flex justify-content-center ${
        isSelected ? "FillAddCard" : "AddCard"
      }`}
      style={{ borderRadius: 10 }}
    >
      <Card
        key={address.id}
        variant="flush"
        bg={backgroundColor}
        onClick={() => {
          if (isServiceable) {
            handleSelectAddress(address.id);
          } else {
            dispatch(
              displayFlashMessage({
                position: "bottom-center",
                delay: 3000,
                background: "danger",
                string: "We do not deliver to this location currently.",
              })
            );
          }
        }}
        className="mb-3 AddressCard"
        style={{ width: "300px", borderRadius: 10 }}
      >
        <Card.Header style={{ color: !isServiceable ? "#000000" : "#ffffff" }}>
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <span> {address.name}</span>
            {isDefault && <i className="fas fa-home"></i>}
          </div>
        </Card.Header>
        <ListGroup key={"delivery" + address.id}>
          <ListGroup.Item>
            {address.doorNum}, {address.buildingDetails}
          </ListGroup.Item>
          <ListGroup.Item>
            {address.road}, {address.area}
          </ListGroup.Item>
          <ListGroup.Item>
            {address.city} - {address.pincode}
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </div>
  );
};

export default AddressCard;

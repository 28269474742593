import { useState, useEffect } from "react";

import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useDispatch, useSelector } from "react-redux";

//My components
import FormContainer from "../Components/FormContainer";
import Loader from "../Components/Loader";
import { sellerOnboarding } from "../Actions/GoogleSheetsActions";
import { ONBOARDING_WRITE_RESET } from "../Constants/GoogleSheetsConstants";
import { Link } from "react-router-dom";

function Onboardingscreen({ history }) {
  const dispatch = useDispatch();
  const [cuisine, setCuisine] = useState("");
  const [category, setCategory] = useState("");
  const [freeText, setFreeText] = useState("");
  const [doorNum, setDoorNum] = useState("");
  const [buildingDetails, setBuildingDetails] = useState("");
  const [road, setRoad] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("BANGALORE");
  const [pincode, setPincode] = useState("560068");
  const [isSubmit, setIsSubmit] = useState(false);

  const [validated, setValidated] = useState(false);

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  const onboardingWrite = useSelector((state) => state.onboardingWrite);
  const { loading, success, error } = onboardingWrite;

  useEffect(() => {
    dispatch({ type: ONBOARDING_WRITE_RESET });
    if (!login) {
      history.push("/login?redirect=onboarding");
    }
  }, [history, login]);
  useEffect(() => {
    if (success) {
      setIsSubmit(true);
    }
  }, [success]);

  const handleReset = () => {
    dispatch({ type: ONBOARDING_WRITE_RESET });
    history.push("/");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropogation();
    }
    const row = {
      createdAt: Date.now(),
      firstName: login.firstName,
      mobile: login.mobile,
      cuisine,
      category,
      freeText,
      doorNum,
      buildingDetails,
      road,
      area,
      city,
      pincode,
    };
    setValidated(true);
    dispatch(sellerOnboarding(row));
  };
  return isSubmit ? (
    <>
      <h3>Thank you for your interest in becoming a Homble seller. </h3>
      <h6>You will receive a call from us within the next 48 hours.</h6>
      <Button variant="secondary" onClick={handleReset}>
        Ok
      </Button>
    </>
  ) : (
    <FormContainer>
      <div className="d-flex">
        <p style={{ fontStyle: "italic" }}>
          Homble is a chemical and preservative free platform, please continue
          only if your products are free of the ingredients listed here:{" "}
          <Link to={`/prohibited-items`}>prohibited list</Link>
        </p>
      </div>

      <h5>Tell us about your talent</h5>
      <Form>
        <FloatingLabel
          controlId="cuisine"
          label="Cuisine you cook? (Eg: Andhra,Gujarati etc)"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Eg: Andhra, Gujarati, International etc."
            onChange={(e) => setCuisine(e.target.value)}
            required
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Please enter at least one type of cuisine
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          controlId="category"
          label="Your best dishes (Eg: Cake, Rasgulla etc.)"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Eg: Sweets, Savouries, Gravies, Paneer etc."
            onChange={(e) => setCategory(e.target.value)}
            minLength="6"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter at least two items you make
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          controlId="freetext"
          label="Anything else you would like to tell us?"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Eg: I cook pure veg."
            onChange={(e) => setFreeText(e.target.value)}
          />
        </FloatingLabel>
        <h5>Please share your address details</h5>
        <FloatingLabel
          controlId="door"
          label="Door no./Flat no./House no."
          className="mb-3"
        >
          <Form.Control
            as="input"
            type="text"
            placeholder="Enter door no."
            onChange={(e) => setDoorNum(e.target.value)}
            required
            minLength="2"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            Please enter a valid door number
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          controlId="building"
          label="Apartment Name or building name"
          className="mb-3"
        >
          <Form.Control
            as="input"
            required
            type="text"
            placeholder="Enter apartment name"
            onChange={(e) => setBuildingDetails(e.target.value)}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel
          controlId="road"
          label="Street Name/ Road"
          className="mb-3"
        >
          <Form.Control
            as="input"
            type="text"
            placeholder="Enter street name"
            onChange={(e) => setRoad(e.target.value)}
            minLength="4"
            required
          ></Form.Control>

          <Form.Control.Feedback type="invalid">
            Please enter a valid street / road name
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel controlId="area" label="Local area" className="mb-3">
          <Form.Control
            as="input"
            type="text"
            placeholder="Enter your local area name"
            onChange={(e) => setArea(e.target.value)}
            minLength="3"
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            Please enter a valid area
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel controlId="city" label="City" className="mb-3">
          <Form.Control
            as="input"
            required
            type="text"
            placeholder="Enter city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          ></Form.Control>
        </FloatingLabel>

        <FloatingLabel controlId="city" label="Pincode" className="mb-3">
          <Form.Control
            as="input"
            required
            type="text"
            placeholder="Enter pin code"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
          ></Form.Control>
        </FloatingLabel>
        {loading && <Loader />}
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Form>
    </FormContainer>
  );
}

export default Onboardingscreen;

import React from "react";

const IssueTicket = (props) => {
  const { selectItem, selectResponse, selectIssue } = props;

  if (
    !selectResponse ||
    !selectResponse.type ||
    !selectItem ||
    !selectItem.productId
  ) {
    return null;
  }

  const isItemIssue = Boolean(
    selectIssue.slice(0, 4) === "Food" || selectIssue === "Items are missing"
  );

  if (!isItemIssue) {
    return null;
  }

  const {
    sellerStoreName = "",
    price = 0,
    quantity = 0,
    name = "",
  } = selectItem;

  const amount = quantity * price;

  let message;

  if (selectResponse.type === "REPLACEMENT") {
    message = `We have registered your preference for a replacement of ${name}`;
  }

  if (selectResponse.type === "DISCOUNT" && amount > 0) {
    message = `We have registered your preference for a discount of Rs. ${amount} on next order`;
  }

  if (selectResponse.type === "NOTHING") {
    message = `Thank you for supporting clean grocery suppliers. We will convey your feedback to ${sellerStoreName}`;
  }

  return <div>{message}</div>;
};

export default IssueTicket;

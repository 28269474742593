import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_ADD_FAIL,
  CART_REMOVE_ITEM,
  SHIPPING_ADDRESS_LIST_SUCCESS,
  SHIPPING_ADDRESS_LIST_REQUEST,
  SHIPPING_ADDRESS_LIST_FAIL,
  SHIPPING_ADDRESS_CREATE_SUCCESS,
  SHIPPING_ADDRESS_CREATE_REQUEST,
  SHIPPING_ADDRESS_CREATE_FAIL,
  SHIPPING_ADDRESS_DETAILS_SUCCESS,
  SHIPPING_ADDRESS_DETAILS_REQUEST,
  SHIPPING_ADDRESS_DETAILS_FAIL,
  CART_LIST_REQUEST,
  CART_LIST_SUCCESS,
  CART_LIST_FAIL,
  APARTMENT_ADDRESS_CREATE_SUCCESS,
  APARTMENT_ADDRESS_CREATE_REQUEST,
  APARTMENT_ADDRESS_CREATE_FAIL,
  APARTMENT_LIST_SUCCESS,
  APARTMENT_LIST_REQUEST,
  APARTMENT_LIST_FAIL,
  CART_ITEMS_UPDATE,
  DELIVERY_OPTIONS_REQUEST,
  DELIVERY_OPTIONS_SUCCESS,
  DELIVERY_OPTIONS_FAIL,
  IS_CUSTOMER_POD_ALLOLWED_REQUEST,
  IS_CUSTOMER_POD_ALLOLWED_FAIL,
  IS_CUSTOMER_POD_ALLOLWED_SUCCESS,
} from "../Constants/CartConstants";
import { displayFlashMessage } from "./FlashMessageActions";
import { UniqueSlots } from "../Utils/SlotFunction";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  try {
    const { data } = await axios.get(`api/products/cart-view/${id}/`);

    let item = data;
    item.quantityPossible = qty;
    item.changeMessage = "";
    dispatch({ type: CART_ADD_ITEM, payload: item });
  } catch (error) {
    dispatch({
      type: CART_ADD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const removeFromCart = (id) => async (dispatch, getState) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: id });

  localStorage.setItem(
    "cartItemsNew1",
    JSON.stringify(getState().cart.cartItems)
  );
};

export const getShippingAddressList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SHIPPING_ADDRESS_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get("api/users/address/", config);
    dispatch({ type: SHIPPING_ADDRESS_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: SHIPPING_ADDRESS_LIST_FAIL, payload: error.response });
  }
};

export const addShippingAddress = (address) => async (dispatch, getState) => {
  try {
    dispatch({ type: SHIPPING_ADDRESS_CREATE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      "api/users/address/create-v2/",
      address,
      config
    );
    dispatch({ type: SHIPPING_ADDRESS_CREATE_SUCCESS, payload: data });

    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "New address added",
      })
    );
  } catch (error) {
    dispatch({ type: SHIPPING_ADDRESS_CREATE_FAIL, payload: error.response });
  }
};

export const getAddressById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SHIPPING_ADDRESS_DETAILS_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.get(`api/users/address/${id}/`, config);
    dispatch({ type: SHIPPING_ADDRESS_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SHIPPING_ADDRESS_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getCartList = (ids) => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: { "Content-type": "Application/json" },
    };
    const { data } = await axios.post(`api/products/cart-list/`, ids, config);
    let cleanData = data.filter((item) =>
      Boolean(
        item.earliestDeliverySlot &&
          Object.keys(item.earliestDeliverySlot).length > 0
      )
    );
    dispatch({ type: CART_LIST_SUCCESS, payload: UniqueSlots(cleanData) });
    dispatch({ type: CART_ITEMS_UPDATE, payload: cleanData });
    localStorage.setItem(
      "cartItemsNew1",
      JSON.stringify(getState().cart.cartItems)
    );
  } catch (error) {
    dispatch({ type: CART_LIST_FAIL, payload: error.response });
  }
};

export const getApartmentList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: APARTMENT_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: { "Content-type": "Application/json" },
    };
    const { data } = await axios.get("/logistics/locations/", config);
    dispatch({ type: APARTMENT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: APARTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const addApartmentShippingAddress =
  (id, address) => async (dispatch, getState) => {
    try {
      dispatch({ type: APARTMENT_ADDRESS_CREATE_REQUEST });
      const { userVerifyOTP } = getState();
      const { login } = userVerifyOTP;
      const config = {
        headers: {
          "Content-type": "Application/json",
          Authorization: "Token " + login.token,
        },
      };
      const { data } = await axios.post(
        `/logistics/locations/${id}/create-address-v2/`,
        address,
        config
      );
      dispatch({ type: APARTMENT_ADDRESS_CREATE_SUCCESS, payload: data });

      dispatch(
        displayFlashMessage({
          position: "top-center",
          delay: 3000,
          background: "success",
          string: "New address added",
        })
      );
    } catch (error) {
      dispatch({
        type: APARTMENT_ADDRESS_CREATE_FAIL,
        payload: error.response,
      });
    }
  };

export const clearCartList = () => (dispatch, getState) => {
  const { cart } = getState();
  const { cartItems } = cart;

  const newCartItems = cartItems.filter((item) => item.quantityPossible > 0);
  dispatch({ type: CART_ITEMS_UPDATE, payload: newCartItems });
};

export const getDeliveryOptions = (ids) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELIVERY_OPTIONS_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: { "Content-type": "Application/json" },
    };
    const { data } = await axios.post(
      `api/carts/delivery-options-v2/`,
      ids,
      config
    );
    dispatch({ type: DELIVERY_OPTIONS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error.response);
    dispatch({ type: DELIVERY_OPTIONS_FAIL, payload: error.response });
  }
};

export const getIsCustomerPodAllowed = () => async (dispatch, getState) => {
  try {
    dispatch({ type: IS_CUSTOMER_POD_ALLOLWED_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      "/api/users/is-customer-pod-allowed/",
      config
    );

    dispatch({ type: IS_CUSTOMER_POD_ALLOLWED_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: IS_CUSTOMER_POD_ALLOLWED_FAIL, payload: error.response });
  }
};

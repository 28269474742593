import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDVXvtCz4Nmr9HPM8V73sgfsFEQ7BkNMrU",
  authDomain: "homble.firebaseapp.com",
  projectId: "homble",
  storageBucket: "homble.appspot.com",
  messagingSenderId: "858600108421",
  appId: "1:858600108421:web:10b29f4b9d6860ce7594ec",
  measurementId: "G-XNEVSCM5P8",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyBkCsvAcnRdLU1Iw7HV1HZiKaJDEh7JS-0",
  authDomain: "homble-dev.firebaseapp.com",
  projectId: "homble-dev",
  storageBucket: "homble-dev.appspot.com",
  messagingSenderId: "439925267269",
  appId: "1:439925267269:web:5259ca900d400e7fe27c2c",
  measurementId: "G-NB68H4LFV9",
};

const currentUrl = window.location.href;

const isDev =
  currentUrl.includes("localhost") ||
  currentUrl.includes(
    "http://www.myjaxy-testing.ap-south-1.elasticbeanstalk.com/"
  );

// Initialize Firebase
const app = initializeApp(isDev ? firebaseConfigDev : firebaseConfig);
export const analytics = getAnalytics(app);

import {
  OTP_ISSUES_WRITE_REQUEST,
  OTP_ISSUES_WRITE_SUCCESS,
  OTP_ISSUES_WRITE_FAIL,
  ONBOARDING_WRITE_REQUEST,
  ONBOARDING_WRITE_SUCCESS,
  ONBOARDING_WRITE_FAIL,
  ISSUES_WRITE_REQUEST,
  ISSUES_WRITE_SUCCESS,
  ISSUES_WRITE_FAIL,
} from "../Constants/GoogleSheetsConstants";

import { GoogleSpreadsheet } from "google-spreadsheet";
import { displayFlashMessage } from "./FlashMessageActions";
import scrollToTop from "../Utils/ScrollToTop";

export const reportOTPIssues = (row) => async (dispatch) => {
  const SPREADSHEET_ID = "1woCPQMnGYcW8ijSznZ3Cut3UDq0y8Mo46cAbNiwG7-0";
  const SHEET_ID = "1611359995";
  const CLIENT_EMAIL =
    "myjaxy-seller-requests@myjaxy-seller-requests.iam.gserviceaccount.com";
  const PRIVATE_KEY =
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC3lLZRKCyR0/A3\nEKW1xnUh/gRzcpZMwpuagkFuW5W77f9mIofZylF3VMMsO52NGsF37nRLDn95mIBO\nVkctkEOCIUSUgHwOUbxTgzpHql2qr/cUGqQS1j9QYLdl1ZVTXqLObtQ28C2Fiy4Z\nHo/oxe2C3yhMqWQxsjqCPF/B0CRuxuvpDGLvxOv443Zfxp4zTqFpDxMacTnJwspx\nSJcU9F5W2dYqA9Fj7rCnNfwg9o+piCeSiIpsL7cH/TFYqhDrrLco4cLsWB5sVgGm\nPPXjH2lYrEeO8DyZHDpcVq9fzuyMvZv1LR9uXqgPSJ05T0SAAaekyaHM/u7MQJu1\n8fdgeO89AgMBAAECggEAAfYmkYzslv1M/IWiw6O0djHE4efoR7cvmvHH2bJEUg77\nst9tFKxm/O+sqzES4KJXzVyApRmpTocM3elplsjLE92fojeSO8PvT3QgQApHuG5r\nZS1YsfPUltsFsqKa1xp0gmCoR1L/JYVkb3PGZV4SCNsyDsmjjq1hFymme0dqUD2u\nvdJB2qWp/IcjAeWfUfK7eHeIcshq+ud0cfWENBnVosNqSVef7EtkYCw0TW6GCw9Z\nYlIscgDA8zud1jqGb9zWM7F90X1h3vq5VgOW9g21nUrQVP5PpE8VAaPeI9hYOvuG\nuLk3UyThgVYAbYUPxqTvWOY5Zs0vgHdxHlliIPsVEQKBgQDjPWJJxZVad++KTEiP\nPrM6Nl9Y3nht2zveAlHMY9bZqu9IJGUeLxW5zaClx6DbL2PAKyHBgY3nyQXyim96\nFF36o7kixpha2sZRlZeru0spGXADxGAsM61DB4LNx3TYeENye9mSvsqXghb04Wvr\nKVDzNW64aAFfIAzOBb0vaNQvTQKBgQDO0MWLWm+t8vhL2MTPl6NiX2Czcma9vTJv\n+9G3jBYHVPrlfDFiiIrZlF4NRh0j27N1ghFqQdeorokt3M+v1UnHBQrThNGjFQuY\nw3F6LW7sQOgil/fuiS3G5tU9vF4Sf3/YZ+R8YIl/4kf0AuTLarhimtVdF6M+F4WS\nW3tBZA2nsQKBgGbbAgIiTNgGMYaHwNAVsUpIEiBHknsnIJH8usmo5zbfCC+/SYXA\nCjVvvadMFKMhcCUrYLwVyAWCGA8QWiUH0wH/2WzEN3HoVJvY+i3hOMQBn5IU8PMQ\n8PvGCo7aGSliRiUrqe+iLFEnsPxmjFKFAP8U0GjnqrdD6ofWUSuEZ4aBAoGAKpYU\nx3A05PXOKRgW1sRb0WrgZ7Mb8uAdna+V7Jy9AH+ANncPWEHTjaAeic8xOuwHlR/W\nZDHwrXxbJQV+ArABxCiGpS2JWXKA89Dy8fyfBakL4+u1EvpOYdGwgUjjeh2k/rPN\n+cqLJbk7pcRexoBQPYWyf7vZUnTXfV63D60BntECgYBMHvvoWuGJW4shi0Lck8BB\nK3vsSqxsNvEROqsqU+5orBigLXRNWzWxm6IQ3ba86+RD2jhwuRHe9ZZeDJYwG8KS\naU22crptzse4k9uRj7am7pJP1X0kkKVQPJ4m1D2jeV/I2xGJiqatP4ANtXxYNnBe\nJE13NK1dThoc11OojWQJkw==\n-----END PRIVATE KEY-----\n";
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  try {
    dispatch({ type: OTP_ISSUES_WRITE_REQUEST });
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const result = await sheet.addRow(row);
    dispatch({ type: OTP_ISSUES_WRITE_SUCCESS });
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "Thanks for reporting the issue! We will call you back soon",
      })
    );
    scrollToTop();
  } catch (e) {
    dispatch({ type: OTP_ISSUES_WRITE_FAIL, payload: e });
  }
};
export const sellerOnboarding = (row) => async (dispatch) => {
  const SPREADSHEET_ID = "1woCPQMnGYcW8ijSznZ3Cut3UDq0y8Mo46cAbNiwG7-0";
  const SHEET_ID = "0";
  const CLIENT_EMAIL =
    "myjaxy-seller-requests@myjaxy-seller-requests.iam.gserviceaccount.com";
  const PRIVATE_KEY =
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC3lLZRKCyR0/A3\nEKW1xnUh/gRzcpZMwpuagkFuW5W77f9mIofZylF3VMMsO52NGsF37nRLDn95mIBO\nVkctkEOCIUSUgHwOUbxTgzpHql2qr/cUGqQS1j9QYLdl1ZVTXqLObtQ28C2Fiy4Z\nHo/oxe2C3yhMqWQxsjqCPF/B0CRuxuvpDGLvxOv443Zfxp4zTqFpDxMacTnJwspx\nSJcU9F5W2dYqA9Fj7rCnNfwg9o+piCeSiIpsL7cH/TFYqhDrrLco4cLsWB5sVgGm\nPPXjH2lYrEeO8DyZHDpcVq9fzuyMvZv1LR9uXqgPSJ05T0SAAaekyaHM/u7MQJu1\n8fdgeO89AgMBAAECggEAAfYmkYzslv1M/IWiw6O0djHE4efoR7cvmvHH2bJEUg77\nst9tFKxm/O+sqzES4KJXzVyApRmpTocM3elplsjLE92fojeSO8PvT3QgQApHuG5r\nZS1YsfPUltsFsqKa1xp0gmCoR1L/JYVkb3PGZV4SCNsyDsmjjq1hFymme0dqUD2u\nvdJB2qWp/IcjAeWfUfK7eHeIcshq+ud0cfWENBnVosNqSVef7EtkYCw0TW6GCw9Z\nYlIscgDA8zud1jqGb9zWM7F90X1h3vq5VgOW9g21nUrQVP5PpE8VAaPeI9hYOvuG\nuLk3UyThgVYAbYUPxqTvWOY5Zs0vgHdxHlliIPsVEQKBgQDjPWJJxZVad++KTEiP\nPrM6Nl9Y3nht2zveAlHMY9bZqu9IJGUeLxW5zaClx6DbL2PAKyHBgY3nyQXyim96\nFF36o7kixpha2sZRlZeru0spGXADxGAsM61DB4LNx3TYeENye9mSvsqXghb04Wvr\nKVDzNW64aAFfIAzOBb0vaNQvTQKBgQDO0MWLWm+t8vhL2MTPl6NiX2Czcma9vTJv\n+9G3jBYHVPrlfDFiiIrZlF4NRh0j27N1ghFqQdeorokt3M+v1UnHBQrThNGjFQuY\nw3F6LW7sQOgil/fuiS3G5tU9vF4Sf3/YZ+R8YIl/4kf0AuTLarhimtVdF6M+F4WS\nW3tBZA2nsQKBgGbbAgIiTNgGMYaHwNAVsUpIEiBHknsnIJH8usmo5zbfCC+/SYXA\nCjVvvadMFKMhcCUrYLwVyAWCGA8QWiUH0wH/2WzEN3HoVJvY+i3hOMQBn5IU8PMQ\n8PvGCo7aGSliRiUrqe+iLFEnsPxmjFKFAP8U0GjnqrdD6ofWUSuEZ4aBAoGAKpYU\nx3A05PXOKRgW1sRb0WrgZ7Mb8uAdna+V7Jy9AH+ANncPWEHTjaAeic8xOuwHlR/W\nZDHwrXxbJQV+ArABxCiGpS2JWXKA89Dy8fyfBakL4+u1EvpOYdGwgUjjeh2k/rPN\n+cqLJbk7pcRexoBQPYWyf7vZUnTXfV63D60BntECgYBMHvvoWuGJW4shi0Lck8BB\nK3vsSqxsNvEROqsqU+5orBigLXRNWzWxm6IQ3ba86+RD2jhwuRHe9ZZeDJYwG8KS\naU22crptzse4k9uRj7am7pJP1X0kkKVQPJ4m1D2jeV/I2xGJiqatP4ANtXxYNnBe\nJE13NK1dThoc11OojWQJkw==\n-----END PRIVATE KEY-----\n";
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  try {
    dispatch({ type: ONBOARDING_WRITE_REQUEST });
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const result = await sheet.addRow(row);
    dispatch({ type: ONBOARDING_WRITE_SUCCESS });
  } catch (e) {
    dispatch({ type: ONBOARDING_WRITE_FAIL, payload: e });
  }
};

export const writeIssues = (row) => async (dispatch) => {
  const SPREADSHEET_ID = "1woCPQMnGYcW8ijSznZ3Cut3UDq0y8Mo46cAbNiwG7-0";
  const SHEET_ID = "1611359995";
  const CLIENT_EMAIL =
    "myjaxy-seller-requests@myjaxy-seller-requests.iam.gserviceaccount.com";
  const PRIVATE_KEY =
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQC3lLZRKCyR0/A3\nEKW1xnUh/gRzcpZMwpuagkFuW5W77f9mIofZylF3VMMsO52NGsF37nRLDn95mIBO\nVkctkEOCIUSUgHwOUbxTgzpHql2qr/cUGqQS1j9QYLdl1ZVTXqLObtQ28C2Fiy4Z\nHo/oxe2C3yhMqWQxsjqCPF/B0CRuxuvpDGLvxOv443Zfxp4zTqFpDxMacTnJwspx\nSJcU9F5W2dYqA9Fj7rCnNfwg9o+piCeSiIpsL7cH/TFYqhDrrLco4cLsWB5sVgGm\nPPXjH2lYrEeO8DyZHDpcVq9fzuyMvZv1LR9uXqgPSJ05T0SAAaekyaHM/u7MQJu1\n8fdgeO89AgMBAAECggEAAfYmkYzslv1M/IWiw6O0djHE4efoR7cvmvHH2bJEUg77\nst9tFKxm/O+sqzES4KJXzVyApRmpTocM3elplsjLE92fojeSO8PvT3QgQApHuG5r\nZS1YsfPUltsFsqKa1xp0gmCoR1L/JYVkb3PGZV4SCNsyDsmjjq1hFymme0dqUD2u\nvdJB2qWp/IcjAeWfUfK7eHeIcshq+ud0cfWENBnVosNqSVef7EtkYCw0TW6GCw9Z\nYlIscgDA8zud1jqGb9zWM7F90X1h3vq5VgOW9g21nUrQVP5PpE8VAaPeI9hYOvuG\nuLk3UyThgVYAbYUPxqTvWOY5Zs0vgHdxHlliIPsVEQKBgQDjPWJJxZVad++KTEiP\nPrM6Nl9Y3nht2zveAlHMY9bZqu9IJGUeLxW5zaClx6DbL2PAKyHBgY3nyQXyim96\nFF36o7kixpha2sZRlZeru0spGXADxGAsM61DB4LNx3TYeENye9mSvsqXghb04Wvr\nKVDzNW64aAFfIAzOBb0vaNQvTQKBgQDO0MWLWm+t8vhL2MTPl6NiX2Czcma9vTJv\n+9G3jBYHVPrlfDFiiIrZlF4NRh0j27N1ghFqQdeorokt3M+v1UnHBQrThNGjFQuY\nw3F6LW7sQOgil/fuiS3G5tU9vF4Sf3/YZ+R8YIl/4kf0AuTLarhimtVdF6M+F4WS\nW3tBZA2nsQKBgGbbAgIiTNgGMYaHwNAVsUpIEiBHknsnIJH8usmo5zbfCC+/SYXA\nCjVvvadMFKMhcCUrYLwVyAWCGA8QWiUH0wH/2WzEN3HoVJvY+i3hOMQBn5IU8PMQ\n8PvGCo7aGSliRiUrqe+iLFEnsPxmjFKFAP8U0GjnqrdD6ofWUSuEZ4aBAoGAKpYU\nx3A05PXOKRgW1sRb0WrgZ7Mb8uAdna+V7Jy9AH+ANncPWEHTjaAeic8xOuwHlR/W\nZDHwrXxbJQV+ArABxCiGpS2JWXKA89Dy8fyfBakL4+u1EvpOYdGwgUjjeh2k/rPN\n+cqLJbk7pcRexoBQPYWyf7vZUnTXfV63D60BntECgYBMHvvoWuGJW4shi0Lck8BB\nK3vsSqxsNvEROqsqU+5orBigLXRNWzWxm6IQ3ba86+RD2jhwuRHe9ZZeDJYwG8KS\naU22crptzse4k9uRj7am7pJP1X0kkKVQPJ4m1D2jeV/I2xGJiqatP4ANtXxYNnBe\nJE13NK1dThoc11OojWQJkw==\n-----END PRIVATE KEY-----\n";
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  try {
    dispatch({ type: ISSUES_WRITE_REQUEST });
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const result = await sheet.addRow(row);
    dispatch({ type: ISSUES_WRITE_SUCCESS });
  } catch (e) {
    dispatch({ type: ISSUES_WRITE_FAIL, payload: e });
  }
};

import { useState } from "react";

function useOtpForm(setValidated) {
  const [otp, setOtp] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const validateForm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    return form.checkValidity();
  };

  return {
    otp,
    setOtp,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    validateForm,
  };
}

export default useOtpForm;

import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const MobileFooter = () => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  if (width > 500) {
    return <Footer />;
  }

  return (
    <div
      style={{
        background: "#ededed",
        padding: 20,
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: 20,
        paddingBottom: 10,
      }}
    >
      <div
        className="d-flex"
        style={{
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <div>
          <Link to="/about-us">
            <p>About us</p>
          </Link>
          <Link to="/privacy">
            <p>Privacy Policy</p>
          </Link>
          <Link to="/contact-us">
            <p>Contact us</p>
          </Link>
        </div>
        <div>
          <Link to="/shipping-policy">
            <p>Shipping Policy</p>
          </Link>
          <Link to="/tnc">
            <p>Terms and Conditions</p>
          </Link>
        </div>
      </div>

      <p style={{ fontSize: "0.8rem", marginTop: 10, textAlign: "center" }}>
        Copyright &copy; JAXY TECHNOLOGIES PRIVATE LIMITED
      </p>
    </div>
  );
};

export default MobileFooter;

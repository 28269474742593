import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";

const ItemSelect = (props) => {
  const { selectIssue = "", setSelectItem, isProductIssue, order } = props;
  const isItemsList = order && order?.items?.length > 1;

  useEffect(() => {
    if (!isProductIssue || !selectIssue) {
      return null;
    }
    if (!isItemsList) {
      setSelectItem(order.items[0]);
    }
  }, [order, selectIssue, isProductIssue]);

  if (!selectIssue || !isProductIssue) {
    return null;
  }

  if (!isItemsList) {
    return <h6 className="mb-3">Issue regarding item {order.items[0].name}</h6>;
  }

  const handleItemSelect = (e) => {
    const selectedItem = JSON.parse(e.target.value);
    setSelectItem(selectedItem);
  };

  return (
    <div>
      <Form.Group className="mb-3" controlId="items">
        <Form.Label>Select the item with the issue</Form.Label>
        <Form.Select type="text" onChange={handleItemSelect}>
          <option key="default-item" value="">
            Choose issue item
          </option>
          {order.items.map((item) => {
            return (
              <option key={item.id} value={JSON.stringify(item)}>
                {item.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default ItemSelect;

import React from "react";
import { Badge } from "react-bootstrap";
import { delTimeBadgeColor, getStringSlot } from "../../Utils/SlotFunction";

const EarliestDelTime = ({ slot }) => {
  if (!slot || !slot?.startDatetime) {
    return null;
  }
  const slotType = slot?.type;

  if (slotType !== 100) {
    return (
      <DelTimeBadge
        color={delTimeBadgeColor(slot?.date)}
        slotString={getStringSlot(slot)}
      />
    );
  }

  return (
    <>
      <DelTimeBadge
        color={delTimeBadgeColor(slot?.date, true)}
        slotString={" ⚡30 mins*"}
      />
      <p
        className="text-primary"
        style={{ fontSize: 12, padding: 0, margin: 0, marginTop: 1 }}
      >
        *Select address to see if this option is available
      </p>
    </>
  );
};

export default EarliestDelTime;

const DelTimeBadge = ({ color, slotString }) => {
  return (
    <Badge bg={color}>
      <h6
        style={{ margin: 0, color: "white" }}
        className="text-heading-secondary"
      >
        {slotString}
      </h6>
    </Badge>
  );
};

import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logoutUser } from "../../Actions/UserActions";
import { USER_LOGOUT_RESET } from "../../Constants/UserConstants";
import { colors } from "../../Constants/colors";
import SearchBoxMain from "../SearchBoxMain";

function NavbarSmall(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  const userLogout = useSelector((state) => state.userLogout);
  const { loading, success, error } = userLogout;

  const handleLogout = () => {
    dispatch(logoutUser());
  };
  useEffect(() => {
    if (success) {
      dispatch({ type: USER_LOGOUT_RESET });
      history.push("/");
    }
  }, [success]);
  return (
    <>
      <Navbar
        className="py-0 d-flex justify-content-between"
        collapseOnSelect
        bg="white"
        variant="dark"
        fixed="top"
        expand="lg"
        style={{
          border: 0,
          padding: 0,
          margin: 0,
          boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Navbar.Brand>
          <LinkContainer to="/" className="p-0 ms-2">
            <img
              alt="Homble | Whole Foods"
              src="logo_green.png"
              width="110"
              className="p-0 m-0"
            />
          </LinkContainer>
        </Navbar.Brand>

        <LinkContainer to="/cart">
          <Nav.Link as="div">
            <span className="cart-basketSmall d-flex align-items-center justify-content-center">
              {props.cartTotal}
            </span>
            <i
              className="fas fa-shopping-cart fa-lg"
              style={{ color: colors.primary }}
            ></i>
          </Nav.Link>
        </LinkContainer>
        {login ? (
          <NavDropdown
            title={
              <i
                className="fas fa-user-circle fa-lg"
                style={{ color: colors.primary }}
              ></i>
            }
            id="user-menu"
          >
            <NavDropdown.Item>
              <LinkContainer to="/profile">
                <Nav.Link as="div">My Profile</Nav.Link>
              </LinkContainer>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <LinkContainer to="/myorders">
                <Nav.Link as="div">My Orders</Nav.Link>
              </LinkContainer>
            </NavDropdown.Item>

            <NavDropdown.Item onClick={handleLogout}>
              <Nav.Link as="div">Logout</Nav.Link>
            </NavDropdown.Item>
          </NavDropdown>
        ) : (
          <LinkContainer to="/login">
            <Nav.Link as="div">
              <i
                className="fas fa-user fa-lg my-0 py-0 "
                style={{ color: colors.primary }}
              >
                <span style={{ color: colors.primary }}></span>
              </i>
            </Nav.Link>
          </LinkContainer>
        )}
        <NavDropdown
          drop="start"
          style={{ color: colors.primary }}
          title={
            <i
              className="fas fa-bars fa-lg"
              style={{ color: colors.primary }}
            ></i>
          }
          id="nav-menu"
        >
          <NavDropdown.Item>
            <LinkContainer to="/about-us">
              <Nav.Link as="div">About us</Nav.Link>
            </LinkContainer>
          </NavDropdown.Item>

          <NavDropdown.Item>
            <LinkContainer to="/privacy">
              <Nav.Link as="div">Privacy Policy</Nav.Link>
            </LinkContainer>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <LinkContainer to="/tnc">
              <Nav.Link as="div">Terms and Conditions</Nav.Link>
            </LinkContainer>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <LinkContainer to="/contact-us">
              <Nav.Link as="div">Contact us</Nav.Link>
            </LinkContainer>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <LinkContainer to="/shipping-policy">
              <Nav.Link as="div">Shipping Policy</Nav.Link>
            </LinkContainer>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <LinkContainer to="/help">
              <Nav.Link as="div">Help</Nav.Link>
            </LinkContainer>
          </NavDropdown.Item>
        </NavDropdown>

        <SearchBoxMain width="200px" isMobile={false} />
      </Navbar>
    </>
  );
}

export default NavbarSmall;

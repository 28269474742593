export const getProductFilter = (showStock, quantityAvailable) => {
  if (showStock) {
    if (quantityAvailable && quantityAvailable === 0) {
      return "sepia(70%)";
    } else {
      return null;
    }
  } else {
    return "grayscale(70%)";
  }
};

import {
  USER_GENERATE_OTP_REQUEST,
  USER_GENERATE_OTP_SUCCESS,
  USER_GENERATE_OTP_FAIL,
  USER_GENERATE_OTP_RESET,
  USER_VERIFY_OTP_FAIL,
  USER_VERIFY_OTP_SUCCESS,
  USER_VERIFY_OTP_REQUEST,
  USER_LOGOUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_RESET,
} from "../Constants/UserConstants";

export const userGenerateOTPReducer = (state = { message: {} }, action) => {
  switch (action.type) {
    case USER_GENERATE_OTP_REQUEST:
      return { loading: true };
    case USER_GENERATE_OTP_SUCCESS:
      return { loading: false, success: true, message: action.payload };
    case USER_GENERATE_OTP_FAIL:
      return { ...state, loading: false, error: action.payload };
    case USER_GENERATE_OTP_RESET:
      return {};
    default:
      return state;
  }
};

export const userVerifyOTPReducer = (state = { login: {} }, action) => {
  switch (action.type) {
    case USER_VERIFY_OTP_REQUEST:
      return { loading: true };
    case USER_VERIFY_OTP_SUCCESS:
      return { loading: false, login: action.payload };
    case USER_VERIFY_OTP_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userGetProfileReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { loading: true };
    case USER_PROFILE_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_PROFILE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userLogoutReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGOUT_REQUEST:
      return { loading: true };
    case USER_LOGOUT_SUCCESS:
      return { loading: false, success: true };
    case USER_LOGOUT_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT_RESET:
      return {};
    default:
      return state;
  }
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProductList } from "../Actions/ProductActions";

const POOLING_TIME = 300000; //5 mins

const useProductListPolling = (currentPage) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getProductList());
    }, POOLING_TIME);
    return () => clearInterval(interval);
  }, [dispatch, currentPage]);
};

export default useProductListPolling;

export const colors = {
  primary: "rgba(0, 110, 69, 1)",
  primary_green: {
    DEFAULT: "rgba(0, 110, 69, 1)",
    LIGHT: "rgba(0, 110, 69, 0.2)",
    LIGHTER: "rgba(0, 110, 69, 0.1)",
    LIGHTEST: "rgba(0, 110, 69, 0.03)",
  },
  beige: "#FBF8EF",
  lightBlue: "rgba(0, 110, 255, 0.03)",
};

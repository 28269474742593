import {
  OTP_ISSUES_WRITE_REQUEST,
  OTP_ISSUES_WRITE_SUCCESS,
  OTP_ISSUES_WRITE_FAIL,
  ONBOARDING_WRITE_REQUEST,
  ONBOARDING_WRITE_SUCCESS,
  ONBOARDING_WRITE_FAIL,
  ONBOARDING_WRITE_RESET,
  ISSUES_WRITE_REQUEST,
  ISSUES_WRITE_SUCCESS,
  ISSUES_WRITE_FAIL,
  ISSUES_WRITE_RESET,
} from "../Constants/GoogleSheetsConstants";

export const OTPIssuesWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case OTP_ISSUES_WRITE_REQUEST:
      return { loading: true };
    case OTP_ISSUES_WRITE_SUCCESS:
      return { loading: false, success: true };
    case OTP_ISSUES_WRITE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const onboardingWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case ONBOARDING_WRITE_REQUEST:
      return { loading: true };
    case ONBOARDING_WRITE_SUCCESS:
      return { loading: false, success: true };
    case ONBOARDING_WRITE_FAIL:
      return { loading: false, error: action.payload };
    case ONBOARDING_WRITE_RESET:
      return {};
    default:
      return state;
  }
};

export const issuesWriteReducer = (state = {}, action) => {
  switch (action.type) {
    case ISSUES_WRITE_REQUEST:
      return { loading: true };
    case ISSUES_WRITE_SUCCESS:
      return { loading: false, success: true };
    case ISSUES_WRITE_FAIL:
      return { loading: false, error: action.payload };
    case ISSUES_WRITE_RESET:
      return {};
    default:
      return state;
  }
};

import axios from "axios";
import {
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SUCCESS,
  REVIEW_CREATE_FAIL,
  CUSTOMER_ISSUE_WRITE_REQUEST,
  CUSTOMER_ISSUE_WRITE_SUCCESS,
  CUSTOMER_ISSUE_WRITE_FAIL,
} from "../Constants/ReviewConstants";
import scrollToTop from "../Utils/ScrollToTop";
import { displayFlashMessage } from "./FlashMessageActions";

export const createMyReview = (review) => async (dispatch, getState) => {
  try {
    dispatch({ type: REVIEW_CREATE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post("api/reviews/create/", review, config);
    dispatch({ type: REVIEW_CREATE_SUCCESS });
    scrollToTop();
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "Thanks for the review!!",
      })
    );
  } catch (error) {
    console.log(error);
    dispatch({ type: REVIEW_CREATE_FAIL, payload: error.response });
  }
};

export const writeCustomerIssues = (postData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOMER_ISSUE_WRITE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      "api/orders/report-issue/",
      postData,
      config
    );
    dispatch({ type: CUSTOMER_ISSUE_WRITE_SUCCESS });
    return data;
  } catch (e) {
    dispatch({ type: CUSTOMER_ISSUE_WRITE_FAIL, payload: e });
  }
};

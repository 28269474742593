import React from "react";
import { Placeholder, Row } from "react-bootstrap";

const CollectionScrollSkeleton = () => {
  const placeholderItems = [...Array(3)];
  return (
    <div
      className="mt-2"
      style={{
        background: "#e9e6e5",
        opacity: 0.3,
      }}
    >
      <Placeholder className="mt-3" as="div" animation="glow">
        <Placeholder as="p" xs={6} style={{ borderRadius: 16 }} />
      </Placeholder>
      <Row
        className="mx-2"
        style={{
          display: "grid",
          gap: 20,
          gridAutoColumns: "minmax(1fr,auto)",
          gridAutoFlow: "column",
          overflowX: "scroll",
          scrollbarWidth: 0,
        }}
      >
        <div className="flex d-flex column infinite-scroll-content gap-4">
          {placeholderItems.map((_, index) => (
            <Placeholder
              className="mb-3"
              as="div"
              animation="glow"
              style={{ borderRadius: 16 }}
            >
              <Placeholder
                style={{
                  width: "120px",
                  height: "136px",
                  borderRadius: 16,
                }}
              />
            </Placeholder>
          ))}
        </div>
      </Row>
    </div>
  );
};

export default CollectionScrollSkeleton;

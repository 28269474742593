import React from "react";
import { ListGroup } from "react-bootstrap";
import CartItem from "./CartItem";
import EarliestDelTime from "./EarliestDelTime";

const CartBody = ({ slots, cartItems, setShowToast }) => {
  if (!slots || slots?.length === 0) {
    return null;
  }

  return (
    <div>
      {slots.map((slot) => {
        const hasCartItems = cartItems.some(
          (item) => item.earliestDeliverySlot?.id === slot?.id
        );
        if (!hasCartItems) return null;

        const slotCartItems = cartItems.filter(
          (item) => item.earliestDeliverySlot?.id === slot.id
        );

        return (
          <div key={slot.id}>
            <EarliestDelTime slot={slot} />
            <ListGroup
              variant="flush"
              style={{
                background: "#f5f5f5",
                marginTop: 10,
                borderRadius: 10,
                padding: 10,
              }}
            >
              {slotCartItems.map((item) =>
                item.quantityPossible !== 0 || item.changeMessage !== "" ? (
                  <ListGroup.Item key={item.id} className="m-0 p-0 pt-2">
                    <CartItem item={item} setShowToast={setShowToast} />
                  </ListGroup.Item>
                ) : null
              )}
            </ListGroup>
          </div>
        );
      })}
    </div>
  );
};

export default CartBody;

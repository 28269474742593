import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import ErrorHandle from "../Components/ErrorHandle";
import FormContainer from "../Components/FormContainer";
import { generateUserOTP } from "../Actions/UserActions";
import { MOBILE_PATTERN, MOBILE_REG_EX } from "../Constants/GlobalConstants";
import { USER_GENERATE_OTP_RESET } from "../Constants/UserConstants";

function Loginscreen({ history, location }) {
  const [phoneNum, setPhoneNum] = useState(0);
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const userGenerateOTP = useSelector((state) => state.userGenerateOTP);
  const {
    loading: loadingGenerateOTP,
    success: successGenerateOTP,
    error: errorGenerateOTP,
  } = userGenerateOTP;
  const redirect = location.search ? location.search.split("=")[1] : "";

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  useEffect(() => {
    dispatch({ type: USER_GENERATE_OTP_RESET });
    if (login) {
      history.push(`/${redirect}`);
    }
  }, [login, history, redirect]);

  useEffect(() => {
    if (successGenerateOTP) {
      redirect
        ? history.push(`/login/verify?redirect=${redirect}`)
        : history.push("/login/verify");
    }
  }, [successGenerateOTP, history, phoneNum]);

  const SendOTP = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropogation();
    }
    setValidated(true);
    const mobileRegEx = MOBILE_REG_EX;
    if (mobileRegEx.test(phoneNum)) {
      dispatch(generateUserOTP(phoneNum));
    }
  };

  const enterOTP = () => {
    history.push("/login/verify");
  };

  return (
    <FormContainer>
      {loadingGenerateOTP && <Spinner animation="border" />}
      <Form
        noValidate
        validated={validated}
        className="Login"
        onSubmit={SendOTP}
      >
        <h4 className="LoginTitle">Login/ Create account</h4>
        <Form.Group>
          {errorGenerateOTP && (
            <ErrorHandle history={history} error={errorGenerateOTP} />
          )}
          <p>Enter your 10-digit phone number</p>
          <Form.Text className="text-muted">
            An OTP will be sent to this number.
          </Form.Text>
          <FloatingLabel
            controlId="phoneNum"
            label="Your mobile no."
            className="mb-3"
          >
            <Form.Control
              type="tel"
              placeholder="Your phone no. here"
              onChange={(e) => setPhoneNum(e.target.value)}
              pattern={MOBILE_PATTERN}
              autoFocus
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid 10 digit mobile number
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
        <Button variant="primary" onClick={SendOTP}>
          Send OTP
        </Button>
        {errorGenerateOTP?.status === 429 && (
          <Button
            variant="secondary"
            onClick={enterOTP}
            style={{ marginLeft: 10 }}
          >
            Already have OTP
          </Button>
        )}
      </Form>
    </FormContainer>
  );
}

export default Loginscreen;

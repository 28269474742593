export const calculateTotalItems = (cartItems) => {
  if (!cartItems) {
    return 0;
  }
  return cartItems.reduce((acc, item) => acc + item.quantityPossible, 0);
};

export const calculateTotalAmount = (cartItems) => {
  if (!cartItems) {
    return 0;
  }
  return cartItems.reduce(
    (acc, item) => acc + item.quantityPossible * item.price,
    0
  );
};

export const calculateDiscount = (totalAmount, discountCoupon) => {
  if (!discountCoupon) {
    return null;
  }
  const discountAmount =
    (totalAmount * discountCoupon?.discountPercentage) / 100;
  return discountAmount > discountCoupon.discountCap
    ? discountCoupon.discountCap
    : Math.round(discountAmount);
};

export const filterCartProducts = (cartItems, recommendedItems) => {
  if (!cartItems || !recommendedItems) {
    return [];
  }
  const cartItemIds = new Set(cartItems.map((item) => item.id));
  const filteredRec = recommendedItems.filter(
    (item) => !cartItemIds.has(item.id)
  );

  return filteredRec.filter((item) => item?.quantityAvailable > 0);
};

export const filterSoldOut = (recommendedItems) => {
  if (!recommendedItems) {
    return null;
  }
  return recommendedItems.filter((item) => item?.quantityAvailable > 0);
};

import React from "react";
import { Placeholder } from "react-bootstrap";

const RecItemSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: 10,
        padding: 10,
        maxWidth: "200px",
      }}
    >
      <div className="mt-0 mb-6" style={{ width: 126, height: 94 }}>
        <Placeholder
          as="div"
          className="d-flex flex-column text-gray-100"
          animation="glow"
          style={{ gap: 20 }}
        >
          <Placeholder
            xs={12}
            style={{ width: "100%", height: "120px", borderRadius: 10 }}
          />
          <Placeholder
            xs={12}
            style={{ width: "100%", height: "20px", borderRadius: 10 }}
          />
        </Placeholder>
      </div>
    </div>
  );
};

export default RecItemSkeleton;

import React from "react";
import { CUT_OFF_RATING, getStarColor } from "../Utils/reviewsHelpers";

const RatingNew = ({ value, text }) => {
  if (!value) {
    return null;
  }
  if (value < CUT_OFF_RATING) {
    return null;
  }
  const color = getStarColor(value);
  return (
    <div
      style={{
        display: "flex",
        width: "fit-content",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: color,
          display: "flex",
          width: "fit-content",
          padding: 5,
          paddingLeft: 7,
          paddingRight: 7,
          borderRadius: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h6 className="p-0 m-0" style={{ color: "white" }}>
          {value.toFixed(1)} <i className="fas fa-star" />
        </h6>
      </div>
      <span style={{ color: "black", paddingLeft: 10 }}>{text}</span>
    </div>
  );
};

export default RatingNew;

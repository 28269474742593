import { ButtonGroup, Button } from "react-bootstrap";
import { colors } from "../Constants/colors";

function PlusMinusButton({ qty, changeQty, removeFromCart }) {
  const buttonStyle = {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    fontWeight: 600,
    background: colors.primary_green.DEFAULT,
    border: 0,
  };
  const handleDecrement = (event) => {
    event.stopPropagation();
    if (qty > 1) {
      changeQty("-", event);
    } else {
      removeFromCart();
    }
  };

  return (
    <ButtonGroup className="plusMinus">
      <Button
        size="sm"
        variant="warning"
        onClick={handleDecrement}
        style={buttonStyle}
        aria-label={qty > 1 ? "Decrease Quantity" : "Remove from Cart"}
      >
        <i className={`fas ${qty > 1 ? "fa-minus" : "fa-trash"}`}></i>
      </Button>
      <Button
        className="wide-btn"
        size="sm"
        disabled={true}
        style={{
          ...buttonStyle,
          backgroundColor: colors.primary_green.LIGHTER,
          color: "black",
          border: `1px solid ${colors.primary_green.DEFAULT}`,
        }}
      >
        <span>{qty}</span>
      </Button>

      <Button
        size="sm"
        variant="warning"
        onClick={(event) => changeQty("+", event)}
        style={buttonStyle}
      >
        <i className="fas fa-plus "></i>
      </Button>
    </ButtonGroup>
  );
}

export default PlusMinusButton;

import React from "react";
import { Button, Card, Modal } from "react-bootstrap";

const OutsideErrorModal = (props) => {
  const { showModal, onHideModal } = props;
  return (
    <Modal show={showModal} onHide={onHideModal} centered>
      {
        <Modal.Body>
          <Card key="oops_error" style={{ maxWidth: "400px" }}>
            <Card.Img variant="top" src="oops_pic.png" />
            <Card.Body>
              <Card.Title>
                Sorry, we currently do not deliver to this location
              </Card.Title>
              <Card.Text>
                We will notify you as soon as we start deliveries to this
                location.
              </Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
      }

      <Modal.Footer>
        <Button onClick={onHideModal} variant="danger">
          Select another location
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OutsideErrorModal;

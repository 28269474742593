import {
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_RESET,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_RESET,
  RAZORPAY_REQUEST,
  RAZORPAY_SUCCESS,
  RAZORPAY_FAIL,
  RAZORPAY_RESET,
  ORDER_CONFIRM_REQUEST,
  ORDER_CONFIRM_SUCCESS,
  ORDER_CONFIRM_FAIL,
  ORDER_CONFIRM_RESET,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_RESET,
  ORDER_NEWCONFIRM_REQUEST,
  ORDER_NEWCONFIRM_SUCCESS,
  ORDER_NEWCONFIRM_FAIL,
  ORDER_NEWCONFIRM_RESET,
  DISCOUNT_CODE_REQUEST,
  DISCOUNT_CODE_SUCCESS,
  DISCOUNT_CODE_FAIL,
  DISCOUNT_CODE_RESET,
  TEMP_ORDER_CREATE,
  PAYMENT_BY_ID_REQUEST,
  PAYMENT_BY_ID_SUCCESS,
  PAYMENT_BY_ID_FAIL,
  PAYMENT_BY_ID_RESET,
  ORDER_BY_ID_REQUEST,
  ORDER_BY_ID_SUCCESS,
  ORDER_BY_ID_FAIL,
  ORDER_BY_ID_RESET,
} from "../Constants/OrderConstants";

export const listMyOrdersReducer = (state = { orderList: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return { loading: true };
    case ORDER_LIST_MY_SUCCESS:
      return { loading: false, orderList: action.payload };
    case ORDER_LIST_MY_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_LIST_MY_RESET:
      return {};
    default:
      return state;
  }
};

export const orderCreateReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true };
    case ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload };
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const razorpayReducer = (state = {}, action) => {
  switch (action.type) {
    case RAZORPAY_REQUEST:
      return { loading: true };
    case RAZORPAY_SUCCESS:
      return { loading: false, success: true };
    case RAZORPAY_FAIL:
      return { loading: false, error: action.payload };
    case RAZORPAY_RESET:
      return {};
    default:
      return state;
  }
};

export const orderConfirmationReducer = (
  state = { finalOrder: [] },
  action
) => {
  switch (action.type) {
    case ORDER_CONFIRM_REQUEST:
      return { loading: true };
    case ORDER_CONFIRM_SUCCESS:
      return { loading: false, finalOrder: action.payload };
    case ORDER_CONFIRM_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CONFIRM_RESET:
      return {};
    default:
      return state;
  }
};

export const orderCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return { loading: true };
    case ORDER_CANCEL_SUCCESS:
      return { loading: false, success: true };
    case ORDER_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_CANCEL_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDiscountReducer = (
  state = { discountCoupon: {} },
  action
) => {
  switch (action.type) {
    case DISCOUNT_CODE_REQUEST:
      return { loading: true };
    case DISCOUNT_CODE_SUCCESS:
      return { loading: false, discountCoupon: action.payload };
    case DISCOUNT_CODE_FAIL:
      return { loading: false, error: action.payload };
    case DISCOUNT_CODE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderNewConfirmationReducer = (
  state = { confirmOrderDetails: {} },
  action
) => {
  switch (action.type) {
    case ORDER_NEWCONFIRM_REQUEST:
      return { loading: true };
    case ORDER_NEWCONFIRM_SUCCESS:
      return { loading: false, confirmOrderDetails: action.payload };
    case ORDER_NEWCONFIRM_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_NEWCONFIRM_RESET:
      return {};
    default:
      return state;
  }
};

export const tempOrderReducer = (state = { tempOrder: {} }, action) => {
  switch (action.type) {
    case TEMP_ORDER_CREATE:
      return { loading: false, success: true, tempOrder: action.payload };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const paymentDetailsReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case PAYMENT_BY_ID_REQUEST:
      return { loading: true };
    case PAYMENT_BY_ID_SUCCESS:
      return { loading: false, order: action.payload };
    case PAYMENT_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case PAYMENT_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDetailsReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_BY_ID_REQUEST:
      return { loading: true };
    case ORDER_BY_ID_SUCCESS:
      return { loading: false, order: action.payload };
    case ORDER_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case ORDER_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

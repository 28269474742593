import {
  REVIEW_CREATE_REQUEST,
  REVIEW_CREATE_SUCCESS,
  REVIEW_CREATE_FAIL,
  REVIEW_CREATE_RESET,
  CUSTOMER_ISSUE_WRITE_REQUEST,
  CUSTOMER_ISSUE_WRITE_SUCCESS,
  CUSTOMER_ISSUE_WRITE_FAIL,
  CUSTOMER_ISSUE_WRITE_RESET,
} from "../Constants/ReviewConstants";

export const createReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case REVIEW_CREATE_REQUEST:
      return { loading: true };
    case REVIEW_CREATE_SUCCESS:
      return { loading: false, success: true };
    case REVIEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case REVIEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const writeCustomerIssueReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_ISSUE_WRITE_REQUEST:
      return { loading: true };
    case CUSTOMER_ISSUE_WRITE_SUCCESS:
      return { loading: false, success: true };
    case CUSTOMER_ISSUE_WRITE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_ISSUE_WRITE_RESET:
      return {};
    default:
      return state;
  }
};

import React from "react";

const BillDetails = ({ amounts }) => {
  if (!amounts) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        fontSize: "0.9rem",
        borderRadius: 10,
        background: "#f5f5f5",
        padding: 15,
      }}
    >
      <p
        className="text-heading-secondary p-0 m-0"
        style={{ fontSize: "1.1rem" }}
      >
        Bill Details
      </p>
      <AmountRow label={"Items Subtotal"} amount={amounts?.itemsTotal} />
      {amounts?.discountAmount > 0 && (
        <AmountRow
          label={"Discounts"}
          amount={amounts?.discountAmount}
          isNegative={true}
        />
      )}
      <AmountRow label={"Delivery charges"} amount={amounts?.deliveryFee} />
      <AmountRow
        label={"Bill total"}
        amount={amounts?.netAmount}
        containerClasses="text-heading-secondary"
      />
    </div>
  );
};

export default BillDetails;

const AmountRow = ({
  label,
  amount,
  containerClasses = "",
  labelClasses = "",
  amountClasses = "",
  isNegative = false,
}) => {
  return (
    <div className={`d-flex justify-content-between ${containerClasses}`}>
      <p className={`p-0 m-0 ${labelClasses}`}>{label} </p>
      <p className={`p-0 m-0 ${amountClasses}`}>
        {isNegative ? "-" : ""}&#8377;{amount}{" "}
      </p>
    </div>
  );
};

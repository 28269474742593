import React from "react";
import { createAddressFromParts } from "../../Utils/addressHelpers";
import { getDateFromStamp } from "../../Utils/DateTimeFunctions";
import { getPaymentMethod } from "../../Utils/myOrdersHelpers";

const LogisticDetails = ({ logisticDetails }) => {
  if (!logisticDetails) {
    return null;
  }
  const address = createAddressFromParts(logisticDetails?.address);
  const dateStr = getDateFromStamp(logisticDetails?.startedAt);
  const paymentMethod = getPaymentMethod(logisticDetails?.method);
  return (
    <div style={{ paddingLeft: 5 }}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-3">
          <p className="text-heading-secondary p-0 m-0">
            ID #{logisticDetails?.id}
          </p>
          <p className="p-0 m-0">
            {" "}
            <strong>Method:</strong> {paymentMethod}
          </p>
        </div>
      </div>
      <LogisticRow label={"Placed on: "} value={dateStr} />
      <LogisticRow label={"Delivered to: "} value={address} />
    </div>
  );
};

export default LogisticDetails;

const LogisticRow = ({ label, value }) => {
  return (
    <div className="mt-1">
      <span className="text-heading-secondary">{label} </span>{" "}
      <span className="">{value}</span>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { convertToSlotOptions, getDelFee } from "../../Utils/SlotFunction";
import Select from "react-select";
import { convertNumToWords } from "../../Utils/StringFunctions";
import { addToLocalStorage } from "../../Utils/browserHelper";
import ProductsDisplayModal from "./ProductsDisplayModal";
import { SELECTED_SLOT_DATA } from "../../Constants/BrowserConstants";
import OptionValue from "./OptionValue";
import { customStyles } from "../../Utils/deliveryOptionsHelpers";

const DeliveryOptionCard = ({
  index,
  option,
  handleConfirmModal,
  addressId,
  history,
  cartItems,
}) => {
  const { deliveries } = option;
  const [slotOptions, setSlotOptions] = useState({ slots: [] });
  const [viewedDeliveryIndex, setViewedDeliveryIndex] = useState(null);

  useEffect(() => {
    const initialSlotOptions = deliveries?.map((delivery) => ({
      slot: delivery?.slots[0],
      products: delivery?.products,
    }));
    setSlotOptions({ slots: initialSlotOptions });
  }, [deliveries]);

  const hasEmptyDelivery = deliveries?.some(
    (option) => option?.slots?.length === 0
  );

  if (hasEmptyDelivery || !deliveries) {
    return null;
  }

  const handleSlotChange = (deliveryIndex, selectedOption) => {
    const updatedSlots = [...slotOptions.slots];

    updatedSlots[deliveryIndex] = {
      slot: selectedOption.value,
      products: deliveries[deliveryIndex].products,
    };

    setSlotOptions({ slots: updatedSlots });
  };
  const handleOptionSelect = () => {
    const isPodNotAllowed = slotOptions.slots.some(
      (item) => !item?.slot?.isPodAllowed
    );
    const delFee = getDelFee(slotOptions);
    addToLocalStorage(SELECTED_SLOT_DATA, {
      ...slotOptions,
      isPodNotAllowed,
      delFee,
    });
    if (isPodNotAllowed) {
      handleConfirmModal(slotOptions);
    } else {
      history.push(`/checkout/${addressId}`);
    }
  };

  const openProductsModal = (deliveryIndex) => {
    setViewedDeliveryIndex(deliveryIndex);
  };

  const productModalCancelHandler = () => {
    setViewedDeliveryIndex(null);
  };

  const modalHeader = index === 0 ? "Earliest" : "All Together";

  return (
    <Card
      key={`card-${index}`}
      border={deliveries.length > 1 ? "primary" : "info"}
      className="mb-3 DeliveryOptions"
    >
      <Card.Header>
        <strong>{`${modalHeader} (${convertNumToWords(deliveries.length)} ${
          deliveries.length > 1 ? "Deliveries" : "Delivery"
        })`}</strong>
        <Button
          style={{ float: "right" }}
          className="mx-auto mb-2"
          size="sm"
          variant={deliveries.length > 1 ? "primary" : "info"}
          onClick={handleOptionSelect}
        >
          Select
        </Button>
      </Card.Header>
      <Card.Body>
        {deliveries.map((delivery, deliveryIndex) => {
          const prodLength = delivery.products?.length;
          const selectOptions = convertToSlotOptions(delivery.slots);
          const cartValue = delivery?.products?.reduce(
            (agg, item) =>
              (agg += (item?.quantityPossible || 0) * (item?.price || 0)),
            0
          );

          const selectedValue = selectOptions
            ?.flatMap((group) => group.options)
            ?.find(
              (option) =>
                option.value === slotOptions?.slots[deliveryIndex]?.slot
            );

          const isSelected = slotOptions?.slots[deliveryIndex]?.slot;
          const isPodAllowed =
            slotOptions?.slots[deliveryIndex]?.slot?.isPodAllowed;

          const showPodWarning = isSelected && !isPodAllowed;
          const freeDelAmount = selectedValue?.value?.freeDeliveryAmount;
          const delFee = selectedValue?.value?.deliveryFee;
          const diffAmount = freeDelAmount - cartValue;
          const showDelFee = diffAmount > 0;
          const calculatedDelFee = diffAmount > 0 ? delFee : 0;

          return (
            <div key={`delivery-${deliveryIndex}`} style={{ marginBottom: 4 }}>
              <div className="d-flex justify-content-between align-items-center">
                <strong>Delivery {deliveryIndex + 1}</strong>
                <Button
                  className="mb-1 p-1"
                  size="sm"
                  variant="outline-primary"
                  onClick={() => openProductsModal(deliveryIndex)}
                >
                  View {prodLength} {prodLength > 1 ? "items" : "item"}
                </Button>
              </div>
              <Select
                onChange={(option) => handleSlotChange(deliveryIndex, option)}
                options={selectOptions}
                value={selectedValue}
                styles={customStyles}
                isSearchable={false}
                components={{ SingleValue: OptionValue }}
              />
              {showPodWarning && (
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "red",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  *Pay on delivery unavailable for this slot
                </p>
              )}
              {selectedValue?.value?.type === 100 && (
                <span
                  style={{
                    fontSize: "0.9rem",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Delivery Fees: &#8377;{calculatedDelFee}
                  {showDelFee && (
                    <p className="text-body-bold p-0 m-0">
                      Order for &#8377;
                      {diffAmount} more to get a free delivery
                    </p>
                  )}
                </span>
              )}

              {viewedDeliveryIndex === deliveryIndex && (
                <ProductsDisplayModal
                  showModal={viewedDeliveryIndex !== null}
                  modalCancelHandler={productModalCancelHandler}
                  delivery={delivery}
                  deliveryIndex={deliveryIndex}
                />
              )}
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

export default DeliveryOptionCard;

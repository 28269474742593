export function addToLocalStorage(key, object) {
  try {
    const serializedObject = JSON.stringify(object);
    localStorage.setItem(key, serializedObject);
  } catch (error) {
    console.error("Error saving to local storage", error);
  }
}

export function getFromLocalStorage(key) {
  try {
    const serializedObject = localStorage.getItem(key);
    if (serializedObject === null) {
      return null;
    }
    return JSON.parse(serializedObject);
  } catch (error) {
    console.error("Error reading from local storage", error);
    return null;
  }
}

export function removeFromLocalStorage(key) {
  try {
    if (getFromLocalStorage(key)) {
      localStorage.removeItem(key);
    }
  } catch (error) {
    console.error("Error removing item from local storage", error);
  }
}

import { Button, Form, Col } from "react-bootstrap";

const MobileSearchBox = ({
  width,
  onSearchFocus,
  handleSubmit,
  keyword,
  setKeyword,
  inputRef,
}) => {
  return (
    <div style={{ width: width <= 10 ? "98%" : "80%" }} className="mx-auto">
      <Col
        xs={width ? width : 10}
        sm={12}
        md={6}
        lg={4}
        xl={3}
        className="p-1 "
        style={{ width: width <= 10 ? "100%" : "" }}
      >
        <Form
          onSubmit={handleSubmit}
          className="search-form d-flex border-0"
          action="."
        >
          <Form.Control
            type="search"
            className="search-input py-2"
            name="search"
            id="searchbar"
            aria-label="Search"
            value={keyword}
            placeholder="Search for fruits, batters and more"
            onChange={(e) => {
              setKeyword(e.target.value);
              inputRef.current?.focus();
            }}
            onFocus={onSearchFocus}
            ref={inputRef}
          />
          <Button
            type="submit"
            className="search-button"
            onClick={handleSubmit}
          >
            <i className="fas fa-search fa-md" style={{ color: "#006e45" }}></i>
          </Button>
        </Form>
      </Col>
    </div>
  );
};

export default MobileSearchBox;

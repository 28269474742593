import React from "react";
import { Modal } from "react-bootstrap";
import DelOptionsCart from "../DelOptionsCart";

const ProductsDisplayModal = (props) => {
  const { showModal, modalCancelHandler, delivery, deliveryIndex } = props;
  const { products = [] } = delivery;
  const prodLength = products?.length ? products.length : 0;
  return (
    <div>
      <Modal show={showModal} onHide={modalCancelHandler} centered>
        <Modal.Header closeButton>
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Modal.Title
              style={{ paddingLeft: 5 }}
              className="text-heading-secondary"
            >
              Delivery {deliveryIndex + 1}
            </Modal.Title>
            <p style={{ paddingLeft: 6, margin: 0 }} className="text-primary">
              {prodLength} {prodLength > 1 ? "items" : "item"}
            </p>
          </div>
        </Modal.Header>

        {
          <Modal.Body>
            {products?.map((item) => (
              <DelOptionsCart key={item.id} item={item} />
            ))}
          </Modal.Body>
        }
      </Modal>
    </div>
  );
};

export default ProductsDisplayModal;

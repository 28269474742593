import React from "react";
import { getStringSlot } from "../../Utils/SlotFunction";
import { Badge, Card } from "react-bootstrap";
import {
  getIsCancelled,
  getIsDelivered,
  getSlotStatus,
} from "../../Utils/myOrdersHelpers";
import SlotOrdersItems from "./SlotOrdersItems";

const SlotDetails = ({ slot, paymentId }) => {
  const { id, orders } = slot;
  const slotStr =
    slot.type === 100 ? "30 min delivery" : getStringSlot(slot, true);

  const pendingPod = slot?.podCollection?.toCollect;
  const isDelivered = getIsDelivered(id, orders);
  const isCancelled = getIsCancelled(orders);

  const { color, badgeText, badgeColor } = getSlotStatus(
    isCancelled,
    isDelivered
  );

  return (
    <Card
      key={id}
      variant="flush"
      style={{
        border: "1px solid lightGray",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.9rem",
      }}
      className="gap-2"
    >
      <Card.Header
        style={{
          backgroundColor: color,
        }}
      >
        <p className="p-0 m-0 text-heading-secondary">Slot - {slotStr}</p>
        <div className="d-flex gap-3 mt-2 ">
          <Badge bg={badgeColor}>
            <p
              style={{
                margin: 0,
                padding: 0,
                color: "white",
                fontSize: "0.8rem",
              }}
              className="text-heading-secondary"
            >
              {badgeText}
            </p>
          </Badge>
          {pendingPod > 0 && !slot?.podCollection?.isCompleted && (
            <p className="p-0 m-0 text-body-bold">
              Amount Pending &#8377;{pendingPod}
            </p>
          )}
        </div>
      </Card.Header>
      <Card.Body className="py-1 my-0">
        <SlotOrdersItems orders={orders} paymentId={paymentId} />
      </Card.Body>
    </Card>
  );
};

export default SlotDetails;

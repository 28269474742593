import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PlusMinusButton from "../PlusMinusButton";

const ProductActionButton = ({
  product,
  cartQty,
  changeQty,
  removeFromCart,
  showCart = true,
}) => {
  const history = useHistory();

  if (cartQty > 0) {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <PlusMinusButton
          key={product.id}
          qty={cartQty}
          changeQty={changeQty}
          removeFromCart={removeFromCart}
        />
        {"  "}
        {showCart && (
          <Button
            className="bold-button"
            variant="primary"
            onClick={() => history.push("/cart")}
          >
            View Cart
          </Button>
        )}
      </div>
    );
  } else if (product.showStock) {
    if (product.quantityAvailable > 0) {
      return (
        <Button
          className="bold-button add-button-colors"
          onClick={() => changeQty("+")}
          style={{
            float: "right",
          }}
        >
          ADD
        </Button>
      );
    } else {
      return (
        <Button
          className="bold-button"
          variant="outline-primary"
          disabled={true}
          style={{ float: "right" }}
        >
          Sold Out
        </Button>
      );
    }
  } else {
    return (
      <Button
        className="bold-button"
        variant="outline-warning"
        disabled={true}
        style={{ float: "right" }}
      >
        Out of Stock
      </Button>
    );
  }
};

export default ProductActionButton;

export const noListItems = [
  {
    Name: "2,4,5-Trihydroxybutyrophenone",
    Aliases: "THBP",
    Functions: "antioxidant",
  },
  {
    Name: "5-HTP",
    Aliases: "5-hydroxytryptophan",
    Functions: "",
  },
  {
    Name: "Acesulfame-K",
    Aliases: "E950, Acesulfame potassium,\n Acesulfame K",
    Functions: "artificial sweetener",
  },
  {
    Name: "Acetoin, synthetic",
    Aliases: "",
    Functions: "flavor",
  },
  {
    Name: "Acetone peroxides",
    Aliases: "",
    Functions: "bleaching agent",
  },
  {
    Name: "Acetylated esters of mono- and\n diglycerides",
    Aliases:
      "Acetic acid esters of mono-and diglycerides, E472a,\n Acetic acid esters of mono-and diglycerides of fatty acids",
    Functions: "emulsifier",
  },
  {
    Name: "Acidity Regulators",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Activated charcoal",
    Aliases: "Charcoal powder, Volcanic\n ash, Activated carbon",
    Functions: "absorbent",
  },
  {
    Name: "Adulterants",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Advantame",
    Aliases: "",
    Functions: "artificial sweetener",
  },
  {
    Name: "Alkanna Tinctoria",
    Aliases: "Alkanet, Alkanna tinctoria\n Tausch",
    Functions: "colorant",
  },
  {
    Name: "Allulose",
    Aliases: "",
    Functions: "sweetener",
  },
  {
    Name: "Alpha-cyclodextrin",
    Aliases: "",
    Functions: "stabilizer, thickener",
  },
  {
    Name: "Aluminum",
    Aliases: "E173",
    Functions: "colorant",
  },
  {
    Name: "Aluminum ammonium sulfate",
    Aliases: "E523",
    Functions: "pH control agent",
  },
  {
    Name: "Aluminum potassium sulfate",
    Aliases: "E522",
    Functions: "pH control agent",
  },
  {
    Name: "Aluminum sodium sulfate",
    Aliases: "E521",
    Functions: "firming agent",
  },
  {
    Name: "Aluminum starch octenylsuccinate",
    Aliases: "Starch aluminum octenyl\n succinate, E1452",
    Functions: "stabilizer, anti-caking agent, absorbent",
  },
  {
    Name: "Aluminum sulfate",
    Aliases: "E520",
    Functions: "firming agent",
  },
  {
    Name: "Ammonium alum",
    Aliases: "Aluminum ammonium sulfate",
    Functions: "anti-perspirant, leavening agent",
  },
  {
    Name: "Ammonium chloride",
    Aliases: "",
    Functions: "viscosity controlling agent, fermentation\n enhancer, buffer",
  },
  {
    Name: "Ammonium hydroxide",
    Aliases: "E527",
    Functions: "excipient, pH control agent, firming\n agent",
  },
  {
    Name: "Ammonium saccharin",
    Aliases: "",
    Functions: "artificial sweetener",
  },
  {
    Name: "Ammonium sulfate",
    Aliases: "E517",
    Functions: "fermentation enhancer, pH control\n agent",
  },
  {
    Name: "Apricot kernel",
    Aliases: "Apricot kernel extract",
    Functions: "",
  },
  {
    Name: "Artificial flavors",
    Aliases: "",
    Functions: "flavor",
  },
  {
    Name: "Artificial sweeteners",
    Aliases: "",
    Functions: "sweetener",
  },
  {
    Name: "Aspartame",
    Aliases: "E951",
    Functions: "artificial sweetener",
  },
  {
    Name: "Aspartame-acesulfame salt",
    Aliases: "E962",
    Functions: "artificial sweetener",
  },
  {
    Name: "Astragalus",
    Aliases: "Astragalus membranaceus\n root extract",
    Functions: "herb",
  },
  {
    Name: "Azo dyes",
    Aliases: "",
    Functions: "colorant",
  },
  {
    Name: "Azodicarbonamide",
    Aliases: "",
    Functions: "dough conditioner",
  },
  {
    Name: "Bacillus coagulans ProDURA UABc-20",
    Aliases: "",
    Functions: "probiotic",
  },
  {
    Name: "Bacillus coagulans Unique IS-2",
    Aliases: "",
    Functions: "probiotic",
  },
  {
    Name: "Bacopa",
    Aliases: "",
    Functions: "herb",
  },
  {
    Name: "Bentonite",
    Aliases: "E558",
    Functions:
      "viscosity controlling agent, absorbent,\n bulking agent, anti-caking agent",
  },
  {
    Name: "Benzoates",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Benzoic acid",
    Aliases: "E210",
    Functions: "preservative",
  },
  {
    Name: "Benzophenone",
    Aliases: "",
    Functions: "sunscreen, UV absorber, flavoring\n agent",
  },
  {
    Name: "Benzoyl peroxide",
    Aliases: "E928",
    Functions: "",
  },
  {
    Name: "Benzyl alcohol",
    Aliases: "E1519",
    Functions: "",
  },
  {
    Name: "Benzyl benzoate",
    Aliases: "Phenylmethyl benzoate",
    Functions: "preservative",
  },
  {
    Name: "Beta-cyclodextrin",
    Aliases: "E459",
    Functions: "stabilizer, thickener, emulsifier",
  },
  {
    Name: "Biphenyl",
    Aliases: "E230, Diphenyl",
    Functions: "preservative",
  },
  {
    Name: "Black soldier fly",
    Aliases: "",
    Functions: "protein",
  },
  {
    Name: "Boric acid",
    Aliases: "E284",
    Functions: "preservative, pH control agent,\n anti-microbial",
  },
  {
    Name: "Boswellia",
    Aliases: "Indian frankincense",
    Functions: "herb extract",
  },
  {
    Name: "Brilliant Black BN",
    Aliases: "Black PN, E151",
    Functions: "artificial color",
  },
  {
    Name: "Brominated vegetable oil",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Brown FK",
    Aliases: "E154, Kipper brown",
    Functions: "artificial color",
  },
  {
    Name: "Brown HT",
    Aliases: "E155, Chocolate brown HT",
    Functions: "artificial color",
  },
  {
    Name: "Butylated hydroxyanisole",
    Aliases: "E320, BHA",
    Functions: "preservative, antioxidant",
  },
  {
    Name: "Butylated hydroxytoluene",
    Aliases: "BHT",
    Functions: "antioxidant",
  },
  {
    Name: "Butylparaben",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Caffeine, extended release",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Calcium 5'-ribonucleotides",
    Aliases: "E634",
    Functions: "flavor enhancer",
  },
  {
    Name: "Calcium benzoate",
    Aliases: "E213",
    Functions: "preservative",
  },
  {
    Name: "Calcium bromate",
    Aliases: "Bromic acid calcium salt",
    Functions: "",
  },
  {
    Name: "Calcium diglutamate",
    Aliases: "E623, Monocalcium\n glutamate",
    Functions: "flavor enhancer",
  },
  {
    Name: "Calcium disodium EDTA",
    Aliases:
      "Disodium calcium EDTA,\n E385, Calcium disodium\n ethylene diamine tetraacetate",
    Functions: "sequestrant, antioxidant, flavor\n enhancer",
  },
  {
    Name: "Calcium guanylate",
    Aliases: "E629",
    Functions: "flavor enhancer",
  },
  {
    Name: "Calcium hydrogen sulfite",
    Aliases: "E227",
    Functions: "preservative",
  },
  {
    Name: "Calcium inosinate",
    Aliases: "E633",
    Functions: "flavor enhancer",
  },
  {
    Name: "Calcium peroxide",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Calcium propionate",
    Aliases: "Proponoic acid, E282",
    Functions: "preservative",
  },
  {
    Name: "Calcium sorbate",
    Aliases: "E203",
    Functions: "preservative",
  },
  {
    Name: "Calcium stearoyl-2-lactylate",
    Aliases: "E482",
    Functions: "emulsifier",
  },
  {
    Name: "Calcium sulfite",
    Aliases: "E226",
    Functions: "preservative",
  },
  {
    Name: "Cannabidiol",
    Aliases: "CBD",
    Functions: "",
  },
  {
    Name: "Canthaxanthin",
    Aliases: "E161g",
    Functions: "colorant",
  },
  {
    Name: "Caprocaprylobehenin",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Carbon monoxide",
    Aliases: "",
    Functions: "colorant fixer, preservative",
  },
  {
    Name: "Carmine",
    Aliases: "Cochineal, Carminic acid,\n E120",
    Functions: "colorant",
  },
  {
    Name: "Carmoisine",
    Aliases: "Azorubine, E122",
    Functions: "artificial color",
  },
  {
    Name: "Certified colors",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Chaga",
    Aliases: "",
    Functions: "mushroom",
  },
  {
    Name: "Citric acid esters of mono- and\n diglycerides of fatty acids",
    Aliases: "E472c",
    Functions: "",
  },
  {
    Name: "Citrus Red No. 2",
    Aliases: "E121",
    Functions: "artificial color",
  },
  {
    Name: "Cluster Dextrin",
    Aliases: "Brand name for Highly\n branched cyclic dextrin",
    Functions: "carbohydrate",
  },
  {
    Name: "Colloidal silver",
    Aliases: "",
    Functions: "mineral",
  },
  {
    Name: "Cordyceps",
    Aliases: "",
    Functions: "mushroom",
  },
  {
    Name: "Crickets",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Cyclamate",
    Aliases:
      "Sodium cyclamate, Cyclamic\n acid and its Na and Ca salts,\n cyclohexanesulfamic acid,\n E952",
    Functions: "artificial sweetener",
  },
  {
    Name: "Cyclodextrin",
    Aliases: "",
    Functions: "stabilizer",
  },
  {
    Name: "DATEM",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Diacetyl, synthetic",
    Aliases: "",
    Functions: "flavor",
  },
  {
    Name: "Diatomaceous earth",
    Aliases: "",
    Functions: "bulking agent, anti-caking agent",
  },
  {
    Name: "Dimethylpolysiloxane",
    Aliases:
      "Dimethyl Silicone, E900,\n Dimethyl polysiloxane,\n Polydimethylsiloxane",
    Functions: "anti-caking agent, anti-foaming agent",
  },
  {
    Name: "Dioctyl sodium sulfosuccinate (DSS)",
    Aliases: "",
    Functions: "emulsifier",
  },
  {
    Name: "Dipotassium guanylate",
    Aliases: "E628",
    Functions: "flavor enhancer",
  },
  {
    Name: "Dipotassium inosinate",
    Aliases: "E632",
    Functions: "flavor enhancer",
  },
  {
    Name: "Disodium 5'-ribonucleotide",
    Aliases: "Disodium 5&#8242;-ribonucleotides",
    Functions: "flavor enhancer",
  },
  {
    Name: "Disodium EDTA",
    Aliases: "E386, Disodium\n ethylenediaminetetraacetate",
    Functions: "flavoring agent, chelator",
  },
  {
    Name: "Disodium guanylate",
    Aliases:
      "E627, Disodium\n 5&#8242;-guanylate,\n Disodium guanosine\n 5&#8242;-monophosphate,\n Sodium 5&#8242; guanylate",
    Functions: "flavor enhancer",
  },
  {
    Name: "Disodium inosinate",
    Aliases:
      "E631, Disodium\n 5&#8242;-inosinate,\n Disodium 5-inosinate,\n Sodium 5&#8242; inosinate",
    Functions: "flavor enhancer",
  },
  {
    Name: "Disodium iron EDTA",
    Aliases: "",
    Functions: "sequestrant, stabilizer",
  },
  {
    Name: "Dodecyl gallate",
    Aliases: "E312",
    Functions: "preservative",
  },
  {
    Name: "EDTA",
    Aliases: "Edetic acid,\n Ethylenediaminetetraacetic\n acid",
    Functions: "chelator",
  },
  {
    Name: "Eleuthero",
    Aliases:
      "Eleutherococcus senticosus\n (Rupr. &; Maxim.)\n Maxim. [Araliaceae],\n Eleutherococcus senticosus,\n Siberian ginseng",
    Functions: "herb",
  },
  {
    Name: "Ephedra",
    Aliases: "Ma huang",
    Functions: "",
  },
  {
    Name: "Ethoxyquin",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Ethyl acrylate, synthetic",
    Aliases: "",
    Functions: "artificial flavor",
  },
  {
    Name: "Ethyl vanillin",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Ethylene glycol",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Ethylene oxide",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Ethylenediaminetetraacetic acid\n disodium salt",
    Aliases: "Disodium EDTA dihydrate,\n EDTA disodium salt dihydrate",
    Functions: "flavoring agent",
  },
  {
    Name: "Ethylparaben",
    Aliases: "Ethyl p-hydroxybenzoate,\n ethyl para-hydroxybenzoate,\n E214",
    Functions: "preservative",
  },
  {
    Name: "Eugenyl methyl ether, synthetic",
    Aliases: "",
    Functions: "artificial flavor",
  },
  {
    Name: "FD&C Blue No. 1",
    Aliases: "Brilliant Blue FCF, E133",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Blue No. 2",
    Aliases: "Indigotine, Indigo Carmine,\n E132",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Colors",
    Aliases: "",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Green No. 3",
    Aliases: "",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Red No. 2",
    Aliases: "Amaranth (colorant), E123",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Red No. 3",
    Aliases: "Erythrosine, E127",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Red No. 40",
    Aliases: "Allura Red AC, E129",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Yellow No. 5",
    Aliases: "Tartrazine, E102",
    Functions: "artificial color",
  },
  {
    Name: "FD&C Yellow No. 6",
    Aliases: "E110, Sunset Yellow FCF,\n Orange Yellow S",
    Functions: "artificial color",
  },
  {
    Name: "Foie gras",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Gamma aminobutyric acid",
    Aliases: "GABA",
    Functions: "dietary supplement",
  },
  {
    Name: "Gamma-cyclodextrin",
    Aliases: "",
    Functions: "flavor carrier, stabilizer",
  },
  {
    Name: "Garcinia cambogia",
    Aliases: "Hydroxycitric acid (HCA)",
    Functions: "herb",
  },
  {
    Name: "Gardenia blue",
    Aliases: "",
    Functions: "colorant",
  },
  {
    Name: "Ginkgo",
    Aliases: "Ginkgo biloba",
    Functions: "herb",
  },
  {
    Name: "Glucose Syrup",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Glutamic acid",
    Aliases: "E620",
    Functions: "flavor enhancer",
  },
  {
    Name: "Glycine",
    Aliases: "E640",
    Functions: "flavor enhancer",
  },
  {
    Name: "GMP",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Grapefruit seed extract",
    Aliases: "",
    Functions: "preservative, anti-microbial, anti-fungal",
  },
  {
    Name: "Green S",
    Aliases: "E142",
    Functions: "artificial color",
  },
  {
    Name: "Guanylic acid",
    Aliases: "E626",
    Functions: "flavor enhancer",
  },
  {
    Name: "Hawaiian black salt",
    Aliases: "Hawaiian black sea salt,\n Black lava salt",
    Functions: "",
  },
  {
    Name: "He shou wu",
    Aliases: "Fo-ti",
    Functions: "",
  },
  {
    Name: "Heptylparaben",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Hexa-, hepta- and octa-esters of sucrose",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Hexamethylene tetramine",
    Aliases: "E239",
    Functions: "preservative",
  },
  {
    Name: "High fructose corn syrup",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Highly branched cyclic dextrin",
    Aliases: "Cluster Dextrin",
    Functions: "carbohydrate",
  },
  {
    Name: "Hijiki",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Horsetail",
    Aliases:
      "Field horsetail, Equisetum\n arvense, Shave grass,\n Shavetail grass",
    Functions: "herb",
  },
  {
    Name: "Hydrochloric acid",
    Aliases: "E507, Muriatic acid",
    Functions: "acidulant",
  },
  {
    Name: "Hydrogenated canola oil",
    Aliases: "Fully hydrogenated canola oil",
    Functions: "oil, fat",
  },
  {
    Name: "Hydrogenated corn oil",
    Aliases: "Fully hydrogenated corn oil",
    Functions: "oil, fat",
  },
  {
    Name: "Hydrogenated cottonseed oil",
    Aliases: "Fully hydrogenated\n cottonseed oil",
    Functions: "oil, fat",
  },
  {
    Name: "Hydrogenated oils",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Hydrogenated palm oil",
    Aliases: "Fully hydrogenated palm oil",
    Functions: "oil, fat",
  },
  {
    Name: "Hydrogenated peanut oil",
    Aliases: "Fully hydrogenated peanut oil",
    Functions: "oil, fat",
  },
  {
    Name: "Hydrogenated soybean oil",
    Aliases: "Fully hydrogenated soybean\n oil",
    Functions: "oil, fat",
  },
  {
    Name: "Inosine monophosphate",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Inosinic acid",
    Aliases: "E630",
    Functions: "flavor enhancer",
  },
  {
    Name: "Iron oxide",
    Aliases: "E172, Iron hydroxides,\n Synthetic iron oxide",
    Functions: "colorant",
  },
  {
    Name: "Kava",
    Aliases: "Kava kava",
    Functions: "herb",
  },
  {
    Name: "L-Cysteine",
    Aliases: "E920, Cysteine",
    Functions: "amino acid, dough conditioner",
  },
  {
    Name: "Lactic acid esters of monoglycerides",
    Aliases:
      "E472b, Lactic acid esters of\n mono- and diglycerides of\n fatty acids",
    Functions: "emulsifier",
  },
  {
    Name: "Lactylated esters of mono- and\n diglycerides",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Lard, hydrogenated or partially\n hydrogenated",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Lithol rubine BK",
    Aliases: "E180, Pigment Rubine",
    Functions: "colorant",
  },
  {
    Name: "Magnesium diglutamate",
    Aliases: "E625",
    Functions: "flavor enhancer",
  },
  {
    Name: "Magnesium salts of fatty acids",
    Aliases: "E470b",
    Functions: "emulsifier, anti-caking agent",
  },
  {
    Name: "Magnesium silicate",
    Aliases: "Magnesium trisilicate, E553a",
    Functions: "anti-caking agent",
  },
  {
    Name: "Maltodextrin",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Melatonin",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Methyl silicon",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Methylparaben",
    Aliases: "E218, methyl\n para-hydroxybenzoate,\n methyl p-hydroxybenzoate",
    Functions: "preservative",
  },
  {
    Name: "Microcrystalline wax",
    Aliases: "E905, Parrafins, Petroleum\n wax",
    Functions: "coating agent",
  },
  {
    Name: "Microparticularized whey protein derived\n fat substitute",
    Aliases: "Simplesse",
    Functions: "",
  },
  {
    Name: "Milk thistle",
    Aliases: "Silybum marianum",
    Functions: "herb",
  },
  {
    Name: "Mixed acetic and tartaric acid esters of\n mono- and diglycerides of fatty acids",
    Aliases: "E472f",
    Functions: "emulsifier",
  },
  {
    Name: "Mono- and diacetyl tartaric acid esters of\n mono- and diglycerides of fatty acids",
    Aliases: "E472e",
    Functions: "emulsifier",
  },
  {
    Name: "Monoammonium glutamate",
    Aliases: "E624",
    Functions: "flavor enhancer",
  },
  {
    Name: "Monopotassium glutamate",
    Aliases: "E622",
    Functions: "flavor enhancer",
  },
  {
    Name: "Monosodium glutamate",
    Aliases: "E621, MSG, L-monosodium\n glutamate",
    Functions: "flavor enhancer",
  },
  {
    Name: "Montan acid esters",
    Aliases:
      "Montan wax fatty acids,\n oxidatively refined, polyhydric\n alcohol",
    Functions: "",
  },
  {
    Name: "Mucuna pruriens",
    Aliases: "Mucuna, Mucuna extract",
    Functions: "",
  },
  {
    Name: "Myrcene, synthetic",
    Aliases: "",
    Functions: "artificial flavor",
  },
  {
    Name: "Nano silver",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Natamycin",
    Aliases: "E235",
    Functions: "",
  },
  {
    Name: "Nature identical flavors",
    Aliases: "",
    Functions: "flavor",
  },
  {
    Name: "Neohesperidine dihydrochalcone",
    Aliases: "Neohesperidine DC, E959",
    Functions: "artificial sweetener, flavor enhancer",
  },
  {
    Name: "Neotame",
    Aliases: "E961",
    Functions: "",
  },
  {
    Name: "Nitrates, synthetic",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Nitrites",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Octyl gallate",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Olestra",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Oligofructose syrup",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Orange B",
    Aliases: "",
    Functions: "artificial color",
  },
  {
    Name: "Orthophenyl phenol",
    Aliases: "E231",
    Functions: "preservative",
  },
  {
    Name: "Oxidised Polyethylene wax",
    Aliases: "E914",
    Functions: "glazing agent, wax",
  },
  {
    Name: "Papad Khar (chemical used in papads)",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Parabens",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Partially hydrogenated canola oil",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Partially hydrogenated corn oil",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Partially hydrogenated cottonseed oil",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Partially hydrogenated oil",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Partially hydrogenated palm oil",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Partially hydrogenated peanut oil",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Partially hydrogenated soybean oil",
    Aliases: "",
    Functions: "oil, fat",
  },
  {
    Name: "Patent Blue V",
    Aliases: "E131",
    Functions: "artificial color",
  },
  {
    Name: "Plant sterols",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Polydextrose",
    Aliases: "E1200",
    Functions: "humectant, nutrient, stabilizer,\n thickener",
  },
  {
    Name: "Polyglycerol polyricinoleate",
    Aliases: "PGPR, E476",
    Functions: "emulsifier",
  },
  {
    Name: "Polyoxyethene (40) stearate",
    Aliases: "E431",
    Functions: "emulsifier",
  },
  {
    Name: "Polyvinylpolypyrrolidone",
    Aliases: "E1202",
    Functions: "stabilizer",
  },
  {
    Name: "Polyvinylpyrrolidone",
    Aliases: "E1201",
    Functions: "stabilizer",
  },
  {
    Name: "Ponceau 4R",
    Aliases: "Cochineal Red A, E124,\n Brilliant Scarlet 4R",
    Functions: "artificial color",
  },
  {
    Name: "Potassium alum",
    Aliases: "Aluminum potassium sulfate",
    Functions: "firming agent, astringent",
  },
  {
    Name: "Potassium benzoate",
    Aliases: "E212",
    Functions: "preservative",
  },
  {
    Name: "Potassium bisulfite",
    Aliases: "E228, Potassium hydrogen\n sulfite",
    Functions: "anti-microbial",
  },
  {
    Name: "Potassium bromate",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Potassium metabisulfite",
    Aliases: "E224",
    Functions: "",
  },
  {
    Name: "Potassium nitrate",
    Aliases: "E252, Saltpetre",
    Functions: "preservative, active",
  },
  {
    Name: "Potassium nitrite",
    Aliases: "E249",
    Functions:
      "anti-microbial, antioxidant, colorant,\n flavoring agent, preservative",
  },
  {
    Name: "Potassium propionate",
    Aliases: "E283",
    Functions: "preservative",
  },
  {
    Name: "Potassium sorbate",
    Aliases: "E202",
    Functions: "preservative",
  },
  {
    Name: "Propionates",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Propionic acid",
    Aliases: "E280",
    Functions:
      "anti-microbial, flavoring agent, pH\n control agent, preservative",
  },
  {
    Name: "Propyl gallate",
    Aliases: "E310, E311",
    Functions: "antioxidant, flavoring agent",
  },
  {
    Name: "Propylene glycol esters of fatty acids",
    Aliases: "Propane-1,2-Diol esters of\n fatty acids, E477",
    Functions: "",
  },
  {
    Name: "Propylene glycol mono- and diesters of\n fats and fatty acids",
    Aliases: "",
    Functions: "emulsifier",
  },
  {
    Name: "Propylene oxide",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Propylparaben",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Pulegone, synthetic",
    Aliases: "",
    Functions: "artificial flavor",
  },
  {
    Name: "Pullulan",
    Aliases: "E1204",
    Functions: "film former",
  },
  {
    Name: "Pyridine, synthetic",
    Aliases: "",
    Functions: "artificial flavor",
  },
  {
    Name: "Quinoline yellow WS",
    Aliases: "E104",
    Functions: "artificial color",
  },
  {
    Name: "Rhodiola",
    Aliases: "Rhodiola rosea",
    Functions: "herb",
  },
  {
    Name: "Saccharin and its sodium, potassium and\n calcium salts",
    Aliases: "Saccharin, calcium salt,\n Saccharin, potassium salt",
    Functions: "artificial sweetener",
  },
  {
    Name: "Saccharin, sodium salt",
    Aliases: "E954",
    Functions: "artificial sweetener",
  },
  {
    Name: "Salatrim (short and long chain acyl\n triglyceride molecule)",
    Aliases: "",
    Functions: "fat substitute",
  },
  {
    Name: "Shugr",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Slippery elm",
    Aliases: "Ulmus rubra, Ulmus fulva",
    Functions: "herb",
  },
  {
    Name: "Smoke flavor, synthetic",
    Aliases: "",
    Functions: "flavor",
  },
  {
    Name: "Soda (Only allowed in Baking powder)",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sodium acid sulfate",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sodium aluminum phosphate",
    Aliases: "E541",
    Functions: "",
  },
  {
    Name: "Sodium aluminum sulfate",
    Aliases: "Aluminum sulfate, Burnt\n alum, Sodium alum",
    Functions: "firming agent, astringent,\n anti-perspirant",
  },
  {
    Name: "Sodium benzoate",
    Aliases: "E211",
    Functions: "preservative",
  },
  {
    Name: "Sodium bisulfite",
    Aliases: "E222, Sodium hydrogen\n sulphite",
    Functions: "anti-microbial, antioxidant, colorant,\n dough conditioner",
  },
  {
    Name: "Sodium diacetate",
    Aliases: "Sodium hydrogen acetate",
    Functions: "",
  },
  {
    Name: "Sodium ethyl para hydroxybenzoate",
    Aliases: "Sodium ethyl\n p-hydroxybenzoate, E215",
    Functions: "preservative",
  },
  {
    Name: "Sodium lauryl sulfate",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sodium metabisulfite",
    Aliases: "E223",
    Functions: "",
  },
  {
    Name: "Sodium methyl p-hydroxybenzoate",
    Aliases: "E219, Sodium methyl para\n hydroxybenzoate",
    Functions: "preservative",
  },
  {
    Name: "Sodium nitrate, synthetic",
    Aliases: "E251, Chile saltpetre",
    Functions: "preservative",
  },
  {
    Name: "Sodium nitrite, synthetic",
    Aliases: "E250",
    Functions: "preservative",
  },
  {
    Name: "Sodium orthophenyl phenol",
    Aliases: "E232",
    Functions: "preservative",
  },
  {
    Name: "Sodium propionate",
    Aliases: "E281",
    Functions: "anti-microbial, flavoring agent,\n preservative",
  },
  {
    Name: "Sodium sorbate",
    Aliases: "",
    Functions: "preservative",
  },
  {
    Name: "Sodium stearoyl lactylate",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sodium stearoyl-2-lactylate",
    Aliases: "E481",
    Functions: "emulsifier",
  },
  {
    Name: "Sodium sulfite",
    Aliases: "E221",
    Functions: "",
  },
  {
    Name: "Sodium, potassium and calcium salts of\n fatty acids",
    Aliases: "E470a",
    Functions: "emulsifier, anti-caking agent",
  },
  {
    Name: "Sorbic acid",
    Aliases: "E200",
    Functions: "preservative",
  },
  {
    Name: "Soy leghemoglobin",
    Aliases: "",
    Functions: "colorant",
  },
  {
    Name: "Stannous chloride",
    Aliases: "E512",
    Functions: "preservative, antioxidant",
  },
  {
    Name: "Stearyl tartrate",
    Aliases: "E483",
    Functions: "emulsifier",
  },
  {
    Name: "Succistearin",
    Aliases: "",
    Functions: "emulsifier",
  },
  {
    Name: "Sucralose",
    Aliases: "E955",
    Functions: "artificial sweetener",
  },
  {
    Name: "Sucroglycerides",
    Aliases: "E474",
    Functions: "emulsifier",
  },
  {
    Name: "Sucrose acetate isobutyrate",
    Aliases: "E444",
    Functions: "emulsifier",
  },
  {
    Name: "Sucrose ester",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sucrose esters of fatty acids",
    Aliases: "E473",
    Functions: "",
  },
  {
    Name: "Sucrose polyester",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sulfites",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Sulfur dioxide",
    Aliases: "E220",
    Functions: "preservative, color retention agent",
  },
  {
    Name: "Sulfuric acid",
    Aliases: "E513",
    Functions: "acidulant",
  },
  {
    Name: "tagatose",
    Aliases: "E321",
    Functions: "preservative, antioxidant",
  },
  {
    Name: "Talc",
    Aliases: "E553b",
    Functions:
      "anti-caking agent, bulking agent,\n excipient, absorbent, opacifier",
  },
  {
    Name: "tert-Butylhydroquinone",
    Aliases: "E319, TBHQ",
    Functions: "antioxidant",
  },
  {
    Name: "Tetrasodium EDTA",
    Aliases: "",
    Functions: "chelator, preservative, sequestrant",
  },
  {
    Name: "Thermally oxidized soya bean oil\n interacted with mono- and diglycerides of\n fatty acids",
    Aliases: "E479b",
    Functions: "emulsifier",
  },
  {
    Name: "Thiodipropionic acid",
    Aliases: "",
    Functions: "antioxidant",
  },
  {
    Name: "Toluene",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Tonka",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Triammonium citrate",
    Aliases: "E380",
    Functions: "pH control agent",
  },
  {
    Name: "Triclosan",
    Aliases: "",
    Functions: "",
  },
  {
    Name: "Urea",
    Aliases: "Carbamide, E927b",
    Functions: "buffer, humectant, skin conditioning\n agent",
  },
  {
    Name: "Valerian",
    Aliases: "",
    Functions: "herb",
  },
  {
    Name: "Vegetable Carbon",
    Aliases: "E153",
    Functions: "artificial color",
  },
  {
    Name: "Whale oil",
    Aliases: "",
    Functions: "fat",
  },
  {
    Name: "Zinc acetate",
    Aliases: "E650",
    Functions: "flavor enhancer",
  },
];

import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../Actions/CartActions";
import PlusMinusButton from "../PlusMinusButton";
import PortionSize from "../PortionSize";
import { displayFlashMessage } from "../../Actions/FlashMessageActions";

function CartItem({ item }) {
  const dispatch = useDispatch();

  const changeQty = (change) => {
    if (change === "+" && !(item.quantityAvailable > item.quantityPossible)) {
      dispatch(
        displayFlashMessage({
          position: "bottom-center",
          delay: 3000,
          background: "primary",
          string: "Sorry! No more stock",
        })
      );
    } else {
      dispatch(
        addToCart(
          item.id,
          change === "+" ? item.quantityPossible + 1 : item.quantityPossible - 1
        )
      );
    }
  };

  const removeItemFromCart = () => {
    dispatch(removeFromCart(item.id));
  };

  return (
    <>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={2} md={2}>
          <Link to={`/product/${item.id}`}>
            <Image
              src={item.cartImage}
              alt={item.name}
              fluid
              style={{ borderRadius: 5, marginLeft: 5 }}
            />
          </Link>
        </Col>
        <Col xs={6} md={4}>
          <Link
            className="text-decoration-none align-middle"
            to={`/product/${item.id}`}
          >
            <p
              className="text-heading-secondary p-style p-0"
              style={{ fontSize: "0.9rem" }}
            >
              {item.name}
            </p>
          </Link>
          <p className="align-middle p-0 m-0" style={{ fontSize: "0.9rem" }}>
            &#8377; {item.price}{" "}
            {item.quantityPossible > 1 ? `x ${item.quantityPossible}` : ""}
          </p>
        </Col>
        <Col
          xs={4}
          md={3}
          className="py-1"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p className="pe-1 p-0 m-0" style={{ fontSize: "0.9rem" }}>
            {<PortionSize packageSize={item.packageSize} />}{" "}
            {item.measurementUnit}{" "}
            {item.quantityPossible > 1 ? `x ${item.quantityPossible}` : ""}
          </p>
          <PlusMinusButton
            key={item.id}
            qty={item.quantityPossible}
            changeQty={changeQty}
            removeFromCart={removeItemFromCart}
          />
        </Col>
      </Row>
      {Boolean(item.changeMessage && item.changeMessage.length > 0) && (
        <i>{item.changeMessage}</i>
      )}
    </>
  );
}

export default CartItem;

import React from "react";
import { Button, Row, Col } from "react-bootstrap";

const ButtonContainerSticky = (props) => {
  const {
    totalItems,
    totalAmount,
    discountCoupon,
    discountAmount,
    balanceToPay,
    cartItems,
    handleCheckout,
  } = props;
  return (
    <div className="button-container-sticky" style={{ zIndex: 100 }}>
      <Row
        className="p-0 m-0"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Col>
          <h6 className="text-heading-secondary">Total ({totalItems}) items</h6>
          <h6 className="text-body-bold">
            Amount : &#8377; {totalAmount.toFixed(2)}
          </h6>
          {discountCoupon?.isValid && (
            <>
              <h6 style={{ color: "red" }}>
                Discount : - &#8377; {discountAmount}
              </h6>
              <h6 className="text-body-bold">
                Balance : &#8377; {balanceToPay.toFixed(2)}
              </h6>
            </>
          )}
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              variant="primary"
              disabled={cartItems.length === 0}
              onClick={handleCheckout}
            >
              <strong>Buy Now</strong>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ButtonContainerSticky;

import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

function FlashMessage({ show, setShow, delay, background, message, position }) {
  return (
    <ToastContainer
      className="p-3 flash-message"
      position={position}
      style={{ zIndex: 100 }}
    >
      <Toast
        onClose={() => setShow(false)}
        bg={background}
        show={show}
        delay={delay}
        autohide
      >
        <Toast.Header>
          <span className="m-auto text-black">{message}</span>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
}

export default FlashMessage;

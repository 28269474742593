import React from "react";
import CollectionScrollSkeleton from "./CollectionScrollSkeleton";

const CollectionMadeTypeSkeleton = () => {
  return (
    <div className={`mt-4 stretch-component-full d-flex flex-column`}>
      <h4 className="text-heading-primary" style={{ paddingLeft: 10 }}>
        Explore Collections
      </h4>
      {<CollectionScrollSkeleton />}
      {<CollectionScrollSkeleton />}
    </div>
  );
};

export default CollectionMadeTypeSkeleton;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Product from "../ProductCard/Product";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryItems } from "../../Actions/ProductActions";
import Loader from "../Loader";
import ErrorHandle from "../ErrorHandle";

const CategoryProductList = ({ categoryId, subcategoryId, setShowToast }) => {
  const [showRecContainer, setShowRecContainer] = useState(null);
  const { categoryProducts, loading, error } = useSelector(
    (state) => state.categoryProducts
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryItems(categoryId, subcategoryId));
  }, [dispatch, categoryId, subcategoryId]);

  const products = categoryProducts?.products || [];

  if (loading) {
    return <Loader />;
  }

  if (error) {
    <ErrorHandle error={error} />;
  }
  if (products.length === 0) {
    return (
      <h5 className="mt-2" style={{ fontSize: "1rem" }}>
        Sorry, no products here right now. We will get more items soon.
      </h5>
    );
  }
  return (
    <Row>
      {products?.map(
        (product) =>
          product?.earliestDeliverySlot && (
            <Col key={`column-${product.id}`} xs={12} md={6} lg={6} xl={4}>
              {product?.showStock && (
                <Product
                  key={product.id}
                  product={product}
                  setShowToast={setShowToast}
                  showRecContainer={showRecContainer}
                  setShowRecContainer={setShowRecContainer}
                  screen="CATEGORY"
                />
              )}
            </Col>
          )
      )}
    </Row>
  );
};

export default CategoryProductList;

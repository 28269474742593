import { useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MobileSearchBox from "./MobileSearchBox";
import SearchBox from "./SearchBox";

const SearchBoxMain = ({ width, isMobile }) => {
  let history = useHistory();
  const [keyword, setKeyword] = useState("");
  const { pathname } = useLocation();
  const inputRef = useRef(null);

  const onSearchFocus = () => {
    if (!pathname?.includes("/search")) {
      history.push(`/search`);
    }
    inputRef.current?.focus();
  };
  if (isMobile) {
    return (
      <MobileSearchBox
        width={width}
        onSearchFocus={onSearchFocus}
        keyword={keyword}
        setKeyword={setKeyword}
        inputRef={inputRef}
      />
    );
  }

  return (
    <SearchBox
      width={width}
      onSearchFocus={onSearchFocus}
      keyword={keyword}
      setKeyword={setKeyword}
    />
  );
};

export default SearchBoxMain;

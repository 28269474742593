import React from "react";
import { ListGroup, Button, Row, Col } from "react-bootstrap";
import ButtonContainerSticky from "./ButtonContainerSticky";

const ButtonContainer = (props) => {
  const {
    totalItems,
    totalAmount,
    discountCoupon,
    discountAmount,
    balanceToPay,
    cartItems,
    handleCheckout,
    history,
    width,
  } = props;

  if (width < 500) {
    return (
      <ButtonContainerSticky
        totalItems={totalItems}
        totalAmount={totalAmount}
        discountCoupon={discountCoupon}
        discountAmount={discountAmount}
        balanceToPay={balanceToPay}
        cartItems={cartItems}
        handleCheckout={handleCheckout}
        history={history}
      />
    );
  }

  return (
    <ListGroup.Item
      style={{
        marginTop: 15,
        background: "#f5f5f5",
        padding: 20,
        borderRadius: 10,
        width: "100%",
      }}
    >
      <Row>
        <Col>
          <h6 className="text-heading-secondary">Total ({totalItems}) items</h6>
          <h6 className="text-body-bold">
            Amount : &#8377; {totalAmount.toFixed(2)}
          </h6>
          {discountCoupon?.isValid && (
            <>
              <h6 style={{ color: "red" }}>
                Discount : - &#8377; {discountAmount}
              </h6>
              <h6 className="text-body-bold">
                Balance : &#8377; {balanceToPay.toFixed(2)}
              </h6>
            </>
          )}
        </Col>
        <Col>
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              disabled={cartItems.length === 0}
              onClick={handleCheckout}
            >
              <strong>Buy Now</strong>
            </Button>
            <Button variant="outline-warning" onClick={() => history.push("/")}>
              <strong>Shop more</strong>
            </Button>
          </div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

export default ButtonContainer;

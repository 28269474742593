export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const PRODUCT_LIST_RESET = "PRODUCT_LIST_RESET";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_PRODUCTS_REQUEST = "CATEGORY_PRODUCTS_REQUEST";
export const CATEGORY_PRODUCTS_SUCCESS = "CATEGORY_PRODUCTS_SUCCESS";
export const CATEGORY_PRODUCTS_FAIL = "CATEGORY_PRODUCTS_FAIL";

export const CATEGORY_DETAILS_REQUEST = "CATEGORY_DETAILS_REQUEST";
export const CATEGORY_DETAILS_SUCCESS = "CATEGORY_DETAILS_SUCCESS";
export const CATEGORY_DETAILS_FAIL = "CATEGORY_DETAILS_FAIL";

export const NEW_PRODUCT_LIST_REQUEST = "NEW_PRODUCT_LIST_REQUEST";
export const NEW_PRODUCT_LIST_SUCCESS = "NEW_PRODUCT_LIST_SUCCESS";
export const NEW_PRODUCT_LIST_FAIL = "NEW_PRODUCT_LIST_FAIL";

export const BANNER_GET_REQUEST = "BANNER_GET_REQUEST";
export const BANNER_GET_SUCCESS = "BANNER_GET_SUCCESS";
export const BANNER_GET_FAIL = "BANNER_GET_FAIL";

export const RECOMMENDATIONS_GET_REQUEST = "RECOMMENDATIONS_GET_REQUEST";
export const RECOMMENDATIONS_GET_SUCCESS = "RECOMMENDATIONS_GET_SUCCESS";
export const RECOMMENDATIONS_GET_FAIL = "RECOMMENDATIONS_GET_FAIL";

export const COLLECTION_DETAILS_REQUEST = "COLLECTION_DETAILS_REQUEST";
export const COLLECTION_DETAILS_SUCCESS = "COLLECTION_DETAILS_SUCCESS";
export const COLLECTION_DETAILS_FAIL = "COLLECTION_DETAILS_FAIL";

export const COLLECTION_LIST_REQUEST = "COLLECTION_LIST_REQUEST";
export const COLLECTION_LIST_SUCCESS = "COLLECTION_LIST_SUCCESS";
export const COLLECTION_LIST_FAIL = "COLLECTION_LIST_FAIL";

export const PRODUCT_RECOMMENDATIONS_REQUEST =
  "PRODUCT_RECOMMENDATIONS_REQUEST";
export const PRODUCT_RECOMMENDATIONS_SUCCESS =
  "PRODUCT_RECOMMENDATIONS_SUCCESS";
export const PRODUCT_RECOMMENDATIONS_FAIL = "PRODUCT_RECOMMENDATIONS_FAIL";

export const PREV_PRODUCTS_REQUEST = "PREV_PRODUCTS_REQUEST";
export const PREV_PRODUCTS_SUCCESS = "PREV_PRODUCTS_SUCCESS";
export const PREV_PRODUCTS_FAIL = "PREV_PRODUCTS_FAIL";

export const COLLECTION_MADE_TYPE_REQUEST = "COLLECTION_MADE_TYPE_REQUEST";
export const COLLECTION_MADE_TYPE_SUCCESS = "COLLECTION_MADE_TYPE_SUCCESS";
export const COLLECTION_MADE_TYPE_FAIL = "COLLECTION_MADE_TYPE_FAIL";

export const COLLECTION_ITEMS_BY_TYPE_REQUEST =
  "COLLECTION_ITEMS_BY_TYPE_REQUEST";
export const COLLECTION_ITEMS_BY_TYPE_SUCCESS =
  "COLLECTION_ITEMS_BY_TYPE_SUCCESS";
export const COLLECTION_ITEMS_BY_TYPE_FAIL = "COLLECTION_ITEMS_BY_TYPE_FAIL";

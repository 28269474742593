import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Components/Loader";
import ProductsDropdown from "../Components/ProductsDropdown";
import FormContainer from "../Components/FormContainer";
import { getBatchList } from "../Actions/AdminActions";
import ErrorHandle from "../Components/ErrorHandle";

function Sandboxscreen({ history }) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState();

  const [optionList, setOptionList] = useState();

  const listBatches = useSelector((state) => state.listBatches);
  const { loading, error, BatchList } = listBatches;

  console.log(selectedOption);

  useEffect(() => {
    dispatch(getBatchList());
  }, [dispatch]);

  useEffect(() => {
    if (
      Boolean(BatchList && Object.keys(BatchList).length > 0) &&
      Boolean(BatchList.approvedSellers && BatchList.approvedSellers.length > 0)
    ) {
      setOptionList(
        BatchList.approvedSellers.map((seller) => {
          return {
            value: seller.id,
            label: `${seller.id} -${seller.storeName}`,
            image: seller.profileImage,
          };
        })
      );
    }
  }, [BatchList]);

  /*
    const optionList = [
        {"value":"Make me wanna die","label":"Make me wanna die","image":"https://myjaxy-prod.s3.amazonaws.com/1664214232KajalAmla_Murabcart.jpg"},
        {"value":"A reason for me?","label":"A reason for me?","image":"https://myjaxy-prod.s3.amazonaws.com/1669301260GeethRaggi_Battcart.jpg"},
        {"value":"One last breath","label":"One last breath","image":"https://myjaxy-prod.s3.amazonaws.com/1662129285ABC2_minute_Ucart.jpg"},
        {"value":"I will survive","label":"I will survive","image":"https://myjaxy-prod.s3.amazonaws.com/1665853784KajalSankarparacart.jpg"},
        {"value":"In the end","label":"In the end","image":"https://myjaxy-prod.s3.amazonaws.com/1665853784KajalSankarparacart.jpg"},
        {"value":"It doesnt even matter","label":"It doesnt even matter","image":"https://myjaxy-prod.s3.amazonaws.com/1665853784KajalSankarparacart.jpg"},
        {"value":"Bring me to life","label":"Bring me to life","image":"https://myjaxy-prod.s3.amazonaws.com/1665853784KajalSankarparacart.jpg"},
        {"value":"Crawling in my skin","label":"Crawling in my skin","image":"https://myjaxy-prod.s3.amazonaws.com/1665853784KajalSankarparacart.jpg"}]
      */

  const handleComplete = () => {
    console.log(selectedOption);
  };
  return loading ? (
    <Loader />
  ) : error ? (
    <ErrorHandle error={error} history={history} />
  ) : Boolean(optionList && optionList.length > 0) ? (
    <FormContainer>
      <ProductsDropdown
        optionList={optionList}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      {Boolean(selectedOption && selectedOption.length > 0) &&
        selectedOption.map((option) => (
          <p key={option.value}>
            {option.value} {option.label}
          </p>
        ))}
    </FormContainer>
  ) : null;
}

export default Sandboxscreen;

// {Boolean(selectedOption && selectedOption.length>0)&& selectedOption.map(option=><p key={option}>{option}</p>)}

import React from "react";
import { Col, Placeholder, Row } from "react-bootstrap";

const ProductListSkeleton = () => {
  return (
    <Row>
      {[...Array(6)].map((_, index) => (
        <Col key={`placeholder-${index}`} xs={12} md={6} lg={6} xl={4}>
          <div className="p-2" style={{ height: 320 }}>
            <Placeholder
              className="d-flef flex-column gap-2"
              as="div"
              animation="glow"
            >
              <Placeholder
                as="image"
                xs={12}
                style={{ height: 220, borderRadius: 16 }}
              />
              <Placeholder
                as="p"
                className="my-4"
                xs={12}
                style={{ height: 20, borderRadius: 16 }}
              />
            </Placeholder>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ProductListSkeleton;

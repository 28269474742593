import React from "react";
import { ListGroup } from "react-bootstrap";

const ProductDescription = ({ product }) => {
  if (!product || !product?.id) {
    return null;
  }
  const hasHeading =
    product?.description?.startsWith("<h4>Product Description:") ||
    product?.description?.startsWith("<h5>Product Description:");
  return (
    <>
      {product?.usageInstructions && (
        <ListGroup.Item style={{ borderRadius: 8 }}>
          <strong style={{ fontSize: "1.1rem" }} className="text-heading-bold">
            Usage Instructions:
          </strong>{" "}
          <div
            dangerouslySetInnerHTML={{ __html: product.usageInstructions }}
          ></div>
        </ListGroup.Item>
      )}
      {product?.description && (
        <ListGroup.Item style={{ borderRadius: 8 }}>
          {!hasHeading && (
            <strong
              style={{ fontSize: "1.1rem" }}
              className="text-heading-bold"
            >
              Product Description:
            </strong>
          )}
          <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
        </ListGroup.Item>
      )}
      {product?.allergenInfo && (
        <ListGroup.Item style={{ borderRadius: 8 }}>
          <strong style={{ fontSize: "1.1rem" }} className="text-heading-bold">
            Allergen Info:
          </strong>{" "}
          <div dangerouslySetInnerHTML={{ __html: product.allergenInfo }}></div>
        </ListGroup.Item>
      )}
    </>
  );
};

export default ProductDescription;

import MobileFooter from "../Components/MobileFooter";

function ShippingPolicycreen() {
  return (
    <>
      <h1>Shipping, Refunds and Cancellation Policy</h1>
      <h5>Cancellations</h5>
      <p>
        Cancellations are allowed for 1 hours after the order has been placed.
        After 1 hour of order placing if the order has been processed,
        cancellations are not allowed.
      </p>
      <h5>Returns</h5>
      <p>
        Returns will be accepted for bad quality items within 24 hours of
        delivery in original packaging only.
      </p>
      <h5>Refund</h5>
      <p>
        Once the poor quality product is receieved Jaxy Technologies Pvt. Ltd.
        will initiate a refund to your source account.
      </p>

      <h5>Shipping and Delivery Policy</h5>
      <p>
        Jaxy Technologies Pvt. Ltd. provides next day delivery only in certain
        areas of Bangalore. All products will be shipped and delivered the next
        day. We ship on all days.{" "}
      </p>
      <p>
        At times there might be unexpected delays in delivery of your order due
        to unavoidable and undeterministic challenges beyond our control for
        which Jaxy Technologies Pvt. Ltd. is not liable and would request its
        users to cooperate with Jaxy Technologies Pvt. Ltd. as we tried to
        nought such instances. Also, Jaxy Technologies Pvt. Ltd. reserves the
        right to cancel your order at its sole discretion in cases where it
        takes longer than usual delivery time or the shipment is physically
        untraceable and refund the amount paid for cancelled product(s) to your
        source account.
      </p>
      <MobileFooter />
    </>
  );
}

export default ShippingPolicycreen;

import React from "react";
import { components } from "react-select";
import { checkToday } from "../../Utils/DateTimeFunctions";

const OptionValue = ({ children, ...props }) => {
  const selectedOption = props.getValue()[0];
  const dayLabel =
    selectedOption?.value?.type !== 100
      ? checkToday(selectedOption?.value?.date)
      : "";

  return (
    <components.SingleValue {...props}>
      {`${dayLabel} ${children}`}
    </components.SingleValue>
  );
};

export default OptionValue;

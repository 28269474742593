import React from "react";
import FormContainer from "../FormContainer";
import IssueTicket from "./IssueTicket";
import Button from "react-bootstrap/Button";
import { getIssueType } from "./utils";

const TicketContainer = (props) => {
  const {
    ticketNumber,
    selectIssue,
    selectResponse,
    selectItem,
    handleReset,
    phone,
  } = props;

  const whatsappSendHandler = () => {
    let message = `I have raised issue - ${selectIssue} with my order, my ticket number is ${ticketNumber}`;
    window.open(
      `https://wa.me/+918904293636?text=${message.replace(/ /g, "%20")}`
    );
  };

  const selfWhatsappSendHandler = () => {
    if (!phone) {
      return;
    }
    let message = `My ticket number for the issue - ${selectIssue} is ${ticketNumber}`;
    window.open(
      `https://wa.me/+91${phone}?text=${message.replace(/ /g, "%20")}`
    );
  };

  const issueType = getIssueType(selectIssue);

  const handlingType = issueType === "PRODUCT" ? "SAVE" : "SEND";

  return (
    <FormContainer>
      <h3 style={{ marginTop: 20 }}>Issue has been reported </h3>
      <h6>
        Your ticket id is {ticketNumber}. We will contact you soon with a
        resolution.
      </h6>
      <IssueTicket
        selectItem={selectItem}
        selectResponse={selectResponse}
        selectIssue={selectIssue}
      />
      {handlingType === "SEND" && (
        <Button variant="secondary" onClick={whatsappSendHandler}>
          <i className="fab fa-whatsapp" style={{ fontSize: "1.4rem" }}></i>{" "}
          Send on Whatsapp
        </Button>
      )}
      {handlingType === "SAVE" && (
        <Button variant="secondary" onClick={selfWhatsappSendHandler}>
          Save your ticket
        </Button>
      )}
      {"  "}
      <Button variant="info" onClick={handleReset} style={{ marginLeft: 10 }}>
        Back to Home
      </Button>
    </FormContainer>
  );
};

export default TicketContainer;

import axios from "axios";
import {
  SELLER_DETAILS_REQUEST,
  SELLER_DETAILS_SUCCESS,
  SELLER_DETAILS_FAIL,
} from "../Constants/SellerConstants";

export const getSellerDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SELLER_DETAILS_REQUEST });

    const { data } = await axios.get(`api/sellers/${id}/`);
    dispatch({ type: SELLER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SELLER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

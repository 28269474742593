import MobileFooter from "../Components/MobileFooter";

function Aboutscreen() {
  return (
    <>
      <h1>About us</h1>

      <p>
        Homble is an entity of JAXY TECHNOLOGIES PRIVATE LIMITED, an FSSAI
        registered online marketplace that brings chemical-free, nutrient-rich
        groceries directly to the doorsteps of Indian families. We are committed
        to empowering consumers with better food choices, promoting sustainable
        practices, and supporting local producers.
      </p>

      <h5>Our vision</h5>
      <p>
        <strong>Homble- Nourishing India, Naturally:</strong> Homble envisions a
        future where every Indian household has access to chemical-free,
        nutritious groceries. Our goal is to lead India's transition from
        achieving food security to ensuring nutrition security, fostering a
        healthier, more sustainable lifestyle for generations to come. By
        integrating traditional wisdom with modern convenience, we aim to be at
        the forefront of a food revolution, where quality, purity, and health
        are not just ideals, but everyday realities.
      </p>

      <h5>Our mission</h5>
      <p>
        Our mission is to provide a trusted online marketplace that brings
        chemical-free, nutrient-rich groceries directly to the doorsteps of
        Indian families. We are committed to empowering consumers with healthy
        food choices, promoting sustainable practices, and supporting local
        producers. By meticulously selecting and offering only the purest
        ingredients and real foods, we strive to elevate India's nutritional
        standards, contributing to the well-being of our customers and the
        planet. Through education, community engagement, and relentless pursuit
        of quality, Homble endeavors to make nutritious eating accessible,
        affordable, and enjoyable for all in India.
      </p>

      <h5>Founders</h5>
      <p>
        We are two passionate food-focussed entrepreneurs launching our second
        venture together. Each of us has 10+ years of work experience across
        roles in start-ups, consulting and engineering, in companies such as
        Swiggy, McKinsey and Bosch. We are graduates of IIT-Madras and
        IIM-B/ISB.
      </p>

      <p>FSSAI Registration No. - 21222010000706</p>
      <MobileFooter />
    </>
  );
}

export default Aboutscreen;

import React from "react";
import CollectionTile from "./CollectionTile";
import { Row } from "react-bootstrap";

const CollectionScroll = ({
  collections,
  title,
  backgroundColor,
  loading = false,
}) => {
  if (!loading && (!collections || collections?.length < 1)) {
    return null;
  }
  return (
    <div
      style={{
        background: backgroundColor,
        padding: 20,
        paddingTop: 10,
        paddingBottom: 15,
      }}
    >
      <h3
        style={{
          color: "#fffdd0",
          fontWeight: 600,
          padding: 0,
          margin: 0,
          fontSize: "1.2rem",
          letterSpacing: 1,
        }}
      >
        {title}
      </h3>
      <Row
        className="scroll-container"
        style={{
          display: "grid",
          gap: 30,
          gridAutoColumns: "minmax(1fr,auto)",
          gridAutoFlow: "column",
          overflowX: "scroll",
          marginTop: 20,
          translate: "",
        }}
      >
        <div className=" infinite-scroll-content" style={{ gap: 30 }}>
          {collections?.map((item, index) => {
            return <CollectionTile key={item.id} item={item} />;
          })}
        </div>
      </Row>
    </div>
  );
};

export default CollectionScroll;

/* eslint-disable default-case */

export const createAddressFromParts = (address) => {
  if (!address) {
    return "";
  }
  const addressArr = [
    address.doorNum,
    address.buildingDetails,
    address.road,
    address.area,
    address.city,
    address.pincode,
  ];

  const filteredArr = addressArr.filter(
    (address) => address && address.length >= 1
  );

  return filteredArr.join(", ");
};

export const checkIfInsidePolygon = (location, polygonPath) => {
  const googlePolygon = new window.google.maps.Polygon({ paths: polygonPath });
  const isInside = window.google.maps.geometry.poly.containsLocation(
    location,
    googlePolygon
  );

  return isInside;
};

export const containerStyle = {
  width: "350px",
  height: "350px",
};

export const center = {
  lat: 12.901965,
  lng: 77.653048,
};

/**
 *
 * @param {*} address_components
 * @returns {location - {lat,lng}, building name, street name, area name, pincode }
 */

export const fillInAddress = (
  address_components,
  description,
  coordinates,
  types
) => {
  let streetName = "";
  let areaName = "";
  let buildingName = "";
  let postcode = "";
  let isPremise = false;

  if (!address_components || address_components.length < 1) {
    return {
      buildingName,
      streetName,
      areaName,
      postcode,
      description,
      coordinates,
      isPremise,
    };
  }
  //can add "establishment" also
  if (types && types.includes("premise")) {
    isPremise = true;
  }

  for (const component of address_components) {
    const componentType = getComponentType(component.types);
    switch (componentType) {
      case "premise":
      case "establishment":
        buildingName = component.long_name;
        break;

      case "street_number":
        streetName = `${component.long_name} ${streetName}`;
        break;

      case "route":
        streetName += component.short_name;
        break;

      case "sublocality_level_1":
      case "sublocality_level_2":
      case "sublocality_level_3":
        areaName = `${areaName} ${component.long_name}`.trim();
        break;

      case "postal_code":
        postcode = `${component.long_name}${postcode}`;
        break;

      case "postal_code_suffix":
        postcode = postcode
          ? `${postcode}-${component.long_name}`
          : component.long_name;
        break;
    }
  }

  const splitDescription = description.split(",");
  if (!buildingName) {
    buildingName = splitDescription[0];
  }

  return {
    buildingName,
    streetName,
    areaName,
    postcode,
    description,
    coordinates,
    isPremise,
  };
};

const getComponentType = (types) => {
  if (types.includes("premise")) return "premise";
  if (types.includes("establishment")) return "establishment";
  if (types.includes("street_number")) return "street_number";
  if (types.includes("route")) return "route";
  if (types.includes("sublocality_level_3")) return "sublocality_level_3";
  if (types.includes("sublocality_level_2")) return "sublocality_level_2";
  if (types.includes("sublocality_level_1")) return "sublocality_level_1";
  if (types.includes("postal_code")) return "postal_code";
  if (types.includes("postal_code_suffix")) return "postal_code_suffix";
  return "";
};

export function extractAddressParts(addressObj) {
  const displayAddress = addressObj?.description?.split(",");
  const mainAddress = displayAddress?.[0] || "";
  const subAddress = displayAddress?.slice(1).join(", ") || "";
  return { mainAddress, subAddress };
}

export const polygonPath = [
  { lat: 12.9740323, lng: 77.6311174 },
  { lat: 12.9790086, lng: 77.6407304 },
  { lat: 12.969223, lng: 77.6418891 },
  { lat: 12.9596462, lng: 77.6434334 },
  { lat: 12.9434193, lng: 77.6401708 },
  { lat: 12.9386021, lng: 77.6354948 },
  { lat: 12.9350333, lng: 77.6479115 },
  { lat: 12.9307871, lng: 77.6600538 },
  { lat: 12.9388596, lng: 77.6697983 },
  { lat: 12.9483012, lng: 77.6778637 },
  { lat: 12.9497351, lng: 77.6886903 },
  { lat: 12.9486775, lng: 77.6992408 },
  { lat: 12.9480191, lng: 77.7042878 },
  { lat: 12.9415672, lng: 77.7057854 },
  { lat: 12.937019, lng: 77.708236 },
  { lat: 12.9341327, lng: 77.7112248 },
  { lat: 12.9298244, lng: 77.7152312 },
  { lat: 12.9255163, lng: 77.7160252 },
  { lat: 12.9246795, lng: 77.7166048 },
  { lat: 12.9212595, lng: 77.7150873 },
  { lat: 12.916021, lng: 77.712485 },
  { lat: 12.9133956, lng: 77.7118685 },
  { lat: 12.9105511, lng: 77.7109244 },
  { lat: 12.9057727, lng: 77.7090092 },
  { lat: 12.9016829, lng: 77.7088216 },
  { lat: 12.8930562, lng: 77.7089226 },
  { lat: 12.8830159, lng: 77.7109825 },
  { lat: 12.8783306, lng: 77.7089225 },
  { lat: 12.8746486, lng: 77.7050876 },
  { lat: 12.872306, lng: 77.7030859 },
  { lat: 12.8661141, lng: 77.7018846 },
  { lat: 12.8592527, lng: 77.7037727 },
  { lat: 12.8552798, lng: 77.6998873 },
  { lat: 12.8515543, lng: 77.6955331 },
  { lat: 12.8472026, lng: 77.6929581 },
  { lat: 12.8385416, lng: 77.7006399 },
  { lat: 12.8289597, lng: 77.7120126 },
  { lat: 12.8323072, lng: 77.6935161 },
  { lat: 12.8396803, lng: 77.6790383 },
  { lat: 12.8368262, lng: 77.6735606 },
  { lat: 12.8324833, lng: 77.6717427 },
  { lat: 12.826639, lng: 77.6698573 },
  { lat: 12.8238634, lng: 77.6686957 },
  { lat: 12.8196491, lng: 77.6674987 },
  { lat: 12.8195026, lng: 77.6626876 },
  { lat: 12.8211364, lng: 77.6538391 },
  { lat: 12.8254882, lng: 77.6456852 },
  { lat: 12.8297964, lng: 77.6392987 },
  { lat: 12.8321398, lng: 77.6323622 },
  { lat: 12.8366588, lng: 77.6265958 },
  { lat: 12.8398388, lng: 77.6220623 },
  { lat: 12.8448598, lng: 77.6170686 },
  { lat: 12.8493789, lng: 77.6122778 },
  { lat: 12.8482071, lng: 77.6035231 },
  { lat: 12.8505501, lng: 77.59391 },
  { lat: 12.8533708, lng: 77.5871137 },
  { lat: 12.8528687, lng: 77.5812772 },
  { lat: 12.8539566, lng: 77.5753549 },
  { lat: 12.8556145, lng: 77.569768 },
  { lat: 12.8617805, lng: 77.5634244 },
  { lat: 12.8733937, lng: 77.566616 },
  { lat: 12.8796063, lng: 77.5673884 },
  { lat: 12.8833265, lng: 77.5650552 },
  { lat: 12.885777, lng: 77.5620719 },
  { lat: 12.8906298, lng: 77.565157 },
  { lat: 12.8957512, lng: 77.5699905 },
  { lat: 12.9003109, lng: 77.5725225 },
  { lat: 12.9036836, lng: 77.5725575 },
  { lat: 12.9084085, lng: 77.5730153 },
  { lat: 12.9135536, lng: 77.5727956 },
  { lat: 12.9167682, lng: 77.5752531 },
  { lat: 12.9210765, lng: 77.5781285 },
  { lat: 12.9260191, lng: 77.5821511 },
  { lat: 12.9264723, lng: 77.5893293 },
  { lat: 12.926856, lng: 77.596485 },
  { lat: 12.9309061, lng: 77.5987708 },
  { lat: 12.9354722, lng: 77.6018064 },
  { lat: 12.9361761, lng: 77.6060664 },
  { lat: 12.9353396, lng: 77.6096712 },
  { lat: 12.9349214, lng: 77.6137911 },
  { lat: 12.9366782, lng: 77.6161084 },
  { lat: 12.9395223, lng: 77.6191984 },
  { lat: 12.9470508, lng: 77.6174818 },
  { lat: 12.9546627, lng: 77.6159368 },
  { lat: 12.9611871, lng: 77.6191983 },
  { lat: 12.9697185, lng: 77.6232324 },
];

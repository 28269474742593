import { Row, Col, Image, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { removeFromCart } from "../Actions/CartActions";
import PortionSize from "./PortionSize";

function DelOptionsCart({ item }) {
  const dispatch = useDispatch();

  const removeItemFromCart = () => {
    dispatch(removeFromCart(item.id));
  };
  return (
    <>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={2} md={2} className="p-0 m-0">
          <Image src={item.cartImage} alt={item.name} fluid thumbnail />
        </Col>
        <Col xs={6} md={5}>
          <h6 className="text-heading-secondary"> {item.name}</h6>
          <p className="p-0 m-0">
            {<PortionSize packageSize={item.packageSize} />}{" "}
            {item.measurementUnit}{" "}
            {item.quantityPossible > 1 ? `x ${item.quantityPossible}` : ""}
          </p>
        </Col>
        <Col xs={3} md={3} className="m-0 py-1">
          <p className="align-middle">
            {" "}
            &#8377; {item.price * item.quantityPossible}
          </p>
        </Col>
        <Col xs={1} md={2} className="py-1">
          <Button
            className="my-auto Trash"
            style={{ float: "right" }}
            size="sm"
            variant="outline-warning"
            onClick={() => removeItemFromCart()}
          >
            <i className="fa fa-trash "></i>
          </Button>
        </Col>
      </Row>
      {Boolean(item.changeMessage && item.changeMessage.length > 0) && (
        <i>{item.changeMessage}</i>
      )}
    </>
  );
}

export default DelOptionsCart;

import React from "react";
import { ListGroup, Button } from "react-bootstrap";
import RatingNew from "../RatingNew";
import { CUT_OFF_RATING } from "../../Utils/reviewsHelpers";

const ProductReviews = ({ product, handleReview, scrollToReviews }) => {
  if (!product || !product?.id) {
    return null;
  }
  const { numReviews, othersReviews, rating, userOrderItems } = product;

  const seeReviews = numReviews > 0 && rating > CUT_OFF_RATING;

  const canReview =
    userOrderItems && userOrderItems.find((item) => item.canReview);

  return (
    <>
      {seeReviews && (
        <ListGroup.Item className="d-flex justify-content-between">
          <RatingNew value={rating} text={`${numReviews} reviews`} />

          {Boolean(othersReviews && othersReviews.length !== 0) && (
            <Button size="sm" onClick={scrollToReviews} variant="secondary">
              See Reviews
            </Button>
          )}
        </ListGroup.Item>
      )}
      {canReview ? (
        <ListGroup.Item>
          <Button
            className="btn-sm"
            variant="info"
            onClick={(e) =>
              handleReview(
                userOrderItems.find((item) => item.canReview).id,
                userOrderItems.find((item) => item.canReview).name
              )
            }
          >
            Write Review
          </Button>
        </ListGroup.Item>
      ) : null}
    </>
  );
};

export default ProductReviews;

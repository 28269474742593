import React from "react";
import Form from "react-bootstrap/Form";
import { createResponseArr, getIfProductActive, getItemAmount } from "./utils";

const ResponseSelect = (props) => {
  const { selectItem, setSelectResponse, isProductIssue } = props;
  const responseLabel =
    "Apologies for the inconvenience. Our team will review this issue and get back to you with a resolution. Kindly select your preferred resolution";

  let isActiveProduct = getIfProductActive(selectItem);
  const amount = getItemAmount(selectItem);
  const responseArr = createResponseArr(
    isActiveProduct,
    amount,
    selectItem?.sellerStoreName
  );

  const handleResponseSelect = (e) => {
    const selectedResponse = JSON.parse(e.target.value);
    setSelectResponse(selectedResponse);
  };

  if (!isProductIssue || !selectItem) {
    return null;
  }

  return (
    <Form.Group className="mb-3" controlId="response">
      <Form.Label>{responseLabel}</Form.Label>
      <div>
        {responseArr.map((issue) => (
          <Form.Check
            key={issue.type}
            type="radio"
            name="response"
            value={JSON.stringify(issue)}
            label={issue.value}
            onChange={handleResponseSelect}
            required
          />
        ))}
      </div>
    </Form.Group>
  );
};

export default ResponseSelect;

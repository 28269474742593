import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Badge, Image } from "react-bootstrap";
import Rating from "../Components/Rating";
import FlashMessage from "../Components/FlashMessage";
import Product from "../Components/ProductCard/Product";
import Loader from "../Components/Loader";
import Message from "../Components/Message";
import { getSellerDetails } from "../Actions/SellerActions";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import usePageTracking from "../CustomHooks/usePageTracking";

function Sellerscreen({ match }) {
  const [showCart, setShowCart] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [addOrRemove, setAddorRemove] = useState("added to");
  const dispatch = useDispatch();

  const sellerId = match.params.id;

  const sellerDetails = useSelector((state) => state.sellerDetails);
  const { seller, loading, error } = sellerDetails;

  useEffect(() => {
    dispatch(getSellerDetails(sellerId));
  }, [dispatch, sellerId]);

  useDocumentTitle(`Homble | ${seller?.storeName}`);
  usePageTracking("Seller Screen", { storeName: seller?.storeName });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message variant="danger">{error}</Message>;
  }

  return (
    <>
      <Row>
        <Col xs={3}>
          <Image
            src={seller.profileImage}
            width="100px"
            alt={seller.storeName}
            className="rounded-3 border border-1"
          />
        </Col>
        <Col xs={9} style={{ paddingLeft: 20 }}>
          <h4 className="text-heading-secondary">{seller.storeName}</h4>
          <Rating
            value={seller.rating}
            text={`(${seller.numReviews} ratings)`}
            color="#f8e825"
          />
          <Badge pill bg="info">
            {seller.categoryTag}
          </Badge>{" "}
          <Badge pill bg="success">
            {seller.cuisineTag}
          </Badge>
        </Col>
        <Col>
          <p className="mt-2 description-text">{seller.aboutSeller}</p>
        </Col>
      </Row>
      <FlashMessage
        show={showToast}
        message={"Sorry! No more stock"}
        setShow={setShowToast}
        background={"primary"}
        position="bottom-center"
      />
      <Row>
        {seller.products ? (
          <>
            <h3>{seller.storeName} Menu</h3>
            {seller.products.map((product) => (
              <Col key={product.id} xs={12} sm={8} md={6} lg={6} xl={4}>
                <Product
                  product={product}
                  cartQty={1}
                  setShowCart={setShowCart}
                  setShowToast={setShowToast}
                  setAddorRemove={setAddorRemove}
                />
              </Col>
            ))}
          </>
        ) : null}
      </Row>
    </>
  );
}

export default Sellerscreen;

export const recEvents = {
  CLICKED_REC_HOME: "CLICKED_REC_HOME",
  CLICKED_REC_COLLECTION: "CLICKED_REC_COLLECTION",
  CLICKED_REC_CATEGORY: "CLICKED_REC_CATEGORY",
  CLICKED_REC_PRODUCT: "CLICKED_REC_PRODUCT",
  CLICKED_REC: "CLICKED_REC",
  CLICKED_REC_OTHER: "CLICKED_REC_OTHER",
};

export const homeEvents = {
  CLICKED_COLLECTION_TILE: "CLICKED_COLLECTION_TILE",
  CLICKED_CATEGORY_TILE: "CLICKED_CATEGORY_TILE",
  CLICKED_BANNER: "CLICKED_BANNER",
  CLICKED_PREV_ITEM: "CLICKED_PREV_ITEM",
};

export const searchEvent = {
  SEARCH_SUBMITTED: "SEARCH_SUBMITTED",
};

import React from "react";
import { useHistory } from "react-router-dom";

const EmptySearch = () => {
  return (
    <div>
      <div>
        <p style={{ fontWeight: 600 }} className="p-0 m-0">
          Trending Searches
        </p>
      </div>
      <div className="d-flex gap-2 mt-2">
        <KeywordComponent name="Fruits" />
        <KeywordComponent name="Chips" />
        <KeywordComponent name="Dairy" />
        <KeywordComponent name="Chutney" />
      </div>
    </div>
  );
};

const KeywordComponent = ({ name }) => {
  const history = useHistory();
  const keywordPressHandler = () => {
    history.push(`/search?keyword=${name.replace(/ /g, "%20")}`);
  };
  return (
    <div
      onClick={keywordPressHandler}
      className="border"
      style={{
        display: "inline-block",
        width: "auto",
        padding: 10,
        borderRadius: 10,
        boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
      }}
    >
      <p
        style={{ fontSize: "0.9rem" }}
        className="p-0 m-0 mt-1 text-body-light"
      >
        {name}
      </p>
    </div>
  );
};

export default EmptySearch;

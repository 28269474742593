export const OTP_ISSUES_WRITE_REQUEST = "OTP_ISSUES_WRITE_REQUEST";
export const OTP_ISSUES_WRITE_SUCCESS = "OTP_ISSUES_WRITE_SUCCESS";
export const OTP_ISSUES_WRITE_FAIL = "OTP_ISSUES_WRITE_FAIL";

export const ONBOARDING_WRITE_REQUEST = "ONBOARDING_WRITE_REQUEST";
export const ONBOARDING_WRITE_SUCCESS = "ONBOARDING_WRITE_SUCCESS";
export const ONBOARDING_WRITE_FAIL = "ONBOARDING_WRITE_FAIL";
export const ONBOARDING_WRITE_RESET = "ONBOARDING_WRITE_RESET";

export const ISSUES_WRITE_REQUEST = "ISSUES_WRITE_REQUEST";
export const ISSUES_WRITE_SUCCESS = "ISSUES_WRITE_SUCCESS";
export const ISSUES_WRITE_FAIL = "ISSUES_WRITE_FAIL";
export const ISSUES_WRITE_RESET = "ISSUES_WRITE_RESET";

import React from "react";
import Compressor from "compressorjs";

const ImageUpload = (props) => {
  const { setSelectedFiles, imageUploadRef, multiple = false } = props;

  const fileChangeHandler = (event) => {
    const files = Array.from(event.target.files);
    if (files.length) {
      const compressedFiles = [];
      files.forEach((file, index) => {
        new Compressor(file, {
          quality: 0.6, // Adjust quality
          success: (compressedFile) => {
            compressedFiles.push(compressedFile);
            if (compressedFiles.length === files.length) {
              setSelectedFiles(multiple ? compressedFiles : compressedFile);
            }
          },
          error: (err) => {
            console.error("Compression error:", err);
          },
        });
      });
    }
  };
  return (
    <div>
      <input
        ref={imageUploadRef}
        type="file"
        onChange={fileChangeHandler}
        accept="image/*"
        multiple={multiple}
      />
    </div>
  );
};

export default ImageUpload;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getCategoryDetails } from "../Actions/ProductActions";
import { useDispatch, useSelector } from "react-redux";
import FlashMessage from "../Components/FlashMessage";
import ErrorHandle from "../Components/ErrorHandle";
import Loader from "../Components/Loader";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import usePageTracking from "../CustomHooks/usePageTracking";
import CategoryProductList from "../Components/CategoryScreen/CategoryProductList";
import SubCategoryList from "../Components/CategoryScreen/SubCategoryList";
import BackButton from "../Components/Common/Buttons/BackButton";

function CategoryScreenNew({ history, match }) {
  const categoryId = match.params.id;
  const subcategoryId = match.params.sid;
  const categoryName = match.params.name;
  const dispatch = useDispatch();

  const {
    categoryData,
    loading: loadingData,
    error: errorData,
  } = useSelector((state) => state.categoryDetails);

  const [showToast, setShowToast] = useState(false);

  const handleHome = () => {
    history.push("/");
  };

  useDocumentTitle(`Homble | ${categoryName}`);
  usePageTracking("Category Screen");

  useEffect(() => {
    dispatch(getCategoryDetails(categoryId));
  }, [dispatch, categoryId]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  if (loadingData) {
    return <Loader />;
  }

  if (errorData) {
    return <ErrorHandle history={history} error={errorData} />;
  }

  return (
    <div>
      <FlashMessage
        show={showToast}
        message={"Sorry! No more stock"}
        setShow={setShowToast}
        background={"primary"}
        position="bottom-center"
      />
      <>
        <div className="d-flex align-items-center gap-3 my-3">
          <BackButton onClick={handleHome} />
          <h3 className="p-0 m-0" style={{ fontSize: "1.2rem" }}>
            Shop for {categoryName}
          </h3>
        </div>
        <Row className="gap-2 px-2">
          <Col
            xs={2}
            className="scrollable-column"
            style={{ paddingBottom: 120 }}
          >
            <SubCategoryList history={history} categoryData={categoryData} />
          </Col>
          <Col className="scrollable-column" style={{ paddingBottom: 120 }}>
            <CategoryProductList
              categoryId={categoryId}
              subcategoryId={subcategoryId}
              setShowToast={setShowToast}
            />
          </Col>
        </Row>
      </>
    </div>
  );
}

export default CategoryScreenNew;

import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

import Select from "react-select";

function ProductsDropdown({
  optionList,
  selectedOption,
  setSelectedOption,
  message,
}) {
  const handleChange = (options) => {
    setSelectedOption(options);
    console.log(selectedOption);
  };
  return (
    <>
      <h5 className="mb-1 mt-3" style={{ color: "blue" }}>
        {message}
      </h5>
      <div className="d-inline">
        <Select
          isMulti
          isClearable
          isSearchable
          autoFocus
          onChange={handleChange}
          options={optionList}
          formatOptionLabel={(optionList) => (
            <div>
              <img
                style={{ width: "30px" }}
                src={optionList.image}
                alt="product-image"
              />
              <span> {optionList.label}</span>
            </div>
          )}
        />
      </div>
    </>
  );
}

export default ProductsDropdown;

import React from "react";
import { Modal } from "react-bootstrap";
import { colors } from "../../Constants/colors";

const OnDemandConfirmModal = (props) => {
  const { showModal, onSecondaryHandler } = props;

  return (
    <Modal show={showModal} onHide={onSecondaryHandler} centered>
      <Modal.Header
        closeButton
        style={{ background: colors.primary_green.LIGHT }}
      >
        <Modal.Title
          style={{ paddingLeft: 5, color: "black" }}
          className="text-heading-primary"
        >{`Order Confirmed`}</Modal.Title>
      </Modal.Header>
      {
        <Modal.Body>
          <h5 className="">
            Sit back and relax your clean groceries will reach to you in 30 mins
          </h5>
          <img
            src={"/beach.jpg"}
            alt={"relax"}
            style={{ width: "100%", borderRadius: 10, marginTop: 20 }}
          />{" "}
        </Modal.Body>
      }

      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default OnDemandConfirmModal;

// This component helps display portion Size based on the actual no. of decimal points

function PortionSize({ packageSize }) {
  let displayDecimals = 0;

  let numSize = Number(packageSize);
  let intSize = Math.floor(numSize);
  if (numSize !== intSize) {
    displayDecimals = JSON.stringify(numSize).split(".")[1]
      ? JSON.stringify(numSize).split(".")[1].length
      : 0;
  }

  return <span>{numSize.toFixed(displayDecimals)}</span>;
}
export default PortionSize;

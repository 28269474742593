import React from "react";
import { Button, Modal } from "react-bootstrap";

const SlotConfirmModal = (props) => {
  const { showModal, onSecondaryHandler, onPrimaryHandler, slotData } = props;

  const { isMultiSlotOrder = false, morningSlotTime = "6 AM to 8 AM" } =
    slotData;

  const footerButtons = {
    primary: {
      title: "Confirm Selection",
      onClick: onPrimaryHandler,
    },
    secondary: {
      title: "Cancel",
      onClick: onSecondaryHandler,
    },
  };

  const slotTimeString = morningSlotTime;

  return (
    <Modal show={showModal} onHide={onSecondaryHandler} centered>
      <Modal.Header closeButton className="brand-title">
        <Modal.Title
          style={{ paddingLeft: 5 }}
        >{`Note - Morning Delivery Slot`}</Modal.Title>
      </Modal.Header>
      {
        <Modal.Body>
          {false ? (
            <p>
              Part of the order will be delivered between{" "}
              <span style={{ color: "#d9230f", fontWeight: 500 }}>
                {slotTimeString}
              </span>{" "}
            </p>
          ) : (
            <p>
              This order will be delivered between{" "}
              <span style={{ color: "#d9230f", fontWeight: 500 }}>
                {slotTimeString}
              </span>{" "}
            </p>
          )}

          <p>Prepaid orders only</p>
          <p>
            Silent Delivery. No bell. No OTP. Order will be left outside the
            door
          </p>
        </Modal.Body>
      }

      <Modal.Footer>
        <Button variant="secondary" onClick={footerButtons?.secondary?.onClick}>
          {footerButtons?.secondary?.title}
        </Button>
        <Button variant="info" onClick={footerButtons?.primary?.onClick}>
          {footerButtons?.primary?.title}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SlotConfirmModal;

export const CUT_OFF_RATING = 3.7;

export const getStarColor = (value) => {
  if (value >= 4) {
    return "rgba(0, 110, 69, 1)"; // Dark green
  } else if (value > 3.7) {
    return "rgb(0, 128, 0)";
  } else if (value >= 3) {
    return "rgb(234 179 8)";
  } else {
    return "#ccc"; // Grey color for low ratings
  }
};

export const getReviewsToShow = (reviews) => {
  if (!reviews || reviews.length < 1) {
    return [];
  }
  const reviewsWithText = reviews.filter(
    (review) => review?.title || review?.body
  );
  return reviewsWithText;
};

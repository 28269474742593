import { polygon } from "../Constants/polygon";
import {
  checkToday,
  convertToCivilianTime,
  getHHMM,
} from "./DateTimeFunctions";
import { checkIfInsidePolygon } from "./addressHelpers";

export const getOnDemandString = (slot) => {
  if (!slot) {
    return "";
  }
  return `⚡Get it in ${slot?.promisedDeliveryTime} mins`;
};

export const UniqueSlots = (cartItemsList) => {
  // Get slosts information as string so we can remove duplicates
  let delSlots = cartItemsList.map((item) =>
    JSON.stringify(item.earliestDeliverySlot)
  );
  // Remove duplicates
  let delSlotsUnique = [...new Set(delSlots)];
  //Bring back all elements of the array from string to object
  let delSlotsUniqueParsed = delSlotsUnique.map((slot) => JSON.parse(slot));
  //Sort the slots in ascending order
  delSlotsUniqueParsed.sort((a, b) => {
    const startTimeA = a.startDatetime;
    const startTimeB = b.startDatetime;
    if (startTimeA < startTimeB) {
      return -1;
    }
    if (startTimeA > startTimeB) {
      return 1;
    }
    return 0;
  });
  return delSlotsUniqueParsed;
};

export const getStringSlot = (earliestDeliverySlot, myOrders = false) => {
  if (earliestDeliverySlot?.type === 100 && !myOrders) {
    return `Get it in ${earliestDeliverySlot?.promisedDeliveryTime} mins`;
  }
  let startTime = convertToCivilianTime(
    earliestDeliverySlot.startDatetime.split("T")[1].slice(0, 5)
  );
  let endTime = convertToCivilianTime(
    earliestDeliverySlot.endDatetime.split("T")[1].slice(0, 5)
  );
  let day = checkToday(earliestDeliverySlot.date, !myOrders);
  if (myOrders) {
    return `${day}, ${startTime} - ${endTime}`;
  }
  return `${day} ${startTime} - ${endTime}`;
};

const isBefore12PM = (startDatetimeStr) => {
  if (!startDatetimeStr) {
    return false;
  }
  const startDatetime = new Date(startDatetimeStr);
  const hour = startDatetime.getHours();
  return hour < 12;
};

export const getIfMorningSlot = (option) => {
  if (!option || !option.slots) {
    return null;
  }
  const { slots } = option;
  let flag = false;
  let onDemand = false;
  let morningSlotTime = "";
  slots.forEach((item) => {
    const { slot } = item;
    const isMorning = isBefore12PM(slot.startDatetime);
    const isOnDemand = slot.type === 100;
    if (isMorning) {
      flag = true;
      morningSlotTime = `${getHHMM(slot.startDatetime)} to ${getHHMM(
        slot.endDatetime
      )}`;
    }
    if (isOnDemand) {
      onDemand = true;
    }
  });
  return [flag, slots.length > 1, morningSlotTime, onDemand];
};

export function hasItemsInCart(cartItems) {
  return cartItems && cartItems.length > 0;
}

export function getDeliverySlotData(option) {
  const [isMorning, isMulti, morningSlotTime] = getIfMorningSlot(option);
  return { isMorning, isMultiSlotOrder: isMulti, morningSlotTime };
}

export const delTimeBadgeColor = (slotDate, onDemand = false) => {
  if (onDemand) {
    return "info";
  }
  if (checkToday(slotDate) === "Today") {
    return "success";
  } else {
    return "warning";
  }
};

export const getSlotTime = (earliestDeliverySlot) => {
  let startTime = convertToCivilianTime(
    earliestDeliverySlot.startDatetime.split("T")[1].slice(0, 5)
  );
  let endTime = convertToCivilianTime(
    earliestDeliverySlot.endDatetime.split("T")[1].slice(0, 5)
  );

  const startPeriod = startTime.slice(-2); // "AM" or "PM"
  const endPeriod = endTime.slice(-2);

  if (startPeriod === endPeriod) {
    startTime = startTime.slice(0, -3); // Remove "AM" or "PM" from startTime
  }

  return `${startTime} - ${endTime}`;
};

export const checkIfOnDemandSlot = (slotType) => {
  return slotType && slotType === 100;
};

export const getSlotLabel = (slot) => {
  const isOnDemand = checkIfOnDemandSlot(slot?.type);
  const onDemandString = getOnDemandString(slot);
  return isOnDemand ? `${onDemandString}` : getSlotTime(slot);
};

export const convertToSlotOptions = (slots) => {
  const groupedOptions = {};
  slots.forEach((slot) => {
    let dayLabel = checkToday(slot.date);
    const slotLabel = getSlotLabel(slot);
    const option = { value: slot, label: slotLabel };
    if (slot?.type === 100) {
      dayLabel = "Now";
    }

    if (!groupedOptions[dayLabel]) {
      groupedOptions[dayLabel] = [];
    }
    groupedOptions[dayLabel].push(option);
  });

  return Object.keys(groupedOptions).map((dayLabel) => ({
    label: dayLabel,
    options: groupedOptions[dayLabel],
  }));
};

export const getSlot = (earliestDeliverySlot) => {
  let startTime = convertToCivilianTime(
    earliestDeliverySlot.startDatetime.split("T")[1].slice(0, 5)
  );
  let endTime = convertToCivilianTime(
    earliestDeliverySlot.endDatetime.split("T")[1].slice(0, 5)
  );
  let day = checkToday(earliestDeliverySlot.date);
  return `${day} ${startTime} - ${endTime}`;
};

const getPolygonType = (polygonTypeInt) => {
  switch (polygonTypeInt) {
    case 0:
      return null;
    case 100:
      return "sm";
    case 200:
      return "md";
    case 300:
      return "bg";
    default:
      return null;
  }
};

export const filterSlotsByAddress = (options, selectedAddress = "") => {
  if (!options || options.length < 1) {
    return [];
  }
  const polygonTypeInt = options
    ?.flatMap((option) =>
      option.deliveries.flatMap((delivery) => delivery.slots)
    )
    ?.find((slot) => slot.type === 100)?.serviceablePolygon;
  const polygonType = getPolygonType(polygonTypeInt);
  const isOnDemandAddress =
    polygonType &&
    checkIfInsidePolygon(
      new window.google.maps.LatLng(
        selectedAddress?.latitude,
        selectedAddress?.longitude
      ),
      polygon[polygonType]
    );
  if (isOnDemandAddress) {
    return options;
  }
  return options.map((option) => ({
    ...option,
    deliveries: option.deliveries.map((delivery) => ({
      ...delivery,
      slots: delivery.slots.filter((slot) => slot.type !== 100),
    })),
  }));
};

export const getDelFee = (slotOptions) => {
  if (!slotOptions || !slotOptions?.slots) {
    return null;
  }
  const { slots } = slotOptions;
  const onDemandSlot = slots.find((slot) => slot?.slot?.type === 100);
  if (!onDemandSlot) {
    return 0;
  }
  const cartValue = onDemandSlot?.products?.reduce(
    (agg, item) => (agg += (item?.quantityPossible || 0) * (item?.price || 0)),
    0
  );
  const freeDelAmount = onDemandSlot?.slot?.freeDeliveryAmount;
  const delFee = onDemandSlot?.slot?.deliveryFee;
  const diffAmount = freeDelAmount - cartValue;
  return diffAmount > 0 ? delFee : 0;
};

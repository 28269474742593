import { DEL_TIME } from "../Constants/GlobalConstants";

export const getDateFromStamp = (stamp) => {
  if (!stamp) {
    return null;
  }
  let stringDate = stamp.split("T")[0];
  const MONTH_LIST = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let day = stringDate.split("-")[2];
  let year = stringDate.split("-")[0];
  let month = MONTH_LIST[Number(stringDate.split("-")[1]) - 1];
  return day + " " + month + " " + year;
};

export const getDelTime = () => {
  let today = new Date();
  let timeNow = today.getHours();
  let del_string;
  if (timeNow < 16) {
    del_string = "today";
  } else {
    del_string = "tomorrow";
  }
  return " " + del_string + DEL_TIME;
};

export const checkToday = (delDate, showYear) => {
  if (!delDate) return "";

  const today = new Date();
  const currentYear = today.getFullYear();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const dayAfter = new Date(today);
  dayAfter.setDate(today.getDate() + 2);

  const [year, month, day] = delDate.split("-").map(Number);

  const dateFromDelDate = new Date(year, month - 1, day);
  const formatDate = (date) => {
    let options = { month: "short", day: "numeric" };
    if (showYear || date.getFullYear() !== currentYear) {
      options.year = "numeric"; // Add year to the format if showYear is true or the year is not the current year
    }
    return date.toLocaleDateString("en-GB", options);
  };

  const todayStr = formatDate(today);
  const tomorrowStr = formatDate(tomorrow);
  const dayAfterStr = formatDate(dayAfter);
  const delDateStr = formatDate(dateFromDelDate);

  if (delDateStr === todayStr) {
    return "Today";
  } else if (delDateStr === tomorrowStr) {
    return "Tomorrow";
  } else if (delDateStr === dayAfterStr) {
    return "Day After";
  } else {
    return delDateStr;
  }
};

export const convertToCivilianTime = (militaryTime) => {
  let militaryHH = Number(militaryTime.split(":")[0]);
  let civilHH = militaryHH - 12 > 0 ? militaryHH - 12 : militaryHH;
  let mm = militaryTime.split(":")[1];
  let ampm = militaryHH >= 12 ? "PM" : "AM";
  return `${civilHH}:${mm} ${ampm}`;
};

export const convert12to24 = (string12) => {
  let ampm = string12.slice(-2);
  let hh = Number(string12.split(":")[0]);
  let hh24 = hh;
  if (hh === 12) {
    hh24 = ampm === "pm" ? hh : 0;
  } else {
    hh24 = ampm === "pm" ? hh + 12 : hh;
  }
  let mm = string12.split(":")[1].slice(0, 2);
  let final =
    JSON.stringify(hh24).length === 2
      ? JSON.stringify(hh24)
      : `0${JSON.stringify(hh24)}`;
  return `${final}:${mm}:00+05:30`;
};

export const getHourFromDateTime = (inputDate) => {
  if (!inputDate) {
    return "";
  }
  return new Date(inputDate).toLocaleTimeString("en-US", {
    hour: "numeric",
    hour12: true,
  });
};

export const getHHMM = (inputDate) => {
  if (!inputDate) {
    return "";
  }
  return new Date(inputDate).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

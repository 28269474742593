import React from "react";
import { ListGroup } from "react-bootstrap";
import RatingNew from "../RatingNew";
import { getReviewsToShow } from "../../Utils/reviewsHelpers";

const AllReviews = ({ product }) => {
  if (!product || !product?.id) {
    return null;
  }
  const { othersReviews, rating, userOrderItems, numReviews } = product;
  const reviewsToShow = getReviewsToShow(othersReviews);
  const userItemReview = userOrderItems?.find((item) => item.review);
  const userReview = userItemReview?.review;
  const sortedReviews = reviewsToShow?.sort((a, b) => b.rating - a.rating);
  const showReviews = numReviews > 0;

  if (!showReviews) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 15,
        background: "#f5f5f5",
        padding: 5,
        paddingTop: 15,
        borderRadius: 10,
        width: "100%",
      }}
    >
      <div style={{ marginLeft: 5 }}>
        <p className="text-heading-secondary">Ratings and Reviews</p>
        <RatingNew value={rating} text={`${numReviews} reviews`} />
      </div>

      {userReview && (
        <div style={{ marginTop: 15 }}>
          <p className="p-0 m-0 text-body-bold">Your Review</p>
          <ListGroup
            variant="flush"
            className="mb-2 py-0"
            key={userReview.id}
            border-1
            style={{ borderRadius: 10 }}
          >
            <ListGroup.Item>
              {userReview.rating}{" "}
              <i className="fa fa-star" style={{ color: "#f8e825" }}></i>
              {"  "}
              <strong>{userReview.title}</strong>
            </ListGroup.Item>
            <ListGroup.Item>{userReview.body}</ListGroup.Item>
          </ListGroup>
        </div>
      )}

      {sortedReviews && sortedReviews.length > 0 && (
        <div style={{ marginTop: 15 }}>
          <p className="p-0 m-0 text-body-bold">Other Reviews</p>
          {sortedReviews.map((review) => (
            <ListGroup
              variant="flush"
              className="mb-2 py-0"
              key={review.id}
              border-1
              style={{ borderRadius: 10 }}
            >
              <ListGroup.Item>
                {review.rating}{" "}
                <i className="fa fa-star" style={{ color: "#f8e825" }}></i>
                {"  "}
                <strong>{review.title}</strong>
              </ListGroup.Item>
              <ListGroup.Item>{review.body}</ListGroup.Item>
            </ListGroup>
          ))}
        </div>
      )}
    </div>
  );
};

export default AllReviews;

export const createOtpIssue = (firstName = "NA") => {
  const mobileNumber = localStorage.getItem("userGenerate")
    ? JSON.parse(localStorage.getItem("userGenerate")).mobile
    : "NA";

  const row = {
    createdAt: Date.now(),
    firstName: firstName,
    mobile: mobileNumber,
    type: "No OTP",
    orderDisplayId: "NA",
    orderId: "NA",
    issue: "No OTP",
    item: "NA",
    details: "Not recd OTP in third attempt",
  };

  return row;
};

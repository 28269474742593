import axios from "axios";
import {
  USER_GENERATE_OTP_REQUEST,
  USER_GENERATE_OTP_SUCCESS,
  USER_GENERATE_OTP_FAIL,
  USER_GENERATE_OTP_RESET,
  USER_VERIFY_OTP_FAIL,
  USER_VERIFY_OTP_SUCCESS,
  USER_VERIFY_OTP_REQUEST,
  USER_LOGOUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAIL,
} from "../Constants/UserConstants";

import { ORDER_VOUCHER_RESET } from "../Constants/OrderConstants";

import { displayFlashMessage } from "./FlashMessageActions";
import scrollToTop from "../Utils/ScrollToTop";

import { CART_CLEAR_ITEMS } from "../Constants/CartConstants";

export const generateUserOTP = (phoneNum) => async (dispatch) => {
  try {
    dispatch({ type: USER_GENERATE_OTP_REQUEST });
    const { data } = await axios.post("api/users/send-otp/", {
      mobile: phoneNum,
    });
    dispatch({ type: USER_GENERATE_OTP_SUCCESS, payload: data });
    localStorage.setItem("userGenerate", JSON.stringify(data));
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_GENERATE_OTP_FAIL, payload: error.response });
  }
};

export const verifyUserOTP = (user) => async (dispatch) => {
  try {
    dispatch({ type: USER_VERIFY_OTP_REQUEST });

    const { data } = await axios.post("api/users/login/", user);
    dispatch({ type: USER_VERIFY_OTP_SUCCESS, payload: data });

    localStorage.setItem("userLogin", JSON.stringify(data));

    localStorage.removeItem("userGenerate");
    dispatch({ type: USER_GENERATE_OTP_RESET });
  } catch (error) {
    dispatch({ type: USER_VERIFY_OTP_FAIL, payload: error.response });
  }
};

export const getUserProfile = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_PROFILE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get("api/users/profile/", config);
    dispatch({ type: USER_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: USER_PROFILE_FAIL, payload: error.response });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.patch(
      "api/users/profile/update/",
      user,
      config
    );
    dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "Profile Updated",
      })
    );
    scrollToTop();

    const newLogin = {
      mobile: login.mobile,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      token: login.token,
    };
    dispatch({ type: USER_VERIFY_OTP_SUCCESS, payload: newLogin });
    localStorage.setItem("userLogin", JSON.stringify(newLogin));
    dispatch({ type: USER_UPDATE_PROFILE_RESET });
  } catch (error) {
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: error.response });
  }
};

export const logoutUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LOGOUT_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.post("api/users/logout/", "logout", config);
    localStorage.clear();
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: CART_CLEAR_ITEMS });
    dispatch({ type: ORDER_VOUCHER_RESET });
    dispatch({ type: USER_LOGOUT_SUCCESS, payload: data });
    scrollToTop();
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "You have been logged out",
      })
    );
  } catch (error) {
    dispatch({ type: USER_LOGOUT_FAIL, payload: error.response });
  }
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, ListGroup, Button } from "react-bootstrap";
import Message from "../Components/Message";
import {
  getAddressById,
  getDeliveryOptions,
  getIsCustomerPodAllowed,
} from "../Actions/CartActions";
import Loader from "../Components/Loader";
import { createOrder, verifyPayment } from "../Actions/OrderActions";
import {
  GST_PERCENTAGE,
  RAZORPAY_CHECKOUT_SCRIPT,
  POD_ORDER_VALUE_LIMIT,
} from "../Constants/GlobalConstants";
import {
  ORDER_CREATE_RESET,
  RAZORPAY_FAIL,
  RAZORPAY_RESET,
  ORDER_VOUCHER_RESET,
} from "../Constants/OrderConstants";
import { CART_CLEAR_ITEMS } from "../Constants/CartConstants";
import {
  calculateTotalCheckoutAmount,
  createSlotData,
  getItemsPrice,
  getSlotCount,
  getTaxedPrice,
  getTempOrder,
  getTotalPrice,
} from "../Utils/checkoutHelpers";
import { getFromLocalStorage } from "../Utils/browserHelper";
import { SELECTED_SLOT_DATA } from "../Constants/BrowserConstants";
import AmountDetails from "../Components/Checkout/AmountDetails";
import PaymentConfirmModal from "../Components/Checkout/PaymentConfirmModal";
import PaymentAction from "../Components/Checkout/PaymentAction";
import AddressContainer from "../Components/Checkout/AddressContainer";
import DeliveryContainer from "../Components/Checkout/DeliveryContainer";
import usePageTracking from "../CustomHooks/usePageTracking";
import { trackEvent } from "../Config/amplitude";

function CheckoutScreenNew({ match, history }) {
  const dispatch = useDispatch();

  const [scriptLoading, setScriptLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSlotDetails, setSelectedSlotDetails] = useState(null);

  const { cartItems } = useSelector((state) => state.cart);
  const { delOptions } = useSelector((state) => state.deliveryOptions);
  const {
    delAddress,
    loading: loadingAddress,
    error: errorAddress,
  } = useSelector((state) => state.deliveryAddress);
  const {
    success: createOrderSuccess,
    order,
    loading: loadingCreate,
    error: errorCreate,
  } = useSelector((state) => state.orderCreate);
  const {
    success: razorpaySuccess,
    loading: razorpayLoading,
    error: razorpayError,
  } = useSelector((state) => state.razorpay);
  const { discountCoupon } = useSelector((state) => state.orderDiscount);
  const { isCustomerPodAllowed } = useSelector(
    (state) => state.isCustomerPodAllowed
  );

  const addressId = match.params.addressId;

  const itemsPrice = getItemsPrice(cartItems);
  const deliveryFee = selectedSlotDetails?.delFee;
  const taxPrice = getTaxedPrice(GST_PERCENTAGE, itemsPrice);
  const totalPrice = getTotalPrice(itemsPrice, deliveryFee, taxPrice);

  usePageTracking("Checkout Screen");

  useEffect(() => {
    dispatch({ type: ORDER_CREATE_RESET });
    dispatch({ type: RAZORPAY_RESET });

    const script = document.createElement("script");
    script.src = RAZORPAY_CHECKOUT_SCRIPT;
    script.onload = () => {
      setScriptLoading(true);
    };
    script.onerror = () => {
      setScriptLoading(false);
    };
    document.body.appendChild(script);
    if (!delAddress || delAddress.id !== addressId) {
      dispatch(getAddressById(addressId));
    }
  }, [dispatch, addressId]);

  useEffect(() => {
    Boolean(cartItems && cartItems.length > 0) &&
      dispatch(
        getDeliveryOptions({
          productList: cartItems.map((item) => {
            return { id: item.id, quantity: item.quantityPossible };
          }),
        })
      );
  }, [dispatch, cartItems]);

  useEffect(() => {
    if (createOrderSuccess && order.paymentMethod === 10) {
      let backendOptions = order ? order.razorpayCheckoutOptions : null;
      const options = {
        ...backendOptions,
        handler: async function (response) {
          const data = {
            myjaxyPaymentId: order.myjaxyPaymentId,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };
          dispatch(verifyPayment(data));
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        const paymentFail = response.error.description;
        dispatch({ type: RAZORPAY_FAIL, payload: paymentFail });
      });
      paymentObject.open();
    } else if (
      createOrderSuccess &&
      Boolean(
        order.paymentMethod === 20 ||
          order.paymentMethod === 60 ||
          order.paymentMethod === 50
      )
    ) {
      dispatch({ type: CART_CLEAR_ITEMS });
      localStorage.removeItem("cartItemsNew1");
      history.push(`/order/confirm/${order.myjaxyPaymentId}`);
    }
  }, [dispatch, createOrderSuccess]);

  useEffect(() => {
    if (razorpaySuccess) {
      dispatch({ type: CART_CLEAR_ITEMS });
      dispatch({ type: ORDER_VOUCHER_RESET });
      localStorage.removeItem("cartIds");
      history.push(`/order/confirm/${order.myjaxyPaymentId}`);
    }
  }, [razorpaySuccess]);

  useEffect(() => {
    const localSelectedData = getFromLocalStorage(SELECTED_SLOT_DATA);
    setSelectedSlotDetails(localSelectedData);
  }, []);

  useEffect(() => {
    dispatch(getIsCustomerPodAllowed());
  }, [dispatch]);

  const placeOrder = (method) => {
    method === 20 && setShowModal(false);
    const slotsData = createSlotData(selectedSlotDetails, cartItems);

    let tempOrder = getTempOrder(
      discountCoupon,
      slotsData,
      addressId,
      deliveryFee,
      method
    );

    dispatch(createOrder(tempOrder));
    trackEvent("Clicked Checkout", { method: method });
  };

  const handleCloseModal = () => setShowModal(false);

  const podOrder = () => {
    if (isFinalAmountNull) {
      placeOrder(10);
    } else {
      setShowModal(true);
    }
  };

  const handleError = () => {
    dispatch({ type: ORDER_CREATE_RESET });
    dispatch({ type: RAZORPAY_RESET });
    history.push("/cart");
  };

  const finalAmount = calculateTotalCheckoutAmount(cartItems, discountCoupon);
  const isFinalAmountNull = finalAmount <= 0;

  const isPodOrderDisabled = () => {
    if (isFinalAmountNull) {
      return false;
    }
    const isCartEmptyOrOverLimit =
      cartItems.length === 0 || totalPrice > POD_ORDER_VALUE_LIMIT;

    const isPodNotAllowed =
      selectedSlotDetails?.isPodNotAllowed ||
      !isCustomerPodAllowed?.isCustomerPodAllowed;

    return isCartEmptyOrOverLimit || isPodNotAllowed;
  };

  const isPodUnavailable = () => {
    return isPodOrderDisabled() && totalPrice <= POD_ORDER_VALUE_LIMIT;
  };

  const slotCount = getSlotCount(selectedSlotDetails);

  if (loadingAddress || loadingCreate) {
    return <Loader />;
  }

  if (errorCreate) {
    return (
      <>
        <Message variant="danger">{errorCreate}</Message>
        <Button variant="danger" onClick={handleError}>
          Change Order
        </Button>
      </>
    );
  }

  return (
    <>
      {razorpayError && (
        <Message variant="danger">
          {"Payment failed!  "}
          {razorpayError}
          {"  "}
          {"Please try again."}
        </Message>
      )}
      <h3 style={{ marginBottom: 10 }}>Check order details</h3>
      <Row>
        <Col xs={12} md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item className="my-0 py-0">
              <AddressContainer delAddress={delAddress} />
            </ListGroup.Item>
            <ListGroup.Item className="my-1 py-0">
              <DeliveryContainer
                cartItemsLength={cartItems.length}
                delOptions={delOptions}
                slotCount={slotCount}
                selectedSlotDetails={selectedSlotDetails}
              />
            </ListGroup.Item>
            <AmountDetails
              itemsPrice={itemsPrice}
              deliveryFee={deliveryFee}
              discountCoupon={discountCoupon}
              cartItems={cartItems}
            />
            <PaymentAction
              isFinalAmountNull={isFinalAmountNull}
              cartItems={cartItems}
              scriptLoading={scriptLoading}
              placeOrder={placeOrder}
              isPodOrderDisabled={isPodOrderDisabled}
              podOrder={podOrder}
              isPodUnavailable={isPodUnavailable}
              totalPrice={totalPrice}
            />
          </ListGroup>
        </Col>
      </Row>
      {showModal && (
        <PaymentConfirmModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          placeOrder={placeOrder}
        />
      )}
    </>
  );
}

export default CheckoutScreenNew;

import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";
import CartIcon from "../Layout/Header/NavBar/CartIcon";
import { LinkContainer } from "react-router-bootstrap";

const SearchPageHeader = ({ deviceType }) => {
  const history = useHistory();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartTotal =
    cartItems?.length > 0
      ? cartItems.reduce((acc, item) => acc + item.quantityPossible, 0)
      : 0;

  const handleBack = () => {
    history.push("/");
  };

  return (
    <>
      <Navbar
        className="py-2 d-flex justify-content-between align-items-center"
        collapseOnSelect
        bg="white"
        variant="dark"
        fixed="top"
        expand="lg"
        style={{
          border: 0,
          padding: 0,
          margin: 0,
          boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Container className="d-flex justify-content-between align-items-center px-3">
          {deviceType === "sm" ? null : (
            <Navbar.Brand>
              <LinkContainer to="/" className="p-0 mx-2">
                <img
                  alt="Homble | Whole Foods"
                  src="logo_green.png"
                  width="120"
                  className="p-0 m-0"
                />
              </LinkContainer>
            </Navbar.Brand>
          )}
          <div onClick={handleBack} style={{ cursor: "pointer" }}>
            <i className="fas fa-chevron-left" />
          </div>
          <SearchBar />
          <CartIcon deviceType={deviceType} count={cartTotal} />
        </Container>
      </Navbar>
    </>
  );
};

export default SearchPageHeader;

export const issues = {
  ORDER_NOT_DELIVERED: "ORDER_NOT_DELIVERED",
  WRONG_ITEMS_DELIVERED: "WRONG_ITEMS_DELIVERED",
  MISSING_ITEMS: "MISSING_ITEMS",
  PRODUCT_QUALITY: "PRODUCT_QUALITY",
  PRODUCT_QUANTITY: "PRODUCT_QUANTITY",
  PRODUCT_PACKAGING: "PRODUCT_PACKAGING",
  TECHNICAL_ISSUE: "TECHNICAL_ISSUE",
  PROFILE_CHANGE: "PROFILE_CHANGE",
  SEE_PAST_ORDERS: "SEE_PAST_ORDERS",
  PAYMENT_RELATED: "PAYMENT_RELATED",
  REFUND_RELATED: "REFUND_RELATED",
  SUGGESTION: "SUGGESTION",
  OTHER: "OTHER",
};

export const orderIssues = [
  issues.ORDER_NOT_DELIVERED,
  issues.WRONG_ITEMS_DELIVERED,
  issues.OTHER,
];

export const productIssues = [
  issues.PRODUCT_QUALITY,
  issues.PRODUCT_QUANTITY,
  issues.PRODUCT_PACKAGING,
  issues.MISSING_ITEMS,
];

export const ORDER_ISSUES_LIST = [
  { key: issues.ORDER_NOT_DELIVERED, value: "Order not delivered" },
  { key: issues.WRONG_ITEMS_DELIVERED, value: "Incorrect items" },
  { key: issues.MISSING_ITEMS, value: "Items are missing" },
  { key: issues.PRODUCT_QUALITY, value: "Product quality" },
  { key: issues.PRODUCT_QUANTITY, value: "Product quantity" },
  { key: issues.PRODUCT_PACKAGING, value: "Product packaging" },
  { key: issues.OTHER, value: "Other" },
];

export const GENERAL_ISSUES_LIST = [
  { key: issues.TECHNICAL_ISSUE, value: "Technical issues" },
  { key: issues.PROFILE_CHANGE, value: "Change my profile" },
  { key: issues.SEE_PAST_ORDERS, value: "See my past orders" },
  { key: issues.PAYMENT_RELATED, value: "Payment related" },
  { key: issues.REFUND_RELATED, value: "Refund related" },
  { key: issues.SUGGESTION, value: "Suggestion for Homble" },
  { key: issues.OTHER, value: "Other" },
];

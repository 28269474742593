import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PortionSize from "../PortionSize";
import { sliceToSize } from "../../Utils/StringFunctions";
import { LISTING_PRODUCT_CHAR } from "../../Constants/GlobalConstants";
import AddButton from "../ProductCard/AddButton";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../Actions/CartActions";
import { getNewRecEvent } from "../../Utils/analyticsHelper";

const RecItem = ({ item, screen, onItemClick = null, loading = false }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [cartQty, setCartQty] = useState(0);

  const {
    id,
    detailImage,
    name,
    packageSize,
    measurementUnit,
    price,
    quantityAvailable,
  } = item;

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const tempItem = cartItems.find((item) => item.id === id);
      setCartQty(tempItem ? tempItem.quantityPossible : 0);
    }
  }, [cartItems, id]);

  const { screen: eventScreen, method: eventMethod } = getNewRecEvent(screen);

  const changeQty = (sign, event) => {
    event.stopPropagation();
    if (sign === "+" && !(quantityAvailable > cartQty)) {
    } else {
      dispatch(addToCart(id, sign === "+" ? cartQty + 1 : cartQty - 1));
      setCartQty(sign === "+" ? cartQty + 1 : cartQty - 1);
    }
  };

  const removeItemFromCart = () => {
    dispatch(removeFromCart(id));
    setCartQty(0);
  };

  return (
    <div
      onClick={onItemClick ? () => onItemClick(id) : null}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: 10,
        padding: 10,
        maxWidth: "200px",
        fontSize: "0.9rem",
      }}
    >
      <Row>
        <div style={{ width: 150 }}>
          <img
            src={detailImage}
            alt={name}
            style={{ width: "100%", height: "100%", borderRadius: 10 }}
          />
        </div>
      </Row>
      <Row>
        <div
          style={{
            lineHeight: "1.1",
            marginTop: 4,
          }}
        >
          <strong
            style={{
              lineHeight: 0,
              letterSpacing: 0,
              padding: 0,
              margin: 0,
              color: "#313131",
            }}
          >
            {sliceToSize(name, LISTING_PRODUCT_CHAR)}
          </strong>
        </div>
      </Row>
      <Row>
        <span
          style={{
            fontSize: "0.7rem",
          }}
        >
          ({<PortionSize packageSize={packageSize} />} {measurementUnit})
        </span>
      </Row>
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <Col xs={6}>
          <div>
            <strong style={{ color: "#313131" }}> &#8377;{price}</strong>
          </div>
        </Col>
        <Col xs={6}>
          <AddButton
            cartQty={cartQty}
            product={item}
            changeQty={changeQty}
            removeFromCart={removeItemFromCart}
            trackingData={{
              eventHeading: "Added Product",
              eventProps: { method: eventMethod, screen: eventScreen },
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default RecItem;

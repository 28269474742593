import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const DetailsInput = (props) => {
  const {
    detailsLabel,
    setDetails,
    onDetailsBlurHandler,
    onDetailSubmitHandler,
    isProductIssue,
  } = props;
  return (
    <Form.Group className="mb-3" controlId="freetext">
      <Form.Label>{detailsLabel}</Form.Label>
      <div className="d-flex flex-row">
        <Form.Control
          as="input"
          type="text"
          onChange={(e) => setDetails(e.target.value)}
          onBlur={onDetailsBlurHandler}
          autoComplete="off"
        />
        {isProductIssue && (
          <Button size="sm" variant="info" onClick={onDetailSubmitHandler}>
            <i className="fas fa-paper-plane "></i>
          </Button>
        )}
      </div>
    </Form.Group>
  );
};

export default DetailsInput;

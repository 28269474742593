import { Button, Form, Col } from "react-bootstrap";

function SearchBox({
  width,
  onSearchFocus,
  handleSubmit,
  keyword,
  setKeyword,
}) {
  return (
    <Col xs={width ? width : 10} sm={8} md={6} lg={4} xl={3} className="p-1">
      <Form
        onSubmit={handleSubmit}
        className="search-form d-flex border-0"
        action="."
      >
        <Form.Control
          type="search"
          className="search-input"
          name="search"
          id="searchbar"
          aria-label="Search"
          value={keyword}
          placeholder="Search for fruits, batters and more"
          onChange={(e) => setKeyword(e.target.value)}
          onFocus={onSearchFocus}
        />
        <Button type="submit" className="search-button" onClick={handleSubmit}>
          <i className="fas fa-search fa-md" style={{ color: "#006e45" }}></i>
        </Button>
      </Form>
    </Col>
  );
}

export default SearchBox;

import React from "react";
import { Card, Placeholder } from "react-bootstrap";

const CategoryCardSkeleton = ({ width }) => {
  return (
    <Card
      style={{
        padding: 0,
        margin: 0,
        maxWidth: width,
        height: "166px",
      }}
      className="Category mx-auto border-0 m-0"
    >
      <div
        style={{
          borderRadius: "10%",
          width: "100%",
          height: "93px",
          backgroundColor: "#f0f0f0",
        }}
      >
        <Placeholder as="image" animation="glow">
          <Placeholder
            as="div"
            style={{
              borderRadius: "10%",
              width: "100%",
              height: "100%",
            }}
          />
        </Placeholder>
      </div>
    </Card>
  );
};

export default CategoryCardSkeleton;

import React from "react";
import { Placeholder, Row } from "react-bootstrap";

const CollectionTileSkeleton = () => {
  const placeHolderItems = [...Array(12)];
  return (
    <div
      className={`mt-4`}
      style={{
        background: "#e9e6e5",
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        opacity: 0.3,
      }}
    >
      <Placeholder className="m-4" as="div" animation="glow">
        <Placeholder as="p" xs={3} style={{ borderRadius: 16 }} />
      </Placeholder>
      <Row
        className=""
        style={{
          display: "grid",
          gridAutoColumns: "minmax(1fr,auto)",
          overflowX: "scroll",
          scrollbarWidth: 0,
          marginTop: 20,
        }}
      >
        <div className="infinite-scroll-content" style={{ gap: 30 }}>
          {placeHolderItems.map((_, index) => (
            <div className="p-0 m-0">
              <Placeholder as="div" animation="glow">
                <Placeholder
                  style={{
                    width: "120px",
                    height: "136px",
                    borderRadius: 10,
                  }}
                />
              </Placeholder>
            </div>
          ))}
        </div>
      </Row>
    </div>
  );
};

export default CollectionTileSkeleton;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrders } from "../Actions/OrderActions";
import Loader from "../Components/Loader";
import ErrorHandle from "../Components/ErrorHandle";
import OrderItemNew from "../Components/MyOrders/OrderItemNew";

function MyOrdersScreenNew({ history }) {
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state.userVerifyOTP);
  const { loading, error, orderList } = useSelector(
    (state) => state.listMyOrders
  );

  useEffect(() => {
    if (!login) {
      history.push("/login");
    }
    dispatch(getMyOrders());
  }, [dispatch, login, history]);

  if (loading) return <Loader />;
  if (error)
    return (
      <ErrorHandle key="error-list-orders" history={history} error={error} />
    );

  if (!orderList?.payments?.length) return <h6>No past orders</h6>;

  return (
    <>
      <h4 className="text-heading-primary">My Orders</h4>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {orderList?.payments?.map((order, index) => (
          <OrderItemNew key={order.id} order={order} />
        ))}
      </div>
    </>
  );
}
export default MyOrdersScreenNew;

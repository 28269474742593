import React from "react";
import { Button, Modal } from "react-bootstrap";

const PaymentConfirmModal = ({ showModal, handleCloseModal, placeOrder }) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title className="red-title">Confirm Pay on Delivery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please ensure someone is{" "}
        <strong>at home during the delivery slot</strong> to collect and pay for
        the order. In case you wish to pay by cash, kindly ensure you have the
        exact amount.{" "}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>

        <Button variant="info" onClick={() => placeOrder(20)}>
          Confirm Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentConfirmModal;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentById } from "../Actions/OrderActions";
import { Button } from "react-bootstrap";
import OrderDetails from "../Components/MyOrders/OrderDetails";

const OrderScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const { order, loading, error } = useSelector(
    (state) => state.paymentDetails
  );

  useEffect(() => {
    dispatch(getPaymentById(match.params.id));
  }, [dispatch, match.params.id]);

  if (!order) {
    return null;
  }

  const handleBack = () => {
    history.go(-1);
  };
  return (
    <div>
      <Button variant="secondary" onClick={handleBack}>
        Go Back
      </Button>
      <div className="mt-2">
        <OrderDetails order={order} />
      </div>
    </div>
  );
};

export default OrderScreen;

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const RAZORPAY_REQUEST = "RAZORPAY_REQUEST";
export const RAZORPAY_SUCCESS = "RAZORPAY_SUCCESS";
export const RAZORPAY_FAIL = "RAZORPAY_FAIL";
export const RAZORPAY_RESET = "RAZORPAY_RESET";

export const ORDER_CONFIRM_REQUEST = "ORDER_CONFIRM_REQUEST";
export const ORDER_CONFIRM_SUCCESS = "ORDER_CONFIRM_SUCCESS";
export const ORDER_CONFIRM_FAIL = "ORDER_CONFIRM_FAIL";
export const ORDER_CONFIRM_RESET = "ORDER_CONFIRM_RESET";

export const ORDER_CANCEL_REQUEST = "ORDER_CANCEL_REQUEST";
export const ORDER_CANCEL_SUCCESS = "ORDER_CANCEL_SUCCESS";
export const ORDER_CANCEL_FAIL = "ORDER_CANCEL_FAIL";
export const ORDER_CANCEL_RESET = "ORDER_CANCEL_RESET";

export const ORDER_VOUCHER_REQUEST = "ORDER_VOUCHER_REQUEST";
export const ORDER_VOUCHER_SUCCESS = "ORDER_VOUCHER_SUCCESS";
export const ORDER_VOUCHER_FAIL = "ORDER_VOUCHER_FAIL";
export const ORDER_VOUCHER_RESET = "ORDER_VOUCHER_RESET";

export const ORDER_NEWCONFIRM_REQUEST = "ORDER_NEWCONFIRM_REQUEST";
export const ORDER_NEWCONFIRM_SUCCESS = "ORDER_NEWCONFIRM_SUCCESS";
export const ORDER_NEWCONFIRM_FAIL = "ORDER_NEWCONFIRM_FAIL";
export const ORDER_NEWCONFIRM_RESET = "ORDER_NEWCONFIRM_RESET";

export const DISCOUNT_CODE_REQUEST = "DISCOUNT_CODE_REQUEST";
export const DISCOUNT_CODE_SUCCESS = "DISCOUNT_CODE_SUCCESS";
export const DISCOUNT_CODE_FAIL = "DISCOUNT_CODE_FAIL";
export const DISCOUNT_CODE_RESET = "DISCOUNT_CODE_RESET";

export const TEMP_ORDER_RESET = "TEMP_ORDER_RESET";
export const TEMP_ORDER_CREATE = "TEMP_ORDER_CREATE";

export const ORDER_BY_ID_REQUEST = "ORDER_BY_ID_REQUEST";
export const ORDER_BY_ID_SUCCESS = "ORDER_BY_ID_SUCCESS";
export const ORDER_BY_ID_FAIL = "ORDER_BY_ID_FAIL";
export const ORDER_BY_ID_RESET = "ORDER_BY_ID_RESET";

export const PAYMENT_BY_ID_REQUEST = "PAYMENT_BY_ID_REQUEST";
export const PAYMENT_BY_ID_SUCCESS = "PAYMENT_BY_ID_SUCCESS";
export const PAYMENT_BY_ID_FAIL = "PAYMENT_BY_ID_FAIL";
export const PAYMENT_BY_ID_RESET = "PAYMENT_BY_ID_RESET";

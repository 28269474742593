import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getUserProfile, updateUserProfile } from "../Actions/UserActions";
import Loader from "../Components/Loader";
import { EMAIL_REG_EX } from "../Constants/GlobalConstants";
import ErrorHandle from "../Components/ErrorHandle";
import { USER_UPDATE_PROFILE_RESET } from "../Constants/UserConstants";

function Profilescreen({ history }) {
  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  const userGetProfile = useSelector((state) => state.userGetProfile);
  const { loading, error, user } = userGetProfile;

  const [firstName, setFirstName] = useState(user ? user.firstName : "");
  const [lastName, setLastName] = useState(user ? user.lastName : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [mobile, setMobile] = useState(user ? user.mobile : 0);

  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success, error: errorUpdate } = userUpdateProfile;

  useEffect(() => {
    dispatch({ type: USER_UPDATE_PROFILE_RESET });
    if (!login || Object.keys(login).length === 0) {
      history.push("/login");
    }
  }, [login, history]);

  useEffect(() => {
    if (!user || success || Boolean(login.mobile !== user.mobile)) {
      dispatch(getUserProfile());
    }
  }, [dispatch, login, user, success]);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setMobile(user.mobile);
    }
  }, [user]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropogation();
    }
    setValidated(true);
    const EmailRegEx = EMAIL_REG_EX;
    if (firstName.length > 2 && EmailRegEx.test(email)) {
      dispatch(updateUserProfile({ firstName, lastName, email }));
    }
  };
  return (
    <Row className="mr-auto">
      <Col xs={10} md={4}>
        {loading ? (
          <Loader />
        ) : error ? (
          <ErrorHandle key="get-profile" history={history} error={error} />
        ) : user && Object.keys(user).length > 0 ? (
          <Form noValidate validated={validated} onSubmit={handleUpdate}>
            <h2>My Profile</h2>
            {errorUpdate && (
              <ErrorHandle
                key="update-profile"
                history={history}
                error={errorUpdate}
              />
            )}
            <Form.Group className="mb-3" controlId="mobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Mobile no."
                value={mobile}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                minLength="3"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid firstName
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              onClick={handleUpdate}
              disabled={
                firstName === user.firstName &&
                Boolean(lastName === user.lastName && email === user.email)
              }
            >
              Update
            </Button>
          </Form>
        ) : (
          <h4>Couldnt find a profile</h4>
        )}
      </Col>
    </Row>
  );
}

export default Profilescreen;

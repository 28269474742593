import React, { useEffect, useState } from "react";
import ApartmentList from "../Components/ApartmentList";
import "../Styles/ModalStyles.css";
import FormContainer from "../Components/FormContainer";
import { Button } from "react-bootstrap";
import FullAddressForm from "../Components/Maps/FullAddressForm";
import MapAddressFlow from "../Components/Maps/MapAddressFlow";
import scrollToTop from "../Utils/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import {
  APARTMENT_ADDRESS_CREATE_RESET,
  SHIPPING_ADDRESS_CREATE_RESET,
} from "../Constants/CartConstants";
import ErrorHandle from "../Components/ErrorHandle";
import Loader from "../Components/Loader";
import { center } from "../Utils/addressHelpers";
import usePageTracking from "../CustomHooks/usePageTracking";

const AddressCreateScreenNew = ({ history }) => {
  const [addressObj, setAddressObj] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [locationAddress, setLocationAddress] = useState(null);
  const [locationCoordinates, setLocationCoordinates] = useState(center);
  const [isNotListed, setIsNotListed] = useState(false);
  const dispatch = useDispatch();

  const createShippingAddress = useSelector(
    (state) => state.createShippingAddress
  );
  const {
    loading: loadingCreateShippingAddress,
    success: successCreateShippingAddress,
    error: errorCreateShippingAddress,
  } = createShippingAddress;

  const createApartmentAddress = useSelector(
    (state) => state.createApartmentAddress
  );

  const {
    loading: loadingCreateApartmentAddress,
    success: successCreateApartmentAddress,
    error: errorCreateApartmentAddress,
  } = createApartmentAddress;

  usePageTracking("Address Create Screen");

  useEffect(() => {
    dispatch({ type: SHIPPING_ADDRESS_CREATE_RESET });
    dispatch({ type: APARTMENT_ADDRESS_CREATE_RESET });
    scrollToTop();
  }, []);

  useEffect(() => {
    if (successCreateShippingAddress || successCreateApartmentAddress) {
      scrollToTop();
      history.push("/shipping");
    }
  }, [successCreateShippingAddress, successCreateApartmentAddress, history]);

  const onNotFoundHandler = () => {
    setIsNotListed(true);
  };

  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return (
    <FormContainer>
      {loadingCreateShippingAddress && <Loader />}
      {errorCreateShippingAddress && (
        <ErrorHandle error={errorCreateShippingAddress} history={history} />
      )}
      <div style={{ position: "relative" }}>
        <h3>Add your address details</h3>
        {!isNotListed && (
          <ApartmentList
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            history={history}
            setLocationAddress={setLocationAddress}
            setAddressObj={setAddressObj}
          />
        )}
        {!selectedOption && !isNotListed && (
          <Button className="mt-3" onClick={onNotFoundHandler}>
            Location Not Found ?{" "}
          </Button>
        )}
        {selectedOption && !isNotListed && (
          <div style={{ marginTop: 30 }}>
            <FullAddressForm
              screenWidth={screenWidth}
              addressObj={addressObj}
              isLocation={true}
            />
          </div>
        )}
        {isNotListed && (
          <MapAddressFlow
            isLoaded={true}
            addressObj={addressObj}
            setAddressObj={setAddressObj}
            locationCoordinates={locationCoordinates}
            setLocationCoordinates={setLocationCoordinates}
            locationAddress={locationAddress}
            setLocationAddress={setLocationAddress}
          />
        )}
      </div>
    </FormContainer>
  );
};

export default AddressCreateScreenNew;

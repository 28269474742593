import React, { useState } from "react";
import FullAddress from "./FullAddress";
import AddressConfirm from "./AddressConfirm";
import MapWithSearch from "./MapWithSearch";

const MapAddressFlow = (props) => {
  const {
    isLoaded,
    addressObj,
    setAddressObj,
    locationCoordinates,
    setLocationCoordinates,
    locationAddress,
    setLocationAddress,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const modalCancelHandler = () => {
    setShowModal(false);
  };

  const onConfirmLocation = () => {
    setShowModal(true);
  };
  return (
    <div>
      <div className="mt-2">
        {isLoaded && (
          <MapWithSearch
            isLoaded={isLoaded}
            setAddressObj={setAddressObj}
            locationCoordinates={locationCoordinates}
            setLocationCoordinates={setLocationCoordinates}
            setLocationAddress={setLocationAddress}
            addressObj={addressObj}
          />
        )}
      </div>

      {locationAddress && (
        <AddressConfirm
          locationAddress={locationAddress}
          onConfirmLocation={onConfirmLocation}
        />
      )}

      {showModal && (
        <FullAddress
          addressObj={addressObj}
          showModal={showModal}
          modalCancelHandler={modalCancelHandler}
          locationAddress={locationAddress}
        />
      )}
    </div>
  );
};

export default MapAddressFlow;

import { useSelector } from "react-redux";
import NavbarBig from "./NavBar/NavbarBig";
import NavbarSmall from "./NavBar/NavbarSmall";
import MobileNavbar from "./NavBar/MobileNavbar";
import { useLocation } from "react-router-dom";

function Header() {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const { pathname } = useLocation();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  if (pathname.includes("/search")) {
    return null;
  }
  return (
    <>
      {width > 1000 ? (
        <NavbarBig
          cartTotal={
            Boolean(cartItems && cartItems.length > 0)
              ? cartItems.reduce((acc, item) => acc + item.quantityPossible, 0)
              : null
          }
        />
      ) : width >= 500 ? (
        <NavbarSmall
          cartTotal={
            Boolean(cartItems && cartItems.length > 0)
              ? cartItems.reduce((acc, item) => acc + item.quantityPossible, 0)
              : 0
          }
          width={width}
        />
      ) : (
        <MobileNavbar
          cartTotal={
            Boolean(cartItems && cartItems.length > 0)
              ? cartItems.reduce((acc, item) => acc + item.quantityPossible, 0)
              : 0
          }
          width={width}
        />
      )}
    </>
  );
}

export default Header;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Loader from "./Loader";

const ReportModal = (props) => {
  const {
    ispdfShow,
    handlepdfClose,
    pdfUrl = "",
    imageUrl = "",
    productName,
  } = props;
  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (!pdfUrl) {
      setIsLoading(false);
    }
    if (ispdfShow) {
      setIsLoading(true);
    }
  }, [ispdfShow]);

  const showImage = isLoading || !pdfUrl;
  const showReport = !!pdfUrl;
  const showReportLoader = showReport && isLoading;

  return (
    <div>
      <Modal
        show={ispdfShow}
        dialogClassName="modal-fullscreen"
        onHide={handlepdfClose}
        centered
      >
        <Modal.Header closeButton onClick={handlepdfClose}>
          <Modal.Title>Lab Report for {productName}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          {showImage && (
            <img alt="Report" src={imageUrl} className="image-page" />
          )}

          {showReport && (
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              Download PDF
            </a>
          )}

          {showReportLoader && (
            <div>
              <Loader />
            </div>
          )}
          {showReport && (
            <iframe
              title={"lab report opener"}
              src={`https://docs.google.com/viewerng/viewer?url=${encodeURIComponent(
                pdfUrl
              )}&embedded=true`}
              className="iframe-fullscreen"
              onLoad={handleIframeLoad}
            ></iframe>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ReportModal;

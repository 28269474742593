import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ORDER_CANCEL_RESET } from "../../Constants/OrderConstants";
import { useDispatch, useSelector } from "react-redux";
import OrderCancelModal from "./OrderCancelModal";
import { cancelOrder } from "../../Actions/OrderActions";
import ActionContainer from "./ActionContainer";

const SlotOrdersItems = (props) => {
  const { orders, paymentId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading: loadingCancel,
    success: successCancel,
    error: errorCancel,
  } = useSelector((state) => state.orderCancel);

  const [showModal, setShowModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelId, setCancelId] = useState(0);

  useEffect(() => {
    if (successCancel) {
      setShowModal(false);
    }
  }, [successCancel]);

  const handleIssue = (orderId) => {
    history.push(`/help/${orderId}`);
  };

  const handleCancel = (orderId) => {
    dispatch({ type: ORDER_CANCEL_RESET });
    setCancelId(orderId);
    setShowModal(true);
  };

  const orderCancellation = () => {
    dispatch(cancelOrder(cancelId, { reason: cancelReason }, paymentId));
  };

  const handleClose = () => setShowModal(false);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {orders?.map((order) => {
        const isDelivered = order?.status === 70;
        const canShowCancel =
          ![10, 50, 60, 70, 90].includes(order?.status) && order?.canCancel;
        return (
          <div
            key={order.id}
            className="p-1 py-2"
            style={{
              border: "1px solid lightGray",
              display: "flex",
              flexDirection: "column",
              fontSize: "0.9rem",
              gap: 5,
            }}
          >
            {order?.items?.map((item) => {
              const onClickHandler = () => {
                history.push(`/product/${item?.productId}`);
              };
              return (
                <div className="d-flex gap-2" key={item.id}>
                  <Image
                    src={item?.productCartImage}
                    onClick={onClickHandler}
                    alt={"item"}
                    fluid
                    style={{
                      borderRadius: 8,
                      marginLeft: 5,
                      maxHeight: "40px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      paddingRight: 10,
                      paddingLeft: 5,
                    }}
                  >
                    <p
                      className="p-0 m-0 text-truncate"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                        whiteSpace: "normal",
                      }}
                    >
                      {item.productName}
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className="p-0 m-0">
                        {parseInt(item?.productPackageSize, 10)}{" "}
                        {item?.productMeasurementUnit} X {item?.quantity}
                      </p>
                      <p className="p-0 m-0">
                        &#8377;{item?.quantity * item?.price}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="mt-1 justify-content-end text-body-bold">
              <span className="text-body-bold">Order Subtotal: </span>{" "}
              <span> &#8377;{order?.itemsSubtotal}</span>
            </div>
            <ActionContainer
              handleCancel={handleCancel}
              handleIssue={handleIssue}
              orderId={order?.id}
              orderStatus={order.status}
              isDelivered={isDelivered}
              canShowCancel={canShowCancel}
            />
          </div>
        );
      })}
      {showModal && (
        <OrderCancelModal
          showModal={showModal}
          handleClose={handleClose}
          loadingCancel={loadingCancel}
          errorCancel={errorCancel}
          setCancelReason={setCancelReason}
          orderCancellation={orderCancellation}
        />
      )}
    </div>
  );
};

export default SlotOrdersItems;

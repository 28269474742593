import React from "react";
import { Button } from "react-bootstrap";

const ActionContainer = ({
  handleCancel,
  handleIssue,
  orderId,
  orderStatus,
  isDelivered,
  canShowCancel,
}) => {
  if (!orderId) {
    return null;
  }
  if (orderStatus === 90) {
    return (
      <p className="text-heading-secondary text-primary p-0 m-0">Cancelled</p>
    );
  }
  return (
    <div className="d-flex justify-content-between mt-2">
      {canShowCancel && (
        <Button
          variant="outline-info"
          size="sm"
          onClick={() => handleCancel(orderId)}
        >
          Cancel
        </Button>
      )}
      {isDelivered && (
        <Button variant="danger" size="sm" onClick={() => handleIssue(orderId)}>
          Report Issue
        </Button>
      )}
    </div>
  );
};

export default ActionContainer;

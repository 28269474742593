import { homeEvents, recEvents } from "../Constants/AnalyticsEvents";

export const getRecEvent = (screen) => {
  switch (screen) {
    case "HOME":
      return recEvents.CLICKED_REC_HOME;

    case "COLLECTION":
      return recEvents.CLICKED_REC_COLLECTION;

    case "CATEGORY":
      return recEvents.CLICKED_REC_CATEGORY;

    case "PRODUCT":
      return recEvents.CLICKED_REC_PRODUCT;

    case "CART":
      return recEvents.CLICKED_REC;

    case "PREV_ITEM":
      return homeEvents.CLICKED_PREV_ITEM;

    default:
      return recEvents.CLICKED_REC_OTHER;
  }
};

export const getNewRecEvent = (screen) => {
  switch (screen) {
    case "HOME":
      return { screen: "HOME", method: "REC" };

    case "COLLECTION":
      return { screen: "COLLECTION", method: "REC" };

    case "CATEGORY":
      return { screen: "CATEGORY", method: "REC" };

    case "PRODUCT":
      return { screen: "PRODUCT", method: "REC" };

    case "CART":
      return { screen: "CART", method: "CART_REC" };

    case "PREV_ITEM":
      return { screen: "HOME", method: "PREV_ITEM" };

    case "NEW_LAUNCHED":
      return { screen: "HOME", method: "NEW_LAUNCHED" };

    default:
      return { screen: "OTHER", method: "OTHER" };
  }
};

import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { colors } from "../../../../Constants/colors";

const CartIcon = ({ deviceType, count }) => {
  const isMobile = deviceType === "sm" ? true : false;
  if (!isMobile) {
    return (
      <LinkContainer to="/cart">
        <Nav.Link as="div">
          <span className="cart-basketSmall d-flex align-items-center justify-content-center">
            {count > 0 ? JSON.stringify(count) : "0"}
          </span>
          <i
            className="fas fa-shopping-cart fa-lg"
            style={{ color: colors.primary }}
          ></i>
        </Nav.Link>
      </LinkContainer>
    );
  } else {
    return (
      <LinkContainer to="/cart">
        <Nav.Link as="div" className="mt-2 pb-0 mb-0 ms-2 me-0 px-0">
          <div className="pt-1">
            <i
              className="fas fa-shopping-cart fa-lg"
              style={{ color: colors.primary }}
            ></i>
            <span className="cart-basketMobile fa-stack fa-sm">
              <i
                style={{ color: "#418deb" }}
                className="fa fa-circle fa-stack-2x"
              ></i>
              <strong className="fa-stack-1x text-white">
                {" "}
                {count > 0 ? JSON.stringify(count) : "0"}
              </strong>
            </span>
          </div>
        </Nav.Link>
      </LinkContainer>
    );
  }
};

export default CartIcon;

// <LinkContainer
//   to="/cart"
//   style={{
//     background: colors.primary,
//     borderRadius: 10,
//     justifyContent: "center",
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     padding: 10,
//     paddingLeft: 15,
//     paddingRight: 15,
//     gap: 5,
//   }}
// >
//   <div>
//     <i
//       className="fas fa-shopping-cart fa-lg mt-3"
//       style={{ color: "white" }}
//     ></i>
//     <span style={{ color: "white", fontWeight: 600, fontSize: "0.9rem" }}>
//       {count > 0 ? `${count} items` : `My Cart`}
//     </span>
//   </div>
// </LinkContainer>;

import React from "react";
import { Form, Spinner, FloatingLabel } from "react-bootstrap";
import Message from "../Message";
import ErrorHandle from "../ErrorHandle";
import { useHistory } from "react-router-dom";

const NewAccount = (props) => {
  const {
    messageGenerateOTP,
    loadingVerifyOTP,
    errorVerifyOTP,
    setFirstName,
    setLastName,
    setEmail,
  } = props;

  const history = useHistory();
  return (
    <div>
      <Form.Group>
        {messageGenerateOTP && (
          <Message variant="success">{messageGenerateOTP.detail}</Message>
        )}
        {loadingVerifyOTP && <Spinner animation="border" />}

        {errorVerifyOTP && (
          <ErrorHandle
            key="verify-otp"
            history={history}
            error={errorVerifyOTP}
          />
        )}
        <FloatingLabel
          controlId="Firstname"
          label="First Name"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Your firstname here"
            onChange={(e) => setFirstName(e.target.value)}
            autoFocus={
              messageGenerateOTP ? !messageGenerateOTP.existingUser : null
            }
            required
          />
          <Form.Control.Feedback type="invalid">
            Enter a valid first name
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel controlId="Lastname" label="Last Name" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Your last here"
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Enter a valid last name
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel controlId="Email" label="Email" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Your Email here"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Enter a valid email
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </div>
  );
};

export default NewAccount;

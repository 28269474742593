import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import Message from "../Message";
import { convertNumToWords } from "../../Utils/StringFunctions";
import { getStringSlot } from "../../Utils/SlotFunction";

const DeliveryContainer = ({
  cartItemsLength,
  delOptions,
  slotCount,
  selectedSlotDetails,
}) => {
  const deliveryHeading = convertNumToWords(slotCount);

  if (cartItemsLength === 0) {
    return <Message variant="info">No items in your cart</Message>;
  }

  if (!Boolean(delOptions && Object.keys(delOptions).length > 0)) {
    return null;
  }

  return (
    <div>
      <h5 className="text-heading-secondary">
        {deliveryHeading} {slotCount === 1 ? "Delivery" : "Deliveries"}
      </h5>
      {selectedSlotDetails?.slots?.map((item, index) => {
        const { slot, products } = item;
        return (
          <React.Fragment key={index}>
            <h6 style={{ color: "blue", fontWeight: 600 }}>
              {getStringSlot(slot)}
            </h6>
            {products?.map((product, productIndex) => {
              return (
                <Row className="mb-2" key={productIndex}>
                  <Col xs={2} md={4}>
                    <Image
                      src={product.cartImage}
                      alt={product.name}
                      style={{ maxWidth: "50px" }}
                      fluid
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <p style={{ float: "left" }}>{product.name}</p>
                  </Col>
                </Row>
              );
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default DeliveryContainer;

//STALE FILE
import React from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { getReviewsToShow } from "../../Utils/reviewsHelpers";

const ReviewsModal = (props) => {
  const { showModal, handleClose, product } = props;
  const { name, othersReviews } = product;
  const reviewsToShow = getReviewsToShow(othersReviews);
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <h5>Reviews for {name}</h5>
      </Modal.Header>
      <Modal.Body>
        {reviewsToShow &&
          reviewsToShow?.map((review) => (
            <ListGroup
              variant="flush"
              className="mb-2 py-0"
              key={review.id}
              border-1
            >
              <ListGroup.Item>
                {review.rating}{" "}
                <i className="fa fa-star" style={{ color: "#f8e825" }}></i>
                {"  "}
                <strong>{review.title}</strong>
              </ListGroup.Item>
              <ListGroup.Item>{review.body}</ListGroup.Item>
            </ListGroup>
          ))}
      </Modal.Body>
    </Modal>
  );
};

export default ReviewsModal;

import React from "react";
import { Form, Spinner } from "react-bootstrap";
import NewAccount from "./NewAccount";
import ErrorHandle from "../ErrorHandle";
import { OTP_PATTERN } from "../../Constants/GlobalConstants";

const FormContent = (props) => {
  const {
    loadingGenerateOTP,
    errorGenerateOTP,
    messageGenerateOTP,
    loadingVerifyOTP,
    errorVerifyOTP,
    history,
    otp,
    setOtp,
    setFirstName,
    setLastName,
    setEmail,
  } = props;

  if (loadingGenerateOTP) {
    return <Spinner animation="border" />;
  }

  if (errorGenerateOTP && errorGenerateOTP?.status !== 429) {
    return (
      <ErrorHandle
        key="generate-otp"
        history={history}
        error={errorGenerateOTP}
      />
    );
  }

  if (!messageGenerateOTP) {
    return null;
  }

  const isExistingUser = messageGenerateOTP?.existingUser;

  return (
    <>
      <h4 className="LoginTitle verify">
        {isExistingUser ? "Verify OTP to login" : "New User - Create account"}
      </h4>
      {errorGenerateOTP && (
        <ErrorHandle
          key="generate-otp"
          history={history}
          error={errorGenerateOTP}
        />
      )}

      {!isExistingUser && (
        <NewAccount
          messageGenerateOTP={messageGenerateOTP}
          loadingVerifyOTP={loadingVerifyOTP}
          errorVerifyOTP={errorVerifyOTP}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
        />
      )}

      <Form.Group>
        <p
          className="text-heading-secondary p- m-0"
          style={{ fontStyle: "italic" }}
        >
          {messageGenerateOTP?.detail
            ? messageGenerateOTP.detail
            : "Enter OTP to verify your phone number:"}
        </p>
        <Form.Control
          as="input"
          type="text"
          placeholder="OTP"
          className="mt-1 otp-input"
          inputMode="numeric"
          autoComplete="one-time-code"
          textcontenttype="oneTimeCode"
          pattern={OTP_PATTERN}
          maxLength={4}
          value={otp}
          onChange={(e) => {
            console.log("otp val", e.target.value);
            setOtp(e.target.value);
          }}
          autoFocus={messageGenerateOTP.existingUser}
          required
        />

        <Form.Control.Feedback type="invalid">
          Enter a valid 4 digit OTP
        </Form.Control.Feedback>

        {errorVerifyOTP && isExistingUser && (
          <div style={{ marginTop: 10 }}>
            <ErrorHandle
              key="verify-otp"
              history={history}
              error={errorVerifyOTP}
            />
          </div>
        )}
      </Form.Group>
    </>
  );
};

export default FormContent;

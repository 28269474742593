import React from "react";
import { Button } from "react-bootstrap";

const AddressConfirm = (props) => {
  const { locationAddress, onConfirmLocation, showButton = true } = props;
  if (!locationAddress) {
    return null;
  }

  const [main_text, ...other] = locationAddress?.split(",");
  const secondary_text = other.length > 0 ? other?.join(", ") : null;
  return (
    <div
      className="address-container"
      style={{
        position: "sticky",
        bottom: "0px",
        display: "flex",
        flexDirection: "column",
        background: "#ececec",
        borderRadius: 10,
        padding: 20,
        paddingBottom: 10,
        zIndex: 50,
        margin: "auto",
        borderColor: "#616161",
        borderWidth: "0.5px",
        borderStyle: "solid",
      }}
    >
      <div>
        <span className="float-left text-heading-secondary">
          Delivering your order to
        </span>
      </div>
      <div className="d-flex justify-center align-items-center mt-2">
        <i className="fa fa-map-marker-alt fa-lg" style={{ marginRight: 10 }} />
        <div>
          <span className="two-lines mt-1">
            <strong>{main_text}</strong>{" "}
            {secondary_text ? `, ${secondary_text}` : ""}
          </span>
        </div>
      </div>
      {showButton && (
        <Button
          variant="primary"
          onClick={onConfirmLocation}
          className="mt-2"
          style={{
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          Confirm location and Continue
        </Button>
      )}
    </div>
  );
};

export default AddressConfirm;

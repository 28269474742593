import React from "react";
import { Image, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const SellerInfo = ({ product }) => {
  return (
    <ListGroup.Item style={{ borderRadius: 8 }}>
      <Link
        to={product.isNPI ? "/newproducts" : `/seller/${product.seller.id}/`}
        className="text-decoration-none  gap-2 d-flex align-items-center"
      >
        <Image
          width="50px"
          src={product.isNPI ? "/NewProducts.jpg" : product.seller.profileImage}
          style={{ borderRadius: 10 }}
        />
        <div className="p-0 m-0 " style={{ color: "black" }}>
          <p className="p-0 m-0">
            {product.isNPI ? "" : "See more items from"}
          </p>
          <p className="p-0 m-0 text-body-bold">
            {product.isNPI ? "New Product" : product.seller.storeName}
          </p>
        </div>
      </Link>
    </ListGroup.Item>
  );
};

export default SellerInfo;

import { Button, ListGroup } from "react-bootstrap";
import { POD_ORDER_VALUE_LIMIT } from "../../Constants/GlobalConstants";

const PaymentAction = ({
  isFinalAmountNull,
  cartItems,
  scriptLoading,
  placeOrder,
  isPodOrderDisabled,
  podOrder,
  isPodUnavailable,
  totalPrice,
}) => {
  const payOnlineDisabled = Boolean(cartItems.length === 0 || !scriptLoading);
  const isPodDisabled = isPodOrderDisabled();
  const podButtonText = isFinalAmountNull ? "Place Order" : "Pay on Delivery";
  const podUnavailable = isPodUnavailable();

  return (
    <div className="my-2 py-0">
      <ListGroup.Item>
        <h5 className="text-heading-secondary">Proceed to pay</h5>
      </ListGroup.Item>
      {!isFinalAmountNull && (
        <ListGroup.Item>
          <Button
            type="button"
            className="btn-block"
            disabled={payOnlineDisabled}
            onClick={() => placeOrder(10)}
          >
            Pay online
          </Button>
        </ListGroup.Item>
      )}
      <ListGroup.Item>
        <Button
          variant="warning"
          type="button"
          className="btn-block"
          disabled={isPodDisabled}
          onClick={podOrder}
        >
          {podButtonText}
        </Button>

        {podUnavailable && (
          <p>
            <i>POD unavailable</i>
          </p>
        )}
        {totalPrice > POD_ORDER_VALUE_LIMIT && (
          <span>
            <i>Not available</i>
          </span>
        )}
      </ListGroup.Item>
    </div>
  );
};

export default PaymentAction;

export const statusColor = (status) => {
  switch (status) {
    case "Placed":
      return "#a8fabf";
    case "Delivered":
      return "#d8d8d8";
    case "Packed":
      return "#ffe3c0";
    case "Cancelled":
      return "#f4d7d7";
    default:
      return "#d8d8d8";
  }
};

export const getSlotItems = (orders) => {
  const finalOrderItems = orders
    ?.reduce((acc, order) => {
      const items = order?.items;
      const orderItems = items?.reduce((accItems, item) => {
        accItems.push(item?.productName);
        return accItems;
      }, []);
      const strItems = orderItems?.join(", ");
      acc.push(strItems);
      return acc;
    }, [])
    .join(", ");
  return finalOrderItems;
};

export const getIsDelivered = (id, orders) => {
  let isDelivered = true;
  for (const order of orders) {
    const orderStatus = order.status;
    const isDone = [10, 70, 90].includes(orderStatus);
    if (!isDone) {
      isDelivered = false;
      break;
    }
  }
  return isDelivered;
};

export const getIsCancelled = (orders) => {
  let isCancelled = true;
  for (const o of orders) {
    const orderStatus = o.status;
    const isDone = [10, 90].includes(orderStatus);
    if (!isDone) {
      isCancelled = false;
      break;
    }
  }
  return isCancelled;
};

export const getSlotStatus = (isCancelled, isDelivered) => {
  if (isCancelled) {
    return {
      color: "rgb(254 242 242)",
      badgeText: "Cancelled",
      badgeColor: "primary",
    };
  }
  if (isDelivered) {
    return {
      color: "rgb(245,245,245)",
      badgeText: "Delivered",
      badgeColor: "success",
    };
  }
  return {
    color: "rgb(254 252 232)",
    badgeText: "Confirmed",
    badgeColor: "warning",
  };
};

export const getPaymentMethod = (methodCode) => {
  if (!methodCode) {
    return "";
  }
  if (methodCode === 10) {
    return "Online Payment";
  } else {
    return "Pay on Delivery";
  }
};

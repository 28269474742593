import React from "react";
import { getDateFromStamp } from "../../Utils/DateTimeFunctions";
import SlotOrders from "./SlotOrders";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const OrderItemNew = ({ order }) => {
  const history = useHistory();
  const { deliverySlots, id, method, netAmount, startedAt, discountAmount } =
    order;
  const dateStr = getDateFromStamp(startedAt);
  const isPodPayment = method === 20;

  const onPaymentClick = () => {
    history.push(`/order/${id}`);
  };

  const isCancelled = netAmount === 0 && discountAmount === 0;

  return (
    <div
      key={id}
      style={{
        border: "1px solid gray",
        borderRadius: 8,
        cursor: isCancelled ? "" : "pointer",
      }}
      className="px-2 py-2"
      onClick={isCancelled ? null : onPaymentClick}
    >
      <div
        className="d-flex justify-content-between mr-4"
        style={{ padding: 5 }}
      >
        <div>
          <div className="d-flex gap-3 p-0 m-0 text-heading-secondary">
            <p className="p-0 m-0">ID #{id} </p>
            {netAmount > 0 && (
              <>
                <p className="p-0 m-0">•</p>
                <p className="p-0 m-0">&#8377;{netAmount}</p>
              </>
            )}
          </div>
          <div className="d-flex gap-3 p-0 m-0">
            <p className="p-0 m-0" style={{ fontSize: "0.9rem" }}>
              Placed on: {dateStr}
            </p>
          </div>
        </div>
        {!isCancelled && (
          <div>
            <Button size="sm" variant="secondary">
              Details
            </Button>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          marginTop: 10,
        }}
      >
        {deliverySlots?.map((slot) => {
          return (
            <SlotOrders key={slot.id} slot={slot} isPodPayment={isPodPayment} />
          );
        })}
      </div>
    </div>
  );
};

export default OrderItemNew;

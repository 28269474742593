import MobileFooter from "../Components/MobileFooter";

function Contactscreen() {
  return (
    <>
      <h1>Contact Us</h1>

      <div>
        <h5>Address</h5>
        <p>JAXY TECHNOLOGIES PRIVATE LIMITED</p>
        <p>Regd. Off - BHIVE Workspace,</p>
        <p> No. 112, AKR Tech Park, Block B,</p>
        <p> 7th Mile, Hosur Road, Krishna</p>
        <p>Reddy Industrial Area, Bangalore -560068</p>
      </div>
      <h5>Email</h5>
      <p>
        <a href="mailto:info@homble.in">info@homble.in</a>
      </p>

      <h5>Phone No.</h5>
      <p>
        {" "}
        <a href="tel:8904293636"> +91 8904293636 </a>
      </p>
      <p>
        Facebook :
        <a href="https://www.facebook.com/Homble.in">
          {" "}
          https://www.facebook.com/Homble.in{" "}
        </a>
      </p>
      <MobileFooter />
    </>
  );
}

export default Contactscreen;

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import scrollToTop from "../Utils/ScrollToTop";
import { DISPLAY_ERROR_LIST } from "../Constants/GlobalConstants";
import Message from "./Message";
import { useEffect } from "react";
import { CART_CLEAR_ITEMS } from "../Constants/CartConstants";
import { USER_LOGOUT_RESET, USER_LOGOUT } from "../Constants/UserConstants";
import { useDispatch } from "react-redux";

function ErrorHandle({ history, error }) {
  const dispatch = useDispatch();

  const handleBack = () => {
    history.go(-1);
  };
  const handleHome = () => {
    history.push("/");
  };

  useEffect(() => {
    if (error && error.status === 401) {
      localStorage.clear();
      dispatch({ type: USER_LOGOUT_RESET });
      dispatch({ type: USER_LOGOUT });
      dispatch({ type: CART_CLEAR_ITEMS });
      history.push("/login");
    }
  }, [error, history]);

  return error && error.status !== 401 ? (
    error && DISPLAY_ERROR_LIST.includes(error.status) ? (
      <>
        {scrollToTop()}
        <Message variant="danger">
          {typeof error.data.detail === "string"
            ? error.data.detail
            : JSON.stringify(error.data.detail)}
        </Message>
      </>
    ) : (
      <>
        {scrollToTop()}
        {error.status === 404 ? (
          <Card key="notFoundError" style={{ maxWidth: "400px" }}>
            <Card.Img variant="top" src="404_pic.png" />
            <Card.Body>
              <Card.Title>404 Error - Page not found</Card.Title>
              <Card.Text>
                Page you requested could not be found. Please go back or return
                to home screen
              </Card.Text>
              <Button onClick={handleBack} variant="secondary">
                Back
              </Button>
              {"    "}
              <Button onClick={handleHome} variant="danger">
                Home
              </Button>
            </Card.Body>
          </Card>
        ) : error.status === 403 ? (
          <Card key="forbiddenError" style={{ maxWidth: "400px" }}>
            <Card.Img variant="top" src="403_pic.png" />
            <Card.Body>
              <Card.Title>403 Error</Card.Title>
              <Card.Text>
                You do not have access to the page you requested. Please contact
                your admin to get the access.
              </Card.Text>
              <Button onClick={handleBack} variant="secondary">
                Back
              </Button>
              {"    "}
              <Button onClick={handleHome} variant="danger">
                Home
              </Button>
            </Card.Body>
          </Card>
        ) : (
          <Card key="oopsError" style={{ maxWidth: "400px" }}>
            <Card.Img variant="top" src="oops_pic.png" />
            <Card.Body>
              <Card.Title>Oops! Something went wrong</Card.Title>
              <Card.Text>Please go back and try again.</Card.Text>
              <Button onClick={handleBack} variant="secondary">
                Back
              </Button>
            </Card.Body>
          </Card>
        )}
      </>
    )
  ) : null;
}

export default ErrorHandle;

import React from "react";
import { extractAddressParts } from "../../Utils/addressHelpers";
import { Modal, Row, Col } from "react-bootstrap";
import FullAddressForm from "./FullAddressForm";

const FullAddress = (props) => {
  const { addressObj, showModal, modalCancelHandler } = props;

  const { mainAddress, subAddress } = extractAddressParts(addressObj);
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return (
    <Modal
      show={showModal}
      onHide={modalCancelHandler}
      centered
      dialogClassName={screenWidth > 1000 ? "modal-fullscreen" : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter complete address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <div
              className={screenWidth > 1000 ? "address-style" : ""}
              style={{
                fontSize: 15,
                marginBottom: 10,
                textAlign: "left",
                marginTop: 0,
              }}
            >
              <p
                className="text-heading-secondary "
                style={{ padding: 0, margin: 0 }}
              >
                <i
                  className="fa fa-map-marker-alt fa-lg"
                  style={{ marginRight: 10 }}
                />
                {mainAddress}
              </p>
              <p
                className="text-body-light "
                style={{ padding: 0, margin: 0, lineHeight: 1.3 }}
              >
                {subAddress}
              </p>
            </div>
          </Col>
          <Col md={9}>
            <FullAddressForm
              screenWidth={screenWidth}
              addressObj={addressObj}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default FullAddress;

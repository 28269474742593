import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { MOBILE_PATTERN } from "../../Constants/GlobalConstants";
import { useAddressForm } from "../../CustomHooks/useAddressForm";
import { useSelector, useDispatch } from "react-redux";

const FullAddressForm = ({ screenWidth, addressObj, isLocation = false }) => {
  const dispatch = useDispatch();

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  const { formState, handleChange, handleSubmit, valid } = useAddressForm(
    addressObj,
    login,
    isLocation,
    dispatch
  );

  const isNotPremise = !addressObj?.isPremise && !isLocation;
  const showPinCode = !isLocation && !formState?.postcode;

  return (
    <div
      style={{
        width: screenWidth > 1000 ? (isLocation ? "100%" : "80%") : "100%",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Form
        id="addressCreate"
        noValidate
        validated={formState.validated}
        onSubmit={handleSubmit}
        style={{ fontSize: 14 }}
      >
        <FloatingLabel controlId="door" label="DOOR /FLAT /HOUSE NO. ">
          <Form.Control
            name="doorNum"
            as="input"
            type="text"
            placeholder="Enter door no."
            onChange={handleChange}
            value={formState.doorNum}
            required
            minLength="2"
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid door number
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel
          controlId="tower"
          label={
            isNotPremise ? "APARTMENT /BUILDING NAME" : "TOWER /BLOCK NAME"
          }
        >
          <Form.Control
            name="tower"
            as="input"
            type="text"
            placeholder="Enter tower name"
            value={formState.tower}
            onChange={handleChange}
          />
        </FloatingLabel>

        {/*NOT IMPORTANT - ONLY NOT A PREMISE*/}
        {isNotPremise && (
          <FloatingLabel controlId="road" label={"ROAD NAME"}>
            <Form.Control
              name="road"
              as="input"
              type="text"
              placeholder="Enter road name"
              value={formState.road}
              onChange={handleChange}
            />
          </FloatingLabel>
        )}
        {/*NOT IMPORTANT - ONLY NOT A PREMISE*/}
        {isNotPremise && (
          <FloatingLabel controlId="area" label={"AREA /LOCALITY NAME"}>
            <Form.Control
              name="area"
              as="input"
              type="text"
              placeholder="Enter area name"
              value={formState.area}
              onChange={handleChange}
            />
          </FloatingLabel>
        )}
        {/*NOT IMPORTANT - ONLY IF NO PINCODE ALREADY*/}
        {showPinCode && (
          <FloatingLabel controlId="postcode" label={"PIN CODE"}>
            <Form.Control
              name="postcode"
              as="input"
              type="text"
              placeholder="Enter Pin Code"
              value={formState.postcode}
              onChange={handleChange}
              required
            />
          </FloatingLabel>
        )}

        <FloatingLabel controlId="name" label="RECEIVER NAME">
          <Form.Control
            name="name"
            type="text"
            value={formState.name}
            placeholder="Enter your name"
            onChange={handleChange}
            minLength="3"
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid first name (at least 3 characters)
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel controlId="mobile" label="MOBILE NO.">
          <Form.Control
            name="mobile"
            as="input"
            type="text"
            placeholder="Mobile No."
            value={formState.mobile}
            onChange={handleChange}
            pattern={MOBILE_PATTERN}
            required
          />
          <Form.Text className="text-muted">
            This contact number will be used for delivery
          </Form.Text>
          <Form.Control.Feedback type="invalid">
            Please enter a valid 10-digit mobile number
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel controlId="label" label="ADDRESS LABEL - Eg: Home /Work">
          <Form.Control
            name="label"
            as="input"
            type="text"
            placeholder="Enter label name"
            value={formState.label}
            onChange={handleChange}
          />
        </FloatingLabel>
        <Form.Check
          name="isDefault"
          type="checkbox"
          id="check-address-default"
          label="Make this my default address"
          onChange={handleChange}
          className="mb-3"
        />
        <Button type="submit" variant="primary" style={{ width: "100%" }}>
          Submit Address
        </Button>
      </Form>
      <br />
    </div>
  );
};

export default FullAddressForm;

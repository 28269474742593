import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../Actions/UserActions";
import { USER_LOGOUT_RESET } from "../../Constants/UserConstants";
import { colors } from "../../Constants/colors";
import SearchBoxMain from "../SearchBoxMain";

function MobileNavbar(props) {
  const NAVBAR_CHANGE_POINT = 100;
  const dispatch = useDispatch();
  const history = useHistory();
  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);

  const { login } = userVerifyOTP;
  const userLogout = useSelector((state) => state.userLogout);
  const { loading, success, error } = userLogout;

  //Navbar toggle in scrolling
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isSticky, setIsSticky] = useState(false);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: USER_LOGOUT_RESET });
      history.push("/");
    }
  }, [success]);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;

    if (prevScrollPos < currentScrollPos && !isSticky) {
      currentScrollPos > NAVBAR_CHANGE_POINT && setIsSticky(true);
    } else if (prevScrollPos > currentScrollPos && isSticky) {
      currentScrollPos <= NAVBAR_CHANGE_POINT && setIsSticky(false);
    }
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isSticky, handleScroll]);

  return (
    <>
      {!isSticky ? (
        <Navbar
          key="top-bar"
          className="m-0 d-flex justify-content-between align-items-center border-0 p-2"
          collapseOnSelect
          fixed="top"
          bg="white"
          variant="dark"
          expand="lg"
        >
          <Navbar.Brand className="p-0 mb-2 mt-2">
            <LinkContainer to="/" className="p-0 ms-1 my-0">
              <img
                alt="Homble | Whole Foods"
                src="logo_green.png"
                width="110"
                className="p-0 m-0"
              />
            </LinkContainer>
          </Navbar.Brand>

          {login ? (
            <NavDropdown
              title={
                <i
                  className="fas fa-user-circle fa-lg dropdown"
                  style={{ color: colors.primary }}
                ></i>
              }
              id="user-menu"
            >
              <NavDropdown.Item>
                <LinkContainer to="/myorders">
                  <Nav.Link as="div">My Orders</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>

              <NavDropdown.Item>
                <LinkContainer to="/profile">
                  <Nav.Link as="div">My Profile</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>

              <NavDropdown.Item>
                <LinkContainer to="/help">
                  <Nav.Link as="div">Help</Nav.Link>
                </LinkContainer>
              </NavDropdown.Item>

              <NavDropdown.Item onClick={handleLogout}>
                <Nav.Link as="div">Logout</Nav.Link>
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <LinkContainer to="/login">
              <Nav.Link as="div">
                <i
                  className="fas fa-user fa-lg my-0 py-0 "
                  style={{ color: colors.primary }}
                >
                  <span style={{ color: colors.primary }}></span>
                </i>
              </Nav.Link>
            </LinkContainer>
          )}

          <LinkContainer to="/cart">
            <Nav.Link as="div" className="mt-2 pb-0 mb-0 ms-2 me-0 px-0">
              <i
                className="fas fa-shopping-cart fa-lg"
                style={{ color: colors.primary }}
              ></i>
              <span className="cart-basketMobile fa-stack fa-sm">
                <i
                  style={{ color: "#418deb" }}
                  className="fa fa-circle fa-stack-2x"
                ></i>
                <strong className="fa-stack-1x text-white">
                  {props.cartTotal}
                </strong>
              </span>
            </Nav.Link>
          </LinkContainer>

          <SearchBoxMain width={8} isMobile={true} />
        </Navbar>
      ) : (
        <Navbar
          key="sticky-bar"
          className="p-0 d-flex justify-content-between align-items-center border-0 my-auto pt-2 pb-1"
          fixed="top"
          bg="white"
          variant="dark"
        >
          <SearchBoxMain width={12} isMobile={true} />

          <LinkContainer to="/cart">
            <Nav.Link as="div" className="mt-2 pb-0 mb-0 ms-2 me-0 px-0">
              <div className="pt-1">
                <i
                  className="fas fa-shopping-cart fa-lg"
                  style={{ color: colors.primary }}
                ></i>
                <span className="cart-basketMobile fa-stack fa-sm">
                  <i
                    style={{ color: "#418deb" }}
                    className="fa fa-circle fa-stack-2x"
                  ></i>
                  <strong className="fa-stack-1x text-white">
                    {props.cartTotal}
                  </strong>
                </span>
              </div>
            </Nav.Link>
          </LinkContainer>
        </Navbar>
      )}
    </>
  );
}

export default MobileNavbar;

import { useState } from "react";
import { MOBILE_REG_EX } from "../Constants/GlobalConstants";
import {
  addApartmentShippingAddress,
  addShippingAddress,
} from "../Actions/CartActions";
import { trackEvent } from "../Config/amplitude";

export const useAddressForm = (
  addressObj,
  initialData,
  isLocation,
  dispatch
) => {
  const {
    buildingName = "",
    streetName = "",
    areaName = "",
    postcode = "",
    description = "",
    coordinates = {},
    isPremise = "",
    locationId = null,
  } = addressObj;

  const [formState, setFormState] = useState({
    doorNum: "",
    buildingDetails: isPremise ? buildingName : "",
    tower: "",
    road: streetName,
    area: areaName,
    name: `${initialData?.firstName} ${initialData?.lastName}` || "",
    mobile: initialData?.mobile || 0,
    label: "Home",
    isDefault: false,
    validated: false,
    isPremise: isPremise || false,
    postcode: postcode,
  });

  let valid = false;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const checkForm = () => {
    if (
      formState.name.length > 0 &&
      MOBILE_REG_EX.test(formState.mobile) === true
    ) {
      if (formState.doorNum.length > 0 && formState.label.length > 0) {
        valid = true;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setFormState((prevState) => ({ ...prevState, validated: true }));
    checkForm();

    if (isLocation) {
      dispatch(
        addApartmentShippingAddress(locationId, {
          name: formState.name,
          mobile: formState.mobile,
          doorNum: formState.doorNum,
          buildingDetails: formState.tower,
          label: formState.label,
          isDefault: formState.isDefault,
        })
      );
    } else {
      valid &&
        dispatch(
          addShippingAddress({
            name: formState.name,
            mobile: formState.mobile,
            doorNum: formState.doorNum,
            buildingDetails:
              formState?.tower + ", " + formState?.buildingDetails,
            road: formState.road,
            area: formState.area,
            city: "BANGALORE",
            pincode: formState.postcode,
            label: formState.label,
            isDefault: formState.isDefault,
            latitude: Number(coordinates?.lat.toFixed(6)),
            longitude: Number(coordinates?.lng).toFixed(6),
            isServiceable: true,
          })
        );
    }
    trackEvent("Created Address success", {
      isLocation: isLocation ? true : false,
    });
  };

  return {
    formState,
    handleChange,
    handleSubmit,
    valid,
  };
};

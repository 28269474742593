import React from "react";
import SlotDetails from "./SlotDetails";
import BillDetails from "./BillDetails";
import LogisticDetails from "./LogisticDetails";
import { Col, Row } from "react-bootstrap";

const OrderDetails = ({ order }) => {
  const {
    address,
    deliverySlots,
    itemsTotal,
    discountAmount,
    grossAmount,
    deliveryFee,
    id,
    method,
    netAmount,
    startedAt,
  } = order;
  return (
    <div className="d-flex flex-column">
      <h4 className="text-heading-primary">Order Summary</h4>
      <div className="d-flex flex-column gap-4 mt-2">
        <LogisticDetails logisticDetails={{ address, startedAt, method, id }} />
        <Row>
          <Col xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                marginBottom: 20,
              }}
            >
              {deliverySlots?.map((slot) => {
                return <SlotDetails key={slot.id} slot={slot} paymentId={id} />;
              })}
            </div>
          </Col>
          <Col xs={12} md={6}>
            <BillDetails
              amounts={{
                itemsTotal,
                discountAmount,
                netAmount,
                grossAmount,
                deliveryFee,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderDetails;

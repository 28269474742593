/**
 * ALL POLYGONS ARE WITH RESPECT TO KUDLU HUB
 * Big - Overall serving area
 * Medium - 4 Km radius with On demand serving area
 * Small - 2 Km radius with On demand serving area
 */

const POLYGON_PATH_BIG = [
  { lat: 12.9740323, lng: 77.6311174 },
  { lat: 12.9790086, lng: 77.6407304 },
  { lat: 12.969223, lng: 77.6418891 },
  { lat: 12.9596462, lng: 77.6434334 },
  { lat: 12.9434193, lng: 77.6401708 },
  { lat: 12.9386021, lng: 77.6354948 },
  { lat: 12.9350333, lng: 77.6479115 },
  { lat: 12.9307871, lng: 77.6600538 },
  { lat: 12.9388596, lng: 77.6697983 },
  { lat: 12.9483012, lng: 77.6778637 },
  { lat: 12.9497351, lng: 77.6886903 },
  { lat: 12.9486775, lng: 77.6992408 },
  { lat: 12.9480191, lng: 77.7042878 },
  { lat: 12.9415672, lng: 77.7057854 },
  { lat: 12.937019, lng: 77.708236 },
  { lat: 12.9341327, lng: 77.7112248 },
  { lat: 12.9298244, lng: 77.7152312 },
  { lat: 12.9255163, lng: 77.7160252 },
  { lat: 12.9246795, lng: 77.7166048 },
  { lat: 12.9212595, lng: 77.7150873 },
  { lat: 12.916021, lng: 77.712485 },
  { lat: 12.9133956, lng: 77.7118685 },
  { lat: 12.9105511, lng: 77.7109244 },
  { lat: 12.9057727, lng: 77.7090092 },
  { lat: 12.9016829, lng: 77.7088216 },
  { lat: 12.8930562, lng: 77.7089226 },
  { lat: 12.8830159, lng: 77.7109825 },
  { lat: 12.8783306, lng: 77.7089225 },
  { lat: 12.8746486, lng: 77.7050876 },
  { lat: 12.872306, lng: 77.7030859 },
  { lat: 12.8661141, lng: 77.7018846 },
  { lat: 12.8592527, lng: 77.7037727 },
  { lat: 12.8552798, lng: 77.6998873 },
  { lat: 12.8515543, lng: 77.6955331 },
  { lat: 12.8472026, lng: 77.6929581 },
  { lat: 12.8385416, lng: 77.7006399 },
  { lat: 12.8289597, lng: 77.7120126 },
  { lat: 12.8323072, lng: 77.6935161 },
  { lat: 12.8396803, lng: 77.6790383 },
  { lat: 12.8368262, lng: 77.6735606 },
  { lat: 12.8324833, lng: 77.6717427 },
  { lat: 12.826639, lng: 77.6698573 },
  { lat: 12.8238634, lng: 77.6686957 },
  { lat: 12.8196491, lng: 77.6674987 },
  { lat: 12.8195026, lng: 77.6626876 },
  { lat: 12.8211364, lng: 77.6538391 },
  { lat: 12.8254882, lng: 77.6456852 },
  { lat: 12.8297964, lng: 77.6392987 },
  { lat: 12.8321398, lng: 77.6323622 },
  { lat: 12.8366588, lng: 77.6265958 },
  { lat: 12.8398388, lng: 77.6220623 },
  { lat: 12.8448598, lng: 77.6170686 },
  { lat: 12.8493789, lng: 77.6122778 },
  { lat: 12.8482071, lng: 77.6035231 },
  { lat: 12.8505501, lng: 77.59391 },
  { lat: 12.8533708, lng: 77.5871137 },
  { lat: 12.8528687, lng: 77.5812772 },
  { lat: 12.8539566, lng: 77.5753549 },
  { lat: 12.8556145, lng: 77.569768 },
  { lat: 12.8617805, lng: 77.5634244 },
  { lat: 12.8733937, lng: 77.566616 },
  { lat: 12.8796063, lng: 77.5673884 },
  { lat: 12.8833265, lng: 77.5650552 },
  { lat: 12.885777, lng: 77.5620719 },
  { lat: 12.8906298, lng: 77.565157 },
  { lat: 12.8957512, lng: 77.5699905 },
  { lat: 12.9003109, lng: 77.5725225 },
  { lat: 12.9036836, lng: 77.5725575 },
  { lat: 12.9084085, lng: 77.5730153 },
  { lat: 12.9135536, lng: 77.5727956 },
  { lat: 12.9167682, lng: 77.5752531 },
  { lat: 12.9210765, lng: 77.5781285 },
  { lat: 12.9260191, lng: 77.5821511 },
  { lat: 12.9264723, lng: 77.5893293 },
  { lat: 12.926856, lng: 77.596485 },
  { lat: 12.9309061, lng: 77.5987708 },
  { lat: 12.9354722, lng: 77.6018064 },
  { lat: 12.9361761, lng: 77.6060664 },
  { lat: 12.9353396, lng: 77.6096712 },
  { lat: 12.9349214, lng: 77.6137911 },
  { lat: 12.9366782, lng: 77.6161084 },
  { lat: 12.9395223, lng: 77.6191984 },
  { lat: 12.9470508, lng: 77.6174818 },
  { lat: 12.9546627, lng: 77.6159368 },
  { lat: 12.9611871, lng: 77.6191983 },
  { lat: 12.9697185, lng: 77.6232324 },
];

const POLYGON_PATH_MEDIUM = [
  { lat: 12.8911802, lng: 77.641779 },
  { lat: 12.9214093, lng: 77.6550403 },
  { lat: 12.919527, lng: 77.6610484 },
  { lat: 12.9167561, lng: 77.6661843 },
  { lat: 12.9159614, lng: 77.6697463 },
  { lat: 12.915136, lng: 77.673383 },
  { lat: 12.9114019, lng: 77.6747134 },
  { lat: 12.906675, lng: 77.6765158 },
  { lat: 12.902952, lng: 77.6779749 },
  { lat: 12.8973884, lng: 77.679949 },
  { lat: 12.8922413, lng: 77.6785773 },
  { lat: 12.8847948, lng: 77.6766891 },
  { lat: 12.8803184, lng: 77.6739425 },
  { lat: 12.87534, lng: 77.6706809 },
  { lat: 12.868111, lng: 77.665655 },
  { lat: 12.865186, lng: 77.654625 },
  { lat: 12.8673468, lng: 77.6470537 },
  { lat: 12.8695224, lng: 77.6373441 },
  { lat: 12.8714887, lng: 77.6271303 },
  { lat: 12.87289, lng: 77.619346 },
  { lat: 12.87289, lng: 77.619346 },
  { lat: 12.8796095, lng: 77.6163551 },
  { lat: 12.8750076, lng: 77.6088021 },
  { lat: 12.885409, lng: 77.61371 },
  { lat: 12.8929966, lng: 77.613909 },
  { lat: 12.898452, lng: 77.614224 },
  { lat: 12.9028692, lng: 77.6174384 },
  { lat: 12.9074288, lng: 77.6204425 },
  { lat: 12.9130341, lng: 77.622202 },
  { lat: 12.916893, lng: 77.62347 },
  { lat: 12.9161249, lng: 77.6296693 },
  { lat: 12.9149955, lng: 77.6409131 },
  { lat: 12.9214372, lng: 77.6439601 },
  { lat: 12.9216045, lng: 77.6495391 },
];
const POLYGON_PATH_SMALL = [
  { lat: 12.909108, lng: 77.632846 },
  { lat: 12.909086, lng: 77.644141 },
  { lat: 12.906172, lng: 77.653819 },
  { lat: 12.9010616, lng: 77.6590626 },
  { lat: 12.8957907, lng: 77.6646416 },
  { lat: 12.8889269, lng: 77.6626246 },
  { lat: 12.880911, lng: 77.660643 },
  { lat: 12.877605, lng: 77.6564307 },
  { lat: 12.874197, lng: 77.652262 },
  { lat: 12.8713, lng: 77.644882 },
  { lat: 12.8744254, lng: 77.6355094 },
  { lat: 12.878034, lng: 77.626094 },
  { lat: 12.8841002, lng: 77.6222057 },
  { lat: 12.890519, lng: 77.618198 },
  { lat: 12.8979672, lng: 77.6211029 },
  { lat: 12.904746, lng: 77.625038 },
];

export const polygon = {
  bg: POLYGON_PATH_BIG,
  md: POLYGON_PATH_MEDIUM,
  sm: POLYGON_PATH_SMALL,
};

import React from "react";

const AddressContainer = ({ delAddress }) => {
  if (!delAddress) {
    return <p>No address found</p>;
  }
  return (
    <div>
      <h6 className="text-body-bold">Deliver to:</h6>
      <p>
        <strong>{delAddress?.name}</strong>
        <br />
        {"  "}
        {delAddress?.doorNum}, {delAddress?.buildingDetails}
        {"  "}
        {delAddress?.road}, {delAddress?.area},{"  "}
        {delAddress?.city}, {delAddress?.pincode}
        <br /> Ph No.: <strong>{delAddress?.mobile}</strong>
      </p>
    </div>
  );
};

export default AddressContainer;

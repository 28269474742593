import React from "react";
import { Button, Modal } from "react-bootstrap";

const LocationErrorModal = (props) => {
  const { showModal, onSecondaryHandler } = props;

  return (
    <Modal show={showModal} onHide={onSecondaryHandler} centered>
      <Modal.Header closeButton className="brand-title">
        <Modal.Title
          style={{ paddingLeft: 5 }}
        >{`Unable to fetch Current Location`}</Modal.Title>
      </Modal.Header>
      {
        <Modal.Body>
          <p>
            Please enable location sharing for us to provide better delivery
            experience
          </p>
        </Modal.Body>
      }

      <Modal.Footer>
        <Button onClick={onSecondaryHandler} variant="danger">
          Got it
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationErrorModal;

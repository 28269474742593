import React from "react";
import { Placeholder } from "react-bootstrap";

const BannerItemSkeleton = ({ width }) => {
  const height = width <= 600 ? 140 : 170;
  return (
    <>
      <Placeholder
        className="rounded-4 text-gray-100 mx-auto d-block"
        style={{ height: height }}
        animation="glow"
      >
        <Placeholder
          xs={12}
          className="rounded-4 text-gray-100"
          style={{ height: height }}
        />
      </Placeholder>
    </>
  );
};

export default BannerItemSkeleton;

import React from "react";
import { getStringSlot } from "../../Utils/SlotFunction";
import { Badge, Button, Card } from "react-bootstrap";
import {
  getIsCancelled,
  getIsDelivered,
  getSlotItems,
  getSlotStatus,
} from "../../Utils/myOrdersHelpers";
import { useHistory } from "react-router-dom";

const SlotOrders = ({ slot }) => {
  const { id, orders } = slot;
  const history = useHistory();

  const slotStr =
    slot.type === 100 ? "30 min delivery" : getStringSlot(slot, true);
  const pendingPod = slot?.podCollection?.toCollect;
  const slotItemsStr = getSlotItems(orders);
  const isDelivered = getIsDelivered(id, orders);
  const isCancelled = getIsCancelled(orders);

  const { color, badgeText, badgeColor } = getSlotStatus(
    isCancelled,
    isDelivered
  );

  const onReportIssueHandler = (event) => {
    event.stopPropagation();
    if (isDelivered) {
      history.push(`/help/${orders[0]?.id}`);
    } else {
      history.push(`/help`);
    }
  };

  return (
    <Card
      key={id}
      variant="flush"
      style={{
        border: "1px solid lightGray",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.9rem",
      }}
      className="gap-2"
    >
      <Card.Header
        style={{
          backgroundColor: color,
        }}
      >
        <div className="d-flex justify-content-between">
          <p className="p-0 m-0 text-heading-secondary">{slotStr}</p>
          {!isCancelled && (
            <div>
              <Button size="sm" variant="danger" onClick={onReportIssueHandler}>
                Report Issue
              </Button>
            </div>
          )}
        </div>

        <div className="d-flex gap-3">
          <Badge bg={badgeColor}>
            <p
              style={{
                margin: 0,
                padding: 0,
                color: "white",
                fontSize: "0.8rem",
              }}
              className="text-heading-secondary"
            >
              {badgeText}
            </p>
          </Badge>
          {pendingPod > 0 && !slot?.podCollection?.isCompleted && (
            <p className="p-0 m-0 text-body-bold">
              POD Pending &#8377;{pendingPod}
            </p>
          )}
        </div>
      </Card.Header>
      <Card.Body className="py-1 my-0">
        <p
          className="p-0 m-0 text-truncate"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            whiteSpace: "normal",
          }}
        >
          ({slotItemsStr})
        </p>
      </Card.Body>
    </Card>
  );
};

export default SlotOrders;

import React from "react";
import { Card } from "react-bootstrap";
import CategoryCardSkeleton from "./CategoryCardSkeleton";

const getCardWidthAndFont = (screenWidth) => {
  if (screenWidth < 720) {
    return { width: "110px", fontSize: "0.9rem" };
  } else if (screenWidth < 1400) {
    return { width: "70px", fontSize: "0.9rem" };
  } else {
    return { width: "80px", fontSize: "0.9rem" };
  }
};

const CategoryCard = (props) => {
  const { onClickHandler, image, name, screenWidth, loading = false } = props;
  const { width, fontSize } = getCardWidthAndFont(screenWidth);
  if (loading) {
    return <CategoryCardSkeleton width={width} fontSize={fontSize} />;
  }

  return (
    <Card
      onClick={onClickHandler}
      style={{
        padding: 0,
        margin: 0,
        maxWidth: width,
      }}
      className="Category mx-auto border-0"
    >
      <Card.Img
        className="rounded-5"
        style={{
          borderRadius: "10%",
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        variant="top"
        src={image}
      />
      <Card.Body className="p-0 text-center mt-3">
        <p
          className="text-body-bold"
          style={{ fontSize: fontSize, lineHeight: 1.3 }}
        >
          {name}
        </p>
      </Card.Body>
    </Card>
  );
};

export default CategoryCard;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../Banner";
import Category from "./Category";
import ProductsList from "./ProductsList";
import { useWindowWidth } from "../../CustomHooks/useWindowWidth";
import RecContainer from "../Recommendations/RecContainer";
import { colors } from "../../Constants/colors";
import {
  getCollectionItemsByType,
  getPrevOrderedProducts,
} from "../../Actions/ProductActions";
import Collections from "./Collections";
import CollectionsMadeType from "./CollectionsMadeType";

const MainContainer = ({ history, products, loading, error, setShowToast }) => {
  const width = useWindowWidth();
  const dispatch = useDispatch();

  const { prevProducts, loading: loadingPrevProducts } = useSelector(
    (state) => state.prevProducts
  );
  const { collectionData, loading: loadingNewlyLaunched } = useSelector(
    (state) => state.collectionItemByType
  );

  const onItemClick = (id) => {
    history.push(`/product/${id}`);
  };

  useEffect(() => {
    dispatch(getPrevOrderedProducts());
    dispatch(getCollectionItemsByType("new_launched"));
  }, []);

  return (
    <>
      <Banner width={width} />
      <div className="d-lg-none">
        {(loadingPrevProducts || prevProducts) && (
          <RecContainer
            rec={prevProducts?.products}
            heading={"Previously ordered"}
            screen="PREV_ITEM"
            styles={{
              background: colors.primary_green.LIGHTEST,
              border: "1px solid #d3d3d3",
            }}
            innerClasses="scroll-container"
            onItemClick={onItemClick}
            loading={loadingPrevProducts}
          />
        )}
      </div>

      <div className="pt-4">
        <h4 className="text-heading-primary">Shop by Category</h4>
        <Category history={history} />
      </div>

      {(loadingNewlyLaunched || collectionData?.id) && (
        <div>
          <RecContainer
            rec={collectionData?.products}
            heading={collectionData?.name}
            screen="NEW_LAUNCHED"
            styles={{
              background: colors.lightBlue,
              border: "1px solid lightGray",
            }}
            innerClasses="scroll-container"
            onItemClick={onItemClick}
            loading={loadingNewlyLaunched}
          />
        </div>
      )}

      {width < 760 ? <CollectionsMadeType /> : <Collections />}
      <>
        <h4 className="mt-4 mb-2 text-heading-primary">All Products</h4>
        <ProductsList
          history={history}
          loading={loading}
          error={error}
          products={products}
          keyword={null}
          setShowToast={setShowToast}
          screen={"HOME"}
        />
      </>
    </>
  );
};

export default MainContainer;

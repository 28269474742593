import React from "react";
import { Image, Button, Table } from "react-bootstrap";
import { noListItems } from "../Constants/noList";

const noList = [
  "Preservatives",
  "Artificial Colours",
  "Artificial Flavours",
  "Artificial Essences",
  "Artificial Sweeteners",
  "MSG (Monosodium glutamate)",
  "Dalda/Vanaspati/Hydrogenated Oils",
  "Palm Oil",
  "Soda (Only allowed in baking powder)",
  "Glucose Syrup",
  "Refined Corn Starch",
  "Maltodextrin",
  "Oligofructose syrup",
  "Nature Identical Flavors",
  "Acidity Regulators",
  "Emulsifiers",
  "Papad Khar (chemical used in papads)",
  "Adulterants",
];

const BANNER_BASE_URL =
  "https://myjaxy-prod.s3.ap-south-1.amazonaws.com/banners";

const NoList = ({ history }) => {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const screenHeight = width > 900 ? 170 : width > 600 ? 150 : 150;
  const posterURL =
    width > 900
      ? `${BANNER_BASE_URL}/42d84a4a75c0401491ac806d93a1feb4_Homble_Main_Banner.png`
      : width > 600
        ? `${BANNER_BASE_URL}/2e36008d95f84ff8a620d59c575f1f8b_Homble_Main_Banner.png`
        : `${BANNER_BASE_URL}/ed45ceda35f84441b4acfca359099754_Homble_Main_Banner.png`;

  const noListImage = {
    url: posterURL,
    height: screenHeight,
  };

  const handleHome = () => {
    history.push("/");
  };

  const headers = Object.keys(noListItems[0]);

  return (
    <>
      <Image
        className="banner-image mx-auto d-block"
        style={{
          height: noListImage.height,
          cursor: "pointer",
        }}
        src={noListImage.url}
        alt="Real Food with Real Ingredients"
        fluid
      />
      <Button variant="secondary" onClick={handleHome} className="mt-2">
        Back Home
      </Button>
      <h5 className="mt-2 theme-red-tile">Ingredients Not Allowed:</h5>

      <Table responsive>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {noListItems.map((row, index) => (
            <tr key={index}>
              {headers.map((header) => (
                <td key={header}>{row[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default NoList;

import { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { useDispatch } from "react-redux";
import { analytics } from "../../Config/firebase";
import { searchEvent } from "../../Constants/AnalyticsEvents";
import { PRODUCT_LIST_RESET } from "../../Constants/ProductConstants";
import { Button, Form } from "react-bootstrap";

const SearchBar = () => {
  let history = useHistory();
  const [keyword, setKeyword] = useState("");
  const { pathname } = useLocation();
  const timeoutRef = useRef(null);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const performSearch = (searchTerm) => {
    if (searchTerm.length >= 2) {
      history.push(`/search?keyword=${searchTerm.replace(/ /g, "%20")}`);
      logEvent(analytics, searchEvent.SEARCH_SUBMITTED, {
        keyword: searchTerm,
      });
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (keyword?.length === 1) {
      dispatch({ type: PRODUCT_LIST_RESET });
      history.push(`/search`);
    }

    if (keyword.length >= 2) {
      timeoutRef.current = setTimeout(() => {
        performSearch(keyword);
      }, 300);
    }
  }, [keyword]);

  const handleClear = (e) => {
    console.log("running handle clear");
    e.preventDefault();
    setKeyword("");
    history.push(`/search`);
    inputRef.current?.focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("running submit");
    if (keyword) {
      history.push(`/search?keyword=${keyword.replace(/ /g, "%20")}`);
      logEvent(analytics, searchEvent.SEARCH_SUBMITTED, {
        keyword: keyword,
      });
      setKeyword("");
    }
  };
  const onSearchFocus = () => {
    if (!pathname?.includes("/search")) {
      history.push(`/search`);
    }
    inputRef.current?.focus();
  };

  return (
    <div className="d-flex" style={{ width: "70%" }}>
      <Form
        onSubmit={handleSubmit}
        className="search-form d-flex border-0"
        action="."
        style={{ width: "100%" }}
      >
        <Form.Control
          type="search"
          className="search-input py-2"
          style={{
            boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
          }}
          name="search"
          id="searchbar"
          aria-label="Search"
          autoFocus={true}
          value={keyword}
          placeholder="Search for fruits, batters and more"
          onChange={(e) => {
            setKeyword(e.target.value);
            inputRef.current?.focus();
          }}
          onFocus={onSearchFocus}
          ref={inputRef}
        />
        <Button type="button" className="search-button" onClick={handleClear}>
          <i
            className={`fas ${keyword ? "fa-times" : "fa-search"} fa-md`}
            style={{ color: "#333333" }}
          ></i>
        </Button>
      </Form>
    </div>
  );
};

export default SearchBar;

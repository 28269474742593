//This slices a string to a limiting size & adds two dots if limiting the size
export const sliceToSize = (fullname, limit) => {
  let lInit = fullname ? fullname.length : 0;
  let lFinal = lInit > limit ? limit - 2 : lInit;
  return `${fullname.slice(0, lFinal)}${lFinal < lInit ? "..." : ""}`;
};

export function convertNumToWords(num) {
  const numbersToWords = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
  };
  if (!num) {
    return "";
  }
  return numbersToWords[num]
    ? numbersToWords[num].toUpperCase()
    : num.toString();
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

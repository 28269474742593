import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Image } from "react-bootstrap";
import FlashMessage from "../Components/FlashMessage";
import Loader from "../Components/Loader";
import Message from "../Components/Message";
import Product from "../Components/ProductCard/Product";
import { getNewProductList } from "../Actions/ProductActions";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";

function NewProductsscreen() {
  const [showCart, setShowCart] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [addOrRemove, setAddorRemove] = useState("added to");
  const dispatch = useDispatch();
  const { newProducts, loading, error } = useSelector(
    (state) => state.newProductList
  );

  useDocumentTitle(`Homble | New Products`);
  useEffect(() => {
    dispatch(getNewProductList());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Message variant="danger">{error}</Message>;
  }

  return (
    <>
      <Row>
        <Col xs={4}>
          <Image
            src="/NewProducts.jpg"
            width="100px"
            alt="New Products"
            className="rounded-3 border border-1"
          />
        </Col>
        <Col xs={8}>
          <h4>New Products</h4>
          <p className="my-2">
            Homble continuously strives to bring you new products from first
            time makers or new introductions from our existing makers.
          </p>
        </Col>
      </Row>
      <FlashMessage
        show={showToast}
        message={"Sorry! No more stock"}
        setShow={setShowToast}
        background={"primary"}
        position="bottom-center"
      />
      <Row>
        {newProducts?.NPIProducts?.length > 0 && (
          <>
            <h3>New Products Menu</h3>
            {newProducts.NPIProducts.map((product) => (
              <Col key={product.id} xs={12} sm={8} md={6} lg={6} xl={4}>
                <Product
                  product={product}
                  cartQty={1}
                  setShowCart={setShowCart}
                  setShowToast={setShowToast}
                  setAddorRemove={setAddorRemove}
                />
              </Col>
            ))}
          </>
        )}
      </Row>
    </>
  );
}

export default NewProductsscreen;

import React from "react";
import { ListGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const DiscountContainer = ({
  discountCoupon,
  login,
  discountCode,
  setDiscountCode,
  handleCode,
  isCodeValid,
  errorDiscount,
}) => {
  const handleInputChange = (e) => {
    setDiscountCode(e.target.value.trim().toUpperCase());
  };
  return (
    <ListGroup.Item
      style={{
        marginTop: 15,
        background: "#f5f5f5",
        padding: 15,
        borderRadius: 10,
        width: "100%",
      }}
    >
      {login ? (
        <div>
          <p
            htmlFor="discountCode"
            className="text-heading-secondary p-0 m-0 mb-1"
          >
            Enter discount code
          </p>

          <div className="d-flex justify-content-between align-items-baseline">
            <input
              id="discountCode"
              className="form-control"
              style={{ width: "70%" }}
              type="text"
              onChange={handleInputChange}
            />
            <Button
              disabled={!discountCode}
              onClick={handleCode}
              className="mt-2 px-4"
              variant="danger"
              size="sm"
              style={{ fontWeight: 600 }}
            >
              Apply
            </Button>
          </div>
          {!isCodeValid && (
            <span className="text-danger">
              <i>Invalid code. Please try again</i>
            </span>
          )}
          {errorDiscount && (
            <span className="text-danger">
              <i>{errorDiscount.data.detail || errorDiscount.data.error}</i>
            </span>
          )}
        </div>
      ) : (
        <Link to="/login?redirect=cart">Login to use discount code</Link>
      )}
    </ListGroup.Item>
  );
};

export default DiscountContainer;

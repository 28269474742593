//Estimated times of arrival

export const ETAS = [
  "11:00 am",
  "11:30 am",
  "11:59 am",
  "12:30 pm",
  "1:00 pm",
  "1:30 pm",
  "2:00 pm",
  "2:30 pm",
  "3:00 pm",
  "3:30 pm",
  "4:00 pm",
  "4:30 pm",
  "5:00 pm",
  "5:30 pm",
  "6:00 pm",
  "6:30 pm",
  "7:00 pm",
  "7:30 pm",
  "8:00 pm",
  "8:30 pm",
  "9:00 pm",
  "9:30 pm",
  "10:00 pm",
  "10:30 pm",
];

//temp - delivery slot

export const DEL_TIME = " by 7 pm";

//Reg ex for form validations
export const MOBILE_REG_EX = /^[6-9][0-9]{9}$/;
export const MOBILE_PATTERN = "[6-9][0-9]{9}";

export const EMAIL_REG_EX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const EMAIL_PATTERN =
  "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";

export const OTP_REG_EX = /^[1-9][0-9]{3}$/;
export const OTP_PATTERN = "[1-9][0-9]{3}";

//Error module constants
//This constant is a list of all error codes where user should get the detail of error
export const DISPLAY_ERROR_LIST = [400, 417, 429];
//Display character lengths
//Listing page product name
export const LISTING_PRODUCT_CHAR = 27;

export const LISTING_SELLER_CHAR = 25;

export const LP_HELPLINE = "+91-96327-70963";
//Details page
//cuisine tags NOT to be displayed
export const CUISINE_TAG_NO = ["International", "All Indian", "Other"];

//Veg,Egg Indicator colors
export const VEG_INDICATOR_COLORS = [
  { indicator: "Egg", color: "#ded313" },
  { indicator: "Veg", color: "#33743e" },
  { indicator: "Non-veg", color: "#814524" },
  { indicator: "Halal", color: "#814524" },
  { indicator: "Vegan", color: "#26df15" },
];

// Business decision constants

//Delivery pincodes
export const DELIVERY_PINCODES_LIST = [
  "560008",
  "560029",
  "560030",
  "560034",
  "560035",
  "560038",
  "560041",
  "560068",
  "560071",
  "560076",
  "560078",
  "560095",
  "560100",
  "560102",
  "560103",
];

// Lowest limit for free delivery
export const FREE_DEL_LIMIT = 200;
// Del fee if not for free delivery
export const DEL_FEE_BELOW_LIMIT = 10;
// GST rate
export const GST_PERCENTAGE = 0;

//RAZORPAY CHECKOUT SCRIPT
export const RAZORPAY_CHECKOUT_SCRIPT =
  "https://checkout.razorpay.com/v1/checkout.js";

export const POD_ORDER_VALUE_LIMIT = 1000;

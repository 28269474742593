import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../Components/FormContainer";
import { generateUserOTP, verifyUserOTP } from "../Actions/UserActions";
import { OTP_REG_EX } from "../Constants/GlobalConstants";
import { reportOTPIssues } from "../Actions/GoogleSheetsActions";
import { USER_GENERATE_OTP_RESET } from "../Constants/UserConstants";
import VerifyActions from "../Components/Login/VerifyActions";
import FormContent from "../Components/Login/FormContent";
import { createOtpIssue } from "../Utils/loginHelpers";
import useOtpForm from "../CustomHooks/useOtpForm";

function Verifyscreen({ history, location }) {
  const redirect = location.search ? location.search.split("=")[1] : "";

  const [validated, setValidated] = useState(false);

  const {
    otp,
    setOtp,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    validateForm,
  } = useOtpForm(setValidated);

  const [disableResend, setDisableResend] = useState(true);
  const dispatch = useDispatch();
  const [localMessageGenerateOTP, setLocalMessageGenerateOTP] = useState(null);

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const {
    loading: loadingVerifyOTP,
    login,
    error: errorVerifyOTP,
  } = userVerifyOTP;

  const userGenerateOTP = useSelector((state) => state.userGenerateOTP);
  const {
    loading: loadingGenerateOTP,
    success: successGenerateOTP,
    message: messageGenerateOTP,
    error: errorGenerateOTP,
  } = userGenerateOTP;

  const OTPIssuesWrite = useSelector((state) => state.OTPIssuesWrite);
  const {
    loading: loadingIssues,
    success: successIssues,
    error: errorIssues,
  } = OTPIssuesWrite;

  useEffect(() => {
    const storedOTP =
      messageGenerateOTP || JSON.parse(localStorage.getItem("userGenerate"));
    setLocalMessageGenerateOTP(storedOTP);
    if (!storedOTP) {
      history.push("/login");
    }
  }, [messageGenerateOTP]);

  useEffect(() => {
    if (errorVerifyOTP && errorVerifyOTP?.status === 400) {
      resetForm();
    }
  }, [errorVerifyOTP]);

  useEffect(() => {
    if (login && Object.keys(login).length > 0) {
      history.push(`/${redirect}`);
    } else {
      setOtp("");
    }
  }, [login, history]);

  useEffect(() => {
    if (successIssues) {
      history.push("/");
    }
  }, [successIssues]);

  useEffect(() => {
    let timer = setTimeout(() => setDisableResend(false), 60000);
    return () => {
      clearTimeout(timer);
    };
  }, [disableResend]);

  const resetForm = () => {
    setOtp("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setValidated(false);
  };

  const handleIssue = () => {
    const row = createOtpIssue(firstName);
    dispatch(reportOTPIssues(row));
  };

  const resendOTP = () => {
    let phoneNum;
    if (errorGenerateOTP) {
      let temp = JSON.parse(localStorage.getItem("userGenerate"));
      phoneNum = temp.mobile;
    } else {
      phoneNum = localMessageGenerateOTP?.mobile;
    }
    dispatch(generateUserOTP(phoneNum));
    setDisableResend(true);
  };

  const backToGenerate = () => {
    dispatch({ type: USER_GENERATE_OTP_RESET });
    history.push("/login");
  };

  const verifyOTP = (e) => {
    e.preventDefault();

    if (!validateForm(e)) return;

    if (!OTP_REG_EX.test(otp)) return;

    let payload = {
      mobile: localMessageGenerateOTP?.mobile,
      otp: otp,
    };

    try {
      if (localMessageGenerateOTP?.existingUser) {
        dispatch(verifyUserOTP(payload));
        resetForm();
      } else {
        if (email && firstName && lastName) {
          payload = { ...payload, firstName, lastName, email };
          dispatch(verifyUserOTP(payload));
          resetForm();
        }
      }
    } catch (error) {
      resetForm();
    }
  };

  return (
    <FormContainer>
      <Form
        noValidate
        validated={validated}
        className="Login verify"
        onSubmit={verifyOTP}
      >
        <Form.Group>
          <FormContent
            loadingGenerateOTP={loadingGenerateOTP}
            errorGenerateOTP={errorGenerateOTP}
            messageGenerateOTP={localMessageGenerateOTP}
            loadingVerifyOTP={loadingVerifyOTP}
            errorVerifyOTP={errorVerifyOTP}
            history={history}
            otp={otp}
            setOtp={setOtp}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
          />
          <VerifyActions
            verifyOTP={verifyOTP}
            disableResend={disableResend}
            messageGenerateOTP={localMessageGenerateOTP}
            handleIssue={handleIssue}
            loadingIssues={loadingIssues}
            resendOTP={resendOTP}
            backToGenerate={backToGenerate}
          />
        </Form.Group>
      </Form>
    </FormContainer>
  );
}

export default Verifyscreen;

import { useEffect, useState } from "react";
import { getApartmentList } from "../Actions/CartActions";
import { useSelector, useDispatch } from "react-redux";
import scrollToTop from "../Utils/ScrollToTop";
import ErrorHandle from "./ErrorHandle";
import Select from "react-select";
import Loader from "../Components/Loader";

function ApartmentList({
  selectedOption,
  setSelectedOption,
  history,
  setLocationAddress,
  setAddressObj,
}) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  const apartmentList = useSelector((state) => state.apartmentList);
  const {
    loading: loadingApartmentList,
    apartments,
    error: errorApartmentList,
  } = apartmentList;

  useEffect(() => {
    scrollToTop();
    dispatch(getApartmentList());
  }, []);

  useEffect(() => {
    if (apartments && apartments.length > 0) {
      setOptions(
        apartments.map((apt) => {
          return { value: apt.id, label: apt.name };
        })
      );
    }
  }, [apartments]);

  useEffect(() => {
    if (!selectedOption) {
      setLocationAddress(null);
    }
  }, [selectedOption]);

  const onSelectAddress = (option) => {
    setSelectedOption(option);
    if (option?.label) {
      setLocationAddress(option?.label);
      setAddressObj({
        buildingName: option?.label,
        isPremise: true,
        description: option?.label,
        locationId: option?.value,
      });
    }
  };

  return loadingApartmentList ? (
    <Loader />
  ) : errorApartmentList ? (
    <ErrorHandle history={history} error={errorApartmentList} />
  ) : (
    Boolean(options && options.length > 0) && (
      <>
        <p className="mb-1 mt-3 text-heading-secondary">
          Search for apartment/ office
        </p>
        <Select
          defaultValue={selectedOption}
          onChange={onSelectAddress}
          isClearable
          autoFocus
          options={options}
        />
      </>
    )
  );
}

export default ApartmentList;

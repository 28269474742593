import React from "react";
import ProductsList from "../HomeScreen/ProductsList";
import EmptySearch from "./EmptySearch";
import Loader from "../Loader";

const SearchContainer = ({
  history,
  products,
  keyword,
  loading,
  error,
  setShowToast,
  deviceType,
}) => {
  const marginTopCal = deviceType === "sm" ? -40 : 0;

  if (!keyword || keyword.length < 2) {
    return (
      <div style={{ paddingLeft: 10, marginTop: marginTopCal }}>
        <EmptySearch />
      </div>
    );
  }
  return (
    <div style={{ marginTop: marginTopCal }}>
      {keyword && (
        <p>
          Search results for "{keyword?.split("=")[1]?.replace(/%20/g, " ")}"
        </p>
      )}
      {loading && <Loader />}
      {!loading && (
        <ProductsList
          history={history}
          loading={loading}
          error={error}
          products={products}
          keyword={keyword}
          setShowToast={setShowToast}
          screen={"SEARCH"}
        />
      )}
    </div>
  );
};

export default SearchContainer;

import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FloatingLabel, Button } from "react-bootstrap";
import FormContainer from "../Components/FormContainer";
import Rating from "../Components/Rating";
import { createMyReview } from "../Actions/ReviewActions";
import ErrorHandle from "../Components/ErrorHandle";
import Loader from "../Components/Loader";
import { REVIEW_CREATE_RESET } from "../Constants/ReviewConstants";

function Reviewscreen({ history, match }) {
  const [title, setTitle] = useState("");
  const [rating, setRating] = useState(5);
  const [body, setBody] = useState("");
  const [errorStr, setErrorStr] = useState(null);

  const [validated, setValidated] = useState(false);

  const order_item = match.params.id;
  const itemName = match.params.name;

  const dispatch = useDispatch();

  const createReview = useSelector((state) => state.createReview);
  const { loading, error, success } = createReview;

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  useEffect(() => {
    if (!login || Object.keys(login).length === 0) {
      history.push(`/login?redirect=create-review/${order_item}/${itemName}`);
    }
  }, [dispatch, login]);

  const submitReview = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropogation();
    }
    if (!rating) {
      return setErrorStr("Please enter a valid rating");
    }
    if (!title) {
      return setErrorStr("Please enter a valid title");
    }
    setValidated(true);
    const review = { order_item, title, body, rating };
    dispatch(createMyReview(review));
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: REVIEW_CREATE_RESET });
      setErrorStr(null);
      history.go(-1);
    }
  }, [history, success]);

  return (
    <>
      <FormContainer>
        {loading ? (
          <Loader />
        ) : error ? (
          <ErrorHandle error={error} history={history} />
        ) : (
          <>
            <h3>Write a Review for {itemName}</h3>
            <Form noValidate validated={validated} onSubmit={submitReview}>
              <Rating value={rating} text={""} color="#f8e825" />

              <FloatingLabel
                controlId="rating"
                label="Select product rating"
                className="mb-3"
              >
                <Form.Select
                  onChange={(e) => setRating(e.target.value)}
                  aria-label="Rating"
                  aria-required
                  required
                >
                  <option value="">Select a Rating</option>
                  <option value="1">1 - Worst</option>
                  <option value="2">2 - Average</option>
                  <option value="3">3 - Good</option>
                  <option value="4">4 - Very Good</option>
                  <option value="5">5 - Best</option>
                </Form.Select>
              </FloatingLabel>

              <FloatingLabel
                controlId="title"
                label="Title of your review"
                className="mb-3"
              >
                <Form.Control
                  as="input"
                  placeholder="Eg: Very tasty Gulab Jamuns"
                  onChange={(e) => setTitle(e.target.value)}
                  maxLength="50"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a title of review
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="body"
                label="Details of your review"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Write some details about your review"
                  onChange={(e) => setBody(e.target.value)}
                  style={{ height: "100px" }}
                  maxLength="200"
                />
              </FloatingLabel>
              {errorStr && <p className="text-primary">{errorStr}</p>}
              <p>
                <i>
                  Note: Reviews with unparliamentary language will not be
                  displayed
                </i>
              </p>
              <Button
                variant="primary"
                type="submit"
                onClick={submitReview}
                className="mb-3"
              >
                Submit Review
              </Button>
            </Form>
          </>
        )}
      </FormContainer>
    </>
  );
}

export default Reviewscreen;

import React from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../Loader";
import ErrorHandle from "../ErrorHandle";

const OrderCancelModal = (props) => {
  const {
    showModal,
    handleClose,
    loadingCancel,
    history,
    error,
    errorCancel,
    setCancelReason,
    orderCancellation,
  } = props;
  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Order cancellation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingCancel ? (
          <Loader />
        ) : errorCancel ? (
          <ErrorHandle key="cancel-error" history={history} error={error} />
        ) : (
          <>
            Kindly let us know the reason for cancelling this order
            <input
              type="text"
              onChange={(e) => setCancelReason(e.target.value)}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Back
        </Button>
        <Button variant="primary" onClick={orderCancellation}>
          Cancel the order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderCancelModal;

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  categoryDetailsReducer,
  categoryListReducer,
  categoryProductsReducer,
  collectionDetailsReducer,
  collectionItemByTypeReducer,
  collectionListReducer,
  collectionMadeTypeReducer,
  getBannerReducer,
  getPrevProductReducer,
  getProductRecommendationsReducer,
  getRecommendationsReducer,
  newProductListReducer,
  productDetailsReducer,
  productListReducer,
} from "./Reducers/ProductReducers";
import {
  createShippingAddressReducer,
  editCartReducer,
  shippingAddressListReducer,
  deliveryAddressReducer,
  cartItemsListReducer,
  apartmentListReducer,
  createApartmentAddressReducer,
  deliveryOptionsReducer,
  isCustomerPodAllowedReducer,
} from "./Reducers/CartReducers";
import {
  userGenerateOTPReducer,
  userGetProfileReducer,
  userLogoutReducer,
  userUpdateProfileReducer,
  userVerifyOTPReducer,
} from "./Reducers/UserReducers";
import {
  listMyOrdersReducer,
  orderCancelReducer,
  orderConfirmationReducer,
  orderCreateReducer,
  paymentDetailsReducer,
  orderDiscountReducer,
  orderNewConfirmationReducer,
  razorpayReducer,
  tempOrderReducer,
  orderDetailsReducer,
} from "./Reducers/OrderReducers";
import {
  createReviewReducer,
  writeCustomerIssueReducer,
} from "./Reducers/ReviewReducers";
import { sellerDetailsReducer } from "./Reducers/SellerReducers";

import { showFlashMessageReducer } from "./Reducers/FlashMessageReducer";
import {
  OTPIssuesWriteReducer,
  onboardingWriteReducer,
  issuesWriteReducer,
} from "./Reducers/GoogleSheetsReducers";
import {
  batchCreateReducer,
  deleteBatchReducer,
  editBatchReducer,
  listBatchesReducer,
  listCreatableBatchesReducer,
  listInwardingReducer,
  markReceivedReducer,
  NPIBatchApproveReducer,
  NPIlistBatchesReducer,
  NPIlistCreatableBatchesReducer,
  NPIlistInwardingReducer,
} from "./Reducers/AdminReducers";

const reducer = combineReducers({
  userGenerateOTP: userGenerateOTPReducer,
  userVerifyOTP: userVerifyOTPReducer,
  userGetProfile: userGetProfileReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userLogout: userLogoutReducer,

  productList: productListReducer,
  productDetails: productDetailsReducer,
  categoryList: categoryListReducer,
  categoryDetails: categoryDetailsReducer,
  categoryProducts: categoryProductsReducer,
  newProductList: newProductListReducer,

  cart: editCartReducer,
  cartItemsList: cartItemsListReducer,
  deliveryOptions: deliveryOptionsReducer,

  createShippingAddress: createShippingAddressReducer,
  shippingAddressList: shippingAddressListReducer,
  apartmentList: apartmentListReducer,
  createApartmentAddress: createApartmentAddressReducer,
  deliveryAddress: deliveryAddressReducer,

  listMyOrders: listMyOrdersReducer,
  orderCreate: orderCreateReducer,
  razorpay: razorpayReducer,
  orderConfirmation: orderConfirmationReducer,
  orderNewConfirmation: orderNewConfirmationReducer,
  orderCancel: orderCancelReducer,
  tempOrderStore: tempOrderReducer,
  paymentDetails: paymentDetailsReducer,
  orderDetails: orderDetailsReducer,

  orderDiscount: orderDiscountReducer,

  createReview: createReviewReducer,

  sellerDetails: sellerDetailsReducer,

  flashMessage: showFlashMessageReducer,

  OTPIssuesWrite: OTPIssuesWriteReducer,
  onboardingWrite: onboardingWriteReducer,
  issuesWrite: issuesWriteReducer,
  writeCustomerIssue: writeCustomerIssueReducer,

  listCreatableBatches: listCreatableBatchesReducer,
  NPIlistCreatableBatches: NPIlistCreatableBatchesReducer,

  deleteBatch: deleteBatchReducer,
  editBatch: editBatchReducer,
  batchCreate: batchCreateReducer,
  listBatches: listBatchesReducer,

  NPIlistBatches: NPIlistBatchesReducer,
  listInwarding: listInwardingReducer,

  NPIlistInwarding: NPIlistInwardingReducer,
  markReceived: markReceivedReducer,

  NPIBatchApprove: NPIBatchApproveReducer,

  bannerList: getBannerReducer,

  cartRecommendations: getRecommendationsReducer,
  collectionDetails: collectionDetailsReducer,
  collectionList: collectionListReducer,
  collectionMadeType: collectionMadeTypeReducer,
  collectionItemByType: collectionItemByTypeReducer,

  productRecommendations: getProductRecommendationsReducer,
  prevProducts: getPrevProductReducer,

  isCustomerPodAllowed: isCustomerPodAllowedReducer,
});

const userGenerateFromStorage = localStorage.getItem("userGenerate")
  ? JSON.parse(localStorage.getItem("userGenerate"))
  : null;

const userLoginFromStorage = localStorage.getItem("userLogin")
  ? JSON.parse(localStorage.getItem("userLogin"))
  : null;

const cartItemsFromStorage = localStorage.getItem("cartItemsNew1")
  ? JSON.parse(localStorage.getItem("cartItemsNew1"))
  : null;

const orderDiscountFromStorage = localStorage.getItem("orderDiscount")
  ? JSON.parse(localStorage.getItem("orderDiscount"))
  : null;

const initialState = {
  userVerifyOTP: {
    login: userLoginFromStorage,
  },
  userGenerateOTP: {
    message: userGenerateFromStorage,
  },
  cart: {
    cartItems: cartItemsFromStorage,
  },
  orderDiscount: {
    discountCoupon: orderDiscountFromStorage,
  },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

import React from "react";
import { Placeholder } from "react-bootstrap";
import RecItemSkeleton from "./RecItemSkeleton";

const RecContainerSkeleton = ({ numItems, styles, innerClasses = "" }) => {
  const placeHolderItems = [...Array(numItems)];
  return (
    <div
      style={{
        marginTop: 15,
        padding: 10,
        borderRadius: 10,
        width: "100%",
        ...styles,
      }}
    >
      <p className="text-heading-secondary p-0 m-0">
        <Placeholder as="p"></Placeholder>
      </p>
      <div
        className={`d-flex rec-container ${innerClasses}`}
        style={{ gap: 2 }}
      >
        {placeHolderItems.map((_, index) => {
          return <RecItemSkeleton />;
        })}
      </div>
    </div>
  );
};

export default RecContainerSkeleton;

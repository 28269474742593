import React, { useState } from "react";
import Product from "../ProductCard/Product";
import { Col, Row } from "react-bootstrap";
import ErrorHandle from "../ErrorHandle";
import ProductListSkeleton from "./ProductListSkeleton";

const ProductsList = ({
  history,
  loading,
  error,
  products = [],
  keyword = "",
  setShowToast,
  screen = "HOME",
}) => {
  const [showRecContainer, setShowRecContainer] = useState(null);

  if (loading) {
    return <ProductListSkeleton />;
  }

  if (error) {
    <ErrorHandle history={history} error={error} />;
  }

  if (products?.length < 1) {
    return <h5>Nothing on sale currently</h5>;
  }
  return (
    <Row>
      {products?.map(
        (product) =>
          product.earliestDeliverySlot && (
            <Col key={`column-${product.id}`} xs={12} md={6} lg={6} xl={4}>
              {keyword ? (
                <Product
                  key={product.id}
                  product={product}
                  setShowToast={setShowToast}
                  showRecContainer={showRecContainer}
                  setShowRecContainer={setShowRecContainer}
                  screen={screen}
                />
              ) : product.showStock ? (
                <Product
                  key={product.id}
                  product={product}
                  setShowToast={setShowToast}
                  showRecContainer={showRecContainer}
                  setShowRecContainer={setShowRecContainer}
                  screen={screen}
                />
              ) : null}
            </Col>
          )
      )}
    </Row>
  );
};

export default ProductsList;
